/**  Colors  **/
/** @deprecated **/
/**  **/
/** Shadow **/
/** Borders **/
/**  **/
/** DEFAULTS **/
.select-dropdown-answer .input_select__control {
  border: 1px solid #D5DDE5;
  border-radius: 8px !important;
  overflow: hidden;
  height: 56px;
}
.select-dropdown-answer .input_select__control--is-focused {
  border-color: #7B29C9 !important;
  box-shadow: 0 0 0 1px #7B29C9;
}
.select-dropdown-answer .input_select__value-container {
  padding: 2px 15px !important;
  margin: -2px 0 0 0;
}
.select-dropdown-answer .input_select__value-container,
.select-dropdown-answer .input_select__indicators {
  background: #F1EDFA;
  height: inherit;
}
.select-dropdown-answer .input_select__placeholder {
  color: #A7B3C2 !important;
  font-size: 18px;
}
.select-dropdown-answer .input_select__indicator-separator {
  display: none;
}
.select-dropdown-answer .input_select__menu {
  border-radius: 8px !important;
  border: 1px solid #D5DDE6;
  z-index: 1000 !important;
}
.task_page--timeout .select-dropdown-answer .input_select__menu {
  z-index: 1 !important;
}
.select-dropdown-answer .input_select__option {
  line-height: 28px;
  font-size: 18px;
  color: #232943 !important;
  padding: 11px 12px !important;
}
.select-dropdown-answer .input_select__option--is-selected,
.select-dropdown-answer .input_select__option--is-focused {
  background: #F2F2F2 !important;
  border-color: #7B29C9 !important;
}
.select-dropdown-answer .input_select__input > input {
  height: auto !important;
}
.select-dropdown-answer-formulas p,
.select-dropdown-answer-formulas .MathJax_Display {
  margin: 0px;
}
.select-dropdown-answer-inline {
  line-height: 24px;
  max-width: 720px;
}
.select-dropdown-answer-inline .input_select__control {
  height: 32px;
  min-height: 32px;
}
.select-dropdown-answer-inline .input_select__value-container {
  padding: 2px 8px !important;
  margin: 0;
}
.select-dropdown-answer-inline .input_select__value-container > div {
  padding-bottom: 0;
  padding-top: 0;
  margin: 0;
}
.select-dropdown-answer-inline .input_select__placeholder {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #757575 !important;
}
.select-dropdown-answer__formula #react-mathjax-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.select-dropdown-answer-read-only {
  pointer-events: none;
}
.theme-light .select-dropdown-answer__dropdown-block-verdict-ok {
  background-color: #EBF7DF;
}
.theme-light .select-dropdown-answer__dropdown-block-verdict-none,
.theme-light .select-dropdown-answer__dropdown-block-verdict-wrong {
  background-color: #FFEBED;
}
.theme-light .select-dropdown-answer__dropdown-block-verdict-partly {
  background-color: #FFF5D9;
}
.theme-light .select-dropdown-answer__dropdown-block-verdict-unscored,
.theme-light .select-dropdown-answer__dropdown-block-verdict-noneUnscored,
.theme-light .select-dropdown-answer__dropdown-block-verdict-annulled {
  background-color: #FFFFFF;
}
.module_passing_content_inner__description img:first-child:last-child.select-dropdown-answer__option-image,
.select-dropdown-answer__option-image {
  margin: 0px;
}
.select-dropdown-answer-simple .dropdown-select__select-item {
  padding: 12px 20px;
  max-height: 48px;
}
.select-dropdown-answer-simple .select-option {
  font-size: 14px;
}
.select-dropdown-answer-simple .ui-checkbox {
  max-height: 24px;
}
