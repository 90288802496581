/* Generated with js2less.js.
Original file: src/styles/values/grayscale.json */
/* Generated with js2less.js.
Original file: src/styles/values/breakpoints.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-action.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-wrong.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-good.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-attention.json */
.teacher-course-page {
  min-height: 100vh;
  min-width: 100%;
  background-color: #F7F7F7;
}
.teacher-course-page__content {
  max-width: 1136px;
  margin: 0 auto;
  padding: 8px;
  width: 100%;
}
.teacher-course-page__back-button {
  display: flex;
  text-decoration: none;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #999999;
  padding: 10px 50px;
  margin: 40px 0 16px 0;
  cursor: pointer;
  width: fit-content;
}
.teacher-course-page__back-button-icon {
  margin-right: 18px;
}
.teacher-course-page__back-button:visited {
  color: #999999;
}
.teacher-course-page__title,
.teacher-course-page__subtitle {
  color: #1A1A1A;
  padding: 40px;
  margin: 0 0 16px 0;
  font-weight: 700;
}
.teacher-course-page__title {
  font-size: 48px;
  line-height: 56px;
}
.teacher-course-page__subtitle {
  font-size: 32px;
  line-height: 40px;
}
.teacher-course-page__submenu {
  padding: 16px 40px;
  margin: 0 0 16px 0;
  overflow-x: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.teacher-course-page__submenu-button {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #1A1A1A;
}
.teacher-course-page__submenu-button:first-of-type {
  margin-right: 8px;
}
.teacher-course-page__submenu-button-wrapper {
  text-decoration: none;
}
.teacher-course-page__submenu-button-wrapper:visited {
  color: #1A1A1A;
}
.teacher-course-page__modules,
.teacher-course-page__contests {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
@media (max-width: 1200px) {
  .teacher-course-page__modules,
  .teacher-course-page__contests {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 600px) {
  .teacher-course-page__modules,
  .teacher-course-page__contests {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 8px;
  }
}
.teacher-course-page__section {
  margin-bottom: 80px;
}
@media (max-width: 760px) {
  .teacher-course-page__title {
    margin-bottom: 8px;
    padding: 16px 24px;
    font-size: 32px;
    line-height: 40px;
  }
  .teacher-course-page__subtitle {
    font-size: 24px;
    line-height: 32px;
    padding: 16px 24px;
  }
  .teacher-course-page__submenu {
    padding: 8px 16px;
    margin-bottom: 8px;
  }
  .teacher-course-page__modules {
    margin: 0 8px;
  }
  .teacher-course-page__section {
    margin-bottom: 40px;
  }
}
.teacher-course-page__class-select-container {
  width: 336px;
}
.teacher-course-page-toc__chapter-selector {
  padding: 8px 40px;
}
.teacher-course-page-toc-layout-chapter-paragraphs {
  gap: 80px;
}
.teacher-course-page-toc-layout-paragraph-lessons {
  gap: 16px;
}
.teacher-course-page-toc .teacher-module-card {
  min-width: calc(32% - 1vh);
  max-width: calc(32% - 1vh);
}
@media (max-width: 1200px) {
  .teacher-course-page-toc .teacher-module-card {
    min-width: calc(50% - 1vh);
    max-width: calc(50% - 1vh);
  }
}
@media (max-width: 600px) {
  .teacher-course-page-toc .teacher-module-card {
    min-width: 100%;
    max-width: 100%;
  }
}
.teacher-course-page-toc__chapter-lessons {
  gap: 16px;
}
.teacher-course-page-toc__chapter-lessons-name {
  padding: 40px;
  font-weight: 700;
  user-select: none;
}
.teacher-course-page-toc__chapter-lessons-content {
  gap: 1vw;
  flex-wrap: wrap;
}
.teacher-course-page-toc__chapter-paragraphs-name {
  padding: 40px;
  font-weight: 700;
  user-select: none;
}
.teacher-course-page-toc__chapter-paragraphs-content {
  gap: 16px;
}
.teacher-course-page-toc__paragraph-lessons {
  background-color: #EDEDED;
  border-radius: 16px;
}
.teacher-course-page-toc__paragraph-lessons:hover {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
}
.teacher-course-page-toc__paragraph-lessons-title {
  padding: 24px 40px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.teacher-course-page-toc__paragraph-lessons-arrow .ui-icon {
  transition: all 0.1s ease-out;
}
.teacher-course-page-toc__paragraph-lessons-open-true .teacher-course-page-toc__paragraph-lessons-arrow .ui-icon {
  transform: rotate(-180deg);
}
.teacher-course-page-toc__paragraph-lessons-name {
  font-weight: 700;
  user-select: none;
}
.teacher-course-page-toc__paragraph-lessons-content {
  max-height: 0;
  padding: 0;
  transition: all 0.2s ease-out;
  gap: 1vw;
  flex-wrap: wrap;
}
.teacher-course-page-toc__paragraph-lessons-content > .teacher-module-card {
  transition: all 0.2s ease-out;
  transform: scale(0.01);
}
.teacher-course-page-toc__paragraph-lessons-open-true .teacher-course-page-toc__paragraph-lessons-content {
  padding: 24px;
  gap: 1vh;
  height: auto;
  max-height: 4000px;
}
.teacher-course-page-toc__paragraph-lessons-open-true .teacher-course-page-toc__paragraph-lessons-content > .teacher-module-card {
  transform: scale(1);
}
