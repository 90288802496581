.system_notification {
  max-width: 360px;
  width: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 111111;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(19, 16, 16, 0.73) transparent;
}
.system_notification::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.system_notification::-webkit-scrollbar-track {
  background: transparent;
}
.system_notification::-webkit-scrollbar-thumb {
  background: rgba(19, 16, 16, 0.73);
  border-radius: 5px;
}
.system_notification::-webkit-scrollbar-thumb:hover {
  background: #131010;
}
.system_notification__item {
  padding: 8px;
  width: 100%;
  color: white;
  border-radius: 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  position: relative;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  -webkit-animation: ease ease-in alternate;
  -webkit-animation-name: run;
  -webkit-animation-duration: 300ms;
  word-break: break-word;
}
.system_notification__item--clickable {
  cursor: pointer;
}
.system_notification__item--info {
  background: #fff;
  color: #1e1e1e;
}
.system_notification__item--info .MuiIcon-root,
.system_notification__item--info .icon_atom.ui-icon {
  color: #909090 !important;
}
.system_notification__item--info a {
  color: #1e1e1e;
  text-decoration: underline;
}
.system_notification__item--error {
  background: #FF5757;
}
.system_notification__item--error .MuiIcon-root,
.system_notification__item--error .icon_atom.ui-icon {
  color: #fff !important;
}
.system_notification__item--error a {
  color: #fff;
  text-decoration: underline;
}
.system_notification__item--warning {
  background: #FFBF00;
  color: #1e1e1e;
}
.system_notification__item--warning .MuiIcon-root,
.system_notification__item--warning .icon_atom.ui-icon {
  color: #909090 !important;
}
.system_notification__item--warning a {
  color: #1e1e1e;
  text-decoration: underline;
}
.system_notification__item--result {
  background: #88CC00;
  color: #1e1e1e;
}
.system_notification__item--result .MuiIcon-root,
.system_notification__item--result .icon_atom.ui-icon {
  color: #909090 !important;
}
.system_notification__item--result a {
  color: #1e1e1e;
  text-decoration: underline;
}
.system_notification__meta {
  justify-content: space-between !important;
  align-items: center !important;
}
.system_notification__meta_popup {
  text-decoration: underline;
  cursor: pointer;
}
.system_notification__meta-copy {
  margin-top: 12px;
}
