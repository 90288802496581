.autograde_nothing_to_do {
  text-align: center;
  color: #36363E;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.autograde_nothing_to_do .title {
  font-size: 36px;
  line-height: 44px;
  color: #36363E;
  font-weight: 600;
}
.autograde_nothing_to_do .text {
  font-size: 20px;
  line-height: 26px;
  margin-top: 20px;
}
.autograde_nothing_to_do__center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding-bottom: 120px;
  max-width: 688px;
  margin: 0 auto;
}
.autograde_nothing_to_do img {
  max-width: 240px;
}
