.task_buttons {
  display: flex;
  margin: 16px -5px;
}
.task_buttons button {
  width: 100%;
  margin: 0 5px;
  border-radius: 12px;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
  cursor: pointer;
  padding: 16px;
  box-sizing: border-box;
  border: none;
  font-family: inherit;
}
.task_buttons__toggle_answer {
  background: #7B29C9;
  color: #FFFFFF;
}
.task_buttons__toggle_answer[disabled] {
  color: #C6C2CC;
  border: 1px solid #E1DFE6;
  background: none;
  cursor: default;
}
.task_buttons__toggle_comments {
  background: #F1EDFA;
  color: #7B29C9;
  flex: 1 0 35%;
  max-width: 210px;
}
.task_buttons__toggle_comments img {
  margin-right: 10px;
  vertical-align: top;
}
.task_buttons__message_form_cancel {
  background: #F1EDFA;
  color: #7B29C9;
  flex: 1 0 30%;
}
.task_buttons__toggle_extra_answer {
  background: #F1EDFA;
  color: #7B29C9;
}
.task_buttons__message_form_submit {
  background: #88CC00;
  color: #FFFFFF;
}
.task_buttons__message_form_submit[disabled] {
  color: #C6C2CC;
  border: 1px solid #E1DFE6;
  background: none;
  cursor: default;
}
@media (max-width: 760px) {
  .task_buttons__toggle_comments {
    max-width: 48px;
  }
  .task_buttons__toggle_comments span {
    display: none;
  }
  .task_buttons__toggle_comments img {
    margin: 0;
  }
}
