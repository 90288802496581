.info-link-card {
  box-sizing: border-box;
  display: inline-flex;
  overflow: hidden;
  background: #EDEEF4;
  color: #101010;
  font-size: 14px;
  line-height: 20px;
  border-radius: 12px;
  width: calc(25% - 20px);
  margin: 10px;
  padding: 16px;
  max-height: 124px;
  min-height: 124px;
  position: relative;
  cursor: pointer;
}
.info-link-card__icon {
  width: 70px;
  height: 84px;
  object-fit: contain;
  margin-right: 20px;
}
.info-link-card__title {
  margin: 0;
  padding: 0;
  max-width: 210px;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
}
.info-link-card__date {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #101010;
  opacity: 0.8;
}
.info-link-card__button {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 6px 12px;
  position: absolute;
  right: 16px;
  bottom: 16px;
}
.info-link-card:hover {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}
.info-link-card h3,
.info-link-card p {
  margin: 0;
}
@media (max-width: 1440px) {
  .info-link-card {
    width: calc(33% - 20px);
  }
}
@media (max-width: 1200px) {
  .info-link-card {
    width: calc(50% - 20px);
  }
}
@media (max-width: 760px) {
  .info-link-card {
    width: calc(100% - 16px);
    margin: 4px 8px;
    max-height: 150px;
    min-height: 150px;
  }
  .info-link-card__icon {
    margin-right: 16px;
  }
}
