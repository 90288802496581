.module_action_card {
  background: #FFFFFF;
  border-radius: 16px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding: 16px;
  box-sizing: border-box;
  font-family: "PT Root UI", Arial, sans-serif;
  text-decoration: none;
}
@media (max-width: 760px) {
  .module_action_card {
    display: block;
    width: auto;
  }
}
.module_action_card__text {
  font-size: 18px;
  line-height: 24px;
  color: #36363E;
  height: 120px;
  overflow: hidden;
  position: relative;
  padding-right: 108px;
}
.module_action_card__cheops {
  position: absolute;
  right: 3px;
  top: 10px;
  width: 106px;
  height: 108px;
  background: url("../../../img/prof_cheops_checkmark.svg") 50% 50% no-repeat;
}
.module_action_card__footer {
  text-align: right;
  font-size: 0;
  margin-top: 36px;
}
.module_action_card button {
  border: none;
  font-style: inherit;
  font-weight: inherit;
  font-size: inherit;
}
.module_action_card__button,
a.module_action_card__button,
button.module_action_card__button {
  font-family: "PT Root UI", Arial, sans-serif;
  font-style: inherit;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  background: #F1EDFA;
  border-radius: 8px;
  height: 36px;
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #7B29C9;
  margin-left: 8px;
}
.module_action_card__button:hover,
a.module_action_card__button:hover,
button.module_action_card__button:hover {
  background: #EAE0FF;
}
.module_action_card__button[disabled],
a.module_action_card__button[disabled],
button.module_action_card__button[disabled] {
  background: transparent;
  color: #C6C2CC;
  border: 1px solid #E1DFE6;
}
