.text-xxl {
  font-size: 32px;
  line-height: 40px;
}
.text-xl {
  font-size: 24px;
  line-height: 32px;
}
.text-l {
  font-size: 18px;
  line-height: 28px;
}
.text-m {
  font-size: 16px;
  line-height: 24px;
}
.text-s {
  font-size: 14px;
  line-height: 20px;
}
.text-xs {
  font-size: 12px;
  line-height: 16px;
}
.text-xxs {
  font-size: 10px;
  line-height: 12px;
}
.smt-answer-widget__widget {
  margin: 24px 0;
  height: 400px;
  min-height: 400px;
  max-height: 400px;
  overflow: visible;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}
.smt-answer-widget__widget-verdict-ok {
  background-color: #EBF7DF;
}
.smt-answer-widget__widget-verdict-none,
.smt-answer-widget__widget-verdict-wrong {
  background-color: #FFEBED;
}
.smt-answer-widget__widget-verdict-partly {
  background-color: #FFF5D9;
}
.smt-answer-widget__euler-circles {
  user-select: none;
}
.smt-answer-widget__euler-circles-debug,
.smt-answer-widget__euler-circles-circles-layer,
.smt-answer-widget__euler-circles-circles-bottom,
.smt-answer-widget__euler-circles-placement,
.smt-answer-widget__euler-circles-placements,
.smt-answer-widget__euler-circles-circle-set {
  overflow: visible;
}
.smt-answer-widget__euler-circles-debug-text {
  font-size: 10px;
  fill: red;
}
.smt-answer-widget__euler-circles-placement-label {
  font-size: 10px;
  fill: #00A3FF;
}
.smt-answer-widget__euler-circles-circle-shape {
  opacity: 0.1;
}
.smt-answer-widget__euler-circles-circle-A {
  fill: #002BFF;
}
.smt-answer-widget__euler-circles-circle-B {
  fill: #C14805;
}
.smt-answer-widget__euler-circles-circle-C {
  fill: #707F14;
}
.smt-answer-widget__euler-circles-circle-label {
  font-size: 10px;
  fill: rgba(0, 0, 0, 0.12);
}
.smt-answer-widget-disabled-false .smt-answer-widget__euler-circles-control-circle:not(.smt-answer-widget__euler-circles-control-empty),
.smt-answer-widget-disabled-false .smt-answer-widget__euler-circles-control-count:not(.smt-answer-widget__euler-circles-control-count-empty) {
  cursor: grab;
}
.smt-answer-widget__euler-circles-control-count {
  font-size: 16px;
  font-weight: 700;
  fill: #FFFFFF;
}
.smt-answer-widget__euler-circles-control-label {
  fill: #999999;
  font-size: 10px;
}
.smt-answer-widget__euler-circles-control-f {
  fill: #8C59B7;
}
.smt-answer-widget__euler-circles-control-f-second {
  fill: #773BA8;
}
.smt-answer-widget__euler-circles-control-f-many {
  fill: #621E9B;
}
.smt-answer-widget__euler-circles-control-d {
  fill: #F9851B;
}
.smt-answer-widget__euler-circles-control-d-second {
  fill: #EA7012;
}
.smt-answer-widget__euler-circles-control-d-many {
  fill: #DC5C0A;
}
.smt-answer-widget__euler-circles-control-e {
  fill: #ACC032;
}
.smt-answer-widget__euler-circles-control-e-second {
  fill: #99AB28;
}
.smt-answer-widget__euler-circles-control-e-many {
  fill: #84951E;
}
.smt-answer-widget__euler-circles-control-g {
  fill: #01A2E7;
}
.smt-answer-widget__euler-circles-control-g-second {
  fill: #0193D2;
}
.smt-answer-widget__euler-circles-control-g-many {
  fill: #0184BD;
}
.smt-answer-widget-disabled-false .smt-answer-widget__euler-circles-circle-set:hover .smt-answer-widget__euler-circles-draggable {
  stroke: black;
  stroke-width: 1;
  cursor: grab;
}
.smt-answer-widget__euler-circles-control-empty {
  opacity: 0.5;
}
.smt-answer-widget__euler-circles-circle-hover > .smt-answer-widget__euler-circles-circle-shape {
  stroke: black;
  stroke-width: 1;
}
.smt-answer-widget__euler-circles-circle-hover > .smt-answer-widget__euler-circles-circle-label {
  fill: black;
}
.smt-answer-widget__euler-circles-transit {
  stroke: black;
  stroke-width: 1;
  cursor: grabbing;
}
.smt-answer-widget__euler-circles-transit-label {
  cursor: grabbing;
}
.smt-answer-widget__euler-circles-transit-f {
  fill: #8C59B7;
}
.smt-answer-widget__euler-circles-transit-d {
  fill: #F9851B;
}
.smt-answer-widget__euler-circles-transit-e {
  fill: #ACC032;
}
.smt-answer-widget__euler-circles-transit-g {
  fill: #01A2E7;
}
.smt-answer-widget__euler-circles-reset {
  cursor: pointer;
}
.smt-answer-widget__euler-circles-reset:hover .smt-answer-widget__euler-circles-reset-path {
  fill: #666666;
}
.smt-answer-widget__euler-circles-reset-path {
  fill: #999999;
}
.smt-answer-widget__task-money-transfer-arrows {
  user-select: none;
}
.smt-answer-widget__task-money-transfer-arrows-reset {
  cursor: pointer;
}
.smt-answer-widget__task-money-transfer-arrows-reset:hover .smt-answer-widget__task-money-transfer-arrows-reset-path {
  fill: #666666;
}
.smt-answer-widget__task-money-transfer-arrows-reset-path {
  fill: #999999;
}
.smt-answer-widget__task-money-transfer-arrows-disabled-false .smt-answer-widget__task-money-transfer-arrows-rect {
  cursor: pointer;
}
.smt-answer-widget__task-money-transfer-arrows-disabled-false .smt-answer-widget__task-money-transfer-arrows-rect-checked.smt-answer-widget__task-money-transfer-arrows-rect-selected .smt-answer-widget__task-money-transfer-arrows-rect-shape,
.smt-answer-widget__task-money-transfer-arrows-disabled-false .smt-answer-widget__task-money-transfer-arrows-rect-checked .smt-answer-widget__task-money-transfer-arrows-rect-shape,
.smt-answer-widget__task-money-transfer-arrows-disabled-false .smt-answer-widget__task-money-transfer-arrows-rect:not(.smt-answer-widget__task-money-transfer-arrows-rect-selected):hover .smt-answer-widget__task-money-transfer-arrows-rect-shape {
  fill: #D1B4EC;
}
.smt-answer-widget__task-money-transfer-arrows-rect {
  overflow: visible;
}
.smt-answer-widget__task-money-transfer-arrows-rect-shape {
  fill: #CCCCCC;
}
.smt-answer-widget__task-money-transfer-arrows-rect-label {
  fill: #1A1A1A;
  font-weight: 500;
}
.smt-answer-widget__task-money-transfer-arrows-rect-selected .smt-answer-widget__task-money-transfer-arrows-rect-shape {
  fill: #8C59B7;
}
.smt-answer-widget__task-money-transfer-arrows-rect-selected.d .smt-answer-widget__task-money-transfer-arrows-rect-shape {
  fill: #7EB219;
}
.smt-answer-widget__task-money-transfer-arrows-rect-selected .smt-answer-widget__task-money-transfer-arrows-rect-label {
  fill: #FFFFFF;
}
.smt-answer-widget__task-money-transfer-arrows-disabled-false .smt-answer-widget__task-money-transfer-arrows-arrow-hl:not(.smt-answer-widget__task-money-transfer-arrows-arrow-selected) .smt-answer-widget__task-money-transfer-arrows-arrow-path,
.smt-answer-widget__task-money-transfer-arrows-disabled-false .smt-answer-widget__task-money-transfer-arrows-arrow:not(.smt-answer-widget__task-money-transfer-arrows-arrow-selected):hover .smt-answer-widget__task-money-transfer-arrows-arrow-path {
  fill: #D1B4EC;
}
.smt-answer-widget__task-money-transfer-arrows-disabled-false .smt-answer-widget__task-money-transfer-arrows-arrow:hover .smt-answer-widget__task-money-transfer-arrows-arrow-path {
  cursor: pointer;
}
.smt-answer-widget__task-money-transfer-arrows-arrow {
  fill: #CCCCCC;
  overflow: visible;
}
.smt-answer-widget__task-money-transfer-arrows-arrow-selected .smt-answer-widget__task-money-transfer-arrows-arrow-path {
  fill: #8C59B7;
}
