.radio_control {
  padding: 0 0 0 16px;
}
.radio_control .MuiRadio-root {
  padding: 8px;
}
.radio_control label.MuiFormControlLabel-root {
  width: 100%;
  margin: 0;
}
.radio_control label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart {
  justify-content: space-between;
}
.radio_control label.MuiFormControlLabel-root .MuiTypography-root {
  font-size: 14px;
}
.radio_control .MuiRadio-colorPrimary {
  color: #909090;
}
.radio_control .MuiRadio-colorPrimary.Mui-checked {
  color: #424242;
}
.radio_control .MuiRadio-colorPrimary.Mui-disabled {
  color: #929292;
}
.radio_control--darkMode .MuiRadio-colorPrimary {
  color: #929292;
}
.radio_control--darkMode .MuiRadio-colorPrimary.Mui-checked {
  color: #fff;
}
.radio_control--darkMode .MuiTypography-root {
  color: #9F9F9F;
}
.radio_control--darkMode.radio_control--highlight .MuiTypography-root {
  color: #FFFFFF;
}
