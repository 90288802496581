.task_explanations__buttons {
  border-radius: 18px;
  color: #7B29C9;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  overflow: hidden;
  display: inline-flex;
  margin: 64px 0;
}
.task_explanations__buttons * {
  background: #F1EDFA;
  text-decoration: none;
  color: inherit;
  padding: 8px 16px;
  cursor: pointer;
}
.task_explanations__buttons *:visited {
  color: inherit;
}
.task_explanations__buttons *:hover {
  background: #EAE0FF;
}
.task_explanations__buttons * + * {
  border-left: 1px solid #fff;
}
.task_explanations__explanation {
  margin-top: 48px;
}
.task_explanations__explanation img {
  max-width: 100%;
}
.task_explanations__explanation:after {
  content: " ";
  display: block;
  clear: both;
}
.task_explanations__explanation .video-wrapper-type-storage {
  position: relative;
}
.task_explanations__explanation_title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  width: 40%;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 12px;
}
.task_explanations__explanation_text {
  margin-bottom: 30px;
}
.task_explanations__video_iframe {
  width: 100%;
  overflow: hidden;
  height: 400px;
}
.task_explanations__close_link {
  float: right;
  cursor: pointer;
}
@media (max-width: 760px) {
  .task_explanations__buttons {
    display: block;
  }
  .task_explanations__buttons * {
    display: inline-block;
    margin: 10px 5px;
    border-radius: 18px;
  }
  .task_explanations__explanation_title {
    font-size: 24px;
  }
}
