/* Generated with js2less.js.
Original file: src/styles/values/grayscale.json */
/* Generated with js2less.js.
Original file: src/styles/values/breakpoints.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-action.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-wrong.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-good.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-attention.json */
.teacher-contest-card {
  padding: 8px 16px;
  min-height: 140px;
  background: #FFFFFF;
  border-radius: 16px;
  align-items: flex-start;
}
.teacher-contest-card__link {
  display: flex;
  text-decoration: none;
  min-height: inherit;
  gap: 8px;
  width: 100%;
  align-items: inherit;
}
.teacher-contest-card__icon {
  min-width: 40px;
  height: 40px;
  background-color: #999999;
}
.teacher-contest-card__icon-has-access {
  background-color: #7EB219;
}
.theme-light .teacher-contest-card__icon .ui-icon {
  color: #FFFFFF;
}
.teacher-contest-card__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #1A1A1A;
  margin: 0;
  padding: 6px 0;
}
.teacher-contest-card__menu .ui-popover__menu-item {
  padding: 0 4px;
}
@media (max-width: 600px) {
  .teacher-contest-card {
    min-height: fit-content;
  }
}
