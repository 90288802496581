.social_icon {
  display: block;
  height: 48px;
  width: 48px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  text-decoration: none;
}
.social_icon-media-vk {
  background-image: url("~CheopsRoot/img/social-icon-vk.svg");
}
.social_icon-media-instagram {
  background-image: url("~CheopsRoot/img/social-icon-instagram.svg");
}
.social_icon-media-youtube {
  background-image: url("~CheopsRoot/img/social-icon-youtube.svg");
}
.social_icon-media-telegram {
  background-image: url("~CheopsRoot/img/social-icon-telegram.svg");
}
