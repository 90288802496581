.module-page-textbook-styles-complexity-controls {
  max-width: 810px;
  margin: 0 auto;
  margin-bottom: 40px;
  padding-left: 0;
}
@media (max-width: 760px) {
  .module-page-textbook-styles-complexity-controls {
    padding-left: 0;
  }
}
