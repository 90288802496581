.code-uploader {
  font-family: inherit;
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #333333;
  cursor: pointer;
  justify-content: space-between;
  width: 126px;
  padding: 0;
}
.code-uploader:focus {
  outline: none;
}
