/* Generated with js2less.js.
Original file: src/styles/values/grayscale.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-action.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-wrong.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-good.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-attention.json */
.teacher-header {
  padding: 10px 16px 10px 32px;
}
.teacher-header-cluster-cpm .teacher-header__logo {
  background-image: url("../../../../img/cluster-logo-cpm.png");
}
.teacher-header-cluster-cpm .teacher-header__logo-white {
  background-image: url("../../../../img/cluster-logo-cpm-white.png");
}
.teacher-header-cluster-cpm .teacher-header__logo-small {
  background-image: url("../../../../img/cluster-logo-cpm-small.svg");
}
.teacher-header-cluster-cpm .teacher-header__logo-white.teacher-header__logo-small {
  background-image: url("../../../../img/cluster-logo-cpm-small-white.svg");
}
.teacher-header-cluster-sirius .teacher-header__logo {
  background-image: url("../../../../img/cluster-logo-sirius.png");
}
.teacher-header-cluster-sirius .teacher-header__logo-white {
  background-image: url("../../../../img/cluster-logo-sirius-white.png");
}
.teacher-header-cluster-sirius .teacher-header__logo-small {
  background-image: url("../../../../img/cluster-logo-sirius-small.svg");
}
.teacher-header-cluster-sirius .teacher-header__logo-white.teacher-header__logo-small {
  background-image: url("../../../../img/cluster-logo-sirius-small-white.svg");
}
.teacher-header-cluster-textbook .teacher-header__logo {
  background-image: url("../../../../img/cluster-logo-textbook.png");
}
.teacher-header-cluster-textbook .teacher-header__logo-white {
  background-image: url("../../../../img/cluster-logo-textbook-white.png");
}
.teacher-header-cluster-textbook .teacher-header__logo-small {
  background-image: url("../../../../img/cluster-logo-textbook-small.svg");
}
.teacher-header-cluster-textbook .teacher-header__logo-white.teacher-header__logo-small {
  background-image: url("../../../../img/cluster-logo-textbook-small-white.svg");
}
.teacher-header-cluster-corp .teacher-header__logo {
  background-image: url("../../../../img/cluster-logo-corp.png");
}
.teacher-header-cluster-corp .teacher-header__logo-white {
  background-image: url("../../../../img/cluster-logo-corp-white.png");
}
.teacher-header-cluster-corp .teacher-header__logo-small {
  background-image: url("../../../../img/cluster-logo-corp-small.svg");
}
.teacher-header-cluster-corp .teacher-header__logo-white.teacher-header__logo-small {
  background-image: url("../../../../img/cluster-logo-corp-small-white.svg");
}
.teacher-header-cluster-univ .teacher-header__logo {
  background-image: url("../../../../img/cluster-logo-univ.png");
}
.teacher-header-cluster-univ .teacher-header__logo-white {
  background-image: url("../../../../img/cluster-logo-univ-white.png");
}
.teacher-header-cluster-univ .teacher-header__logo-small {
  background-image: url("../../../../img/cluster-logo-univ-small.svg");
}
.teacher-header-cluster-univ .teacher-header__logo-white.teacher-header__logo-small {
  background-image: url("../../../../img/cluster-logo-univ-small-white.svg");
}
.teacher-header__logo {
  height: 36px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 196px;
  margin: 12px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}
.teacher-header__services-button {
  margin: 6px 0;
}
.teacher-header__services-button .teacher-header__logo {
  margin: 0;
}
.teacher-header .services-dropdown {
  top: 80px;
  z-index: 10;
}
.teacher-header .services-dropdown.services-dropdown-isTablet {
  top: 0;
}
.teacher-header .services-dropdown:not(.services-dropdown-isTablet) {
  left: 16px;
  margin: 0;
}
@media (max-width: calc(1136px + 4px * 4)) {
  .teacher-header .services-dropdown:not(.services-dropdown-isTablet) {
    left: 0;
  }
}
.teacher-header a:visited.services-dropdown__service,
.teacher-header a.services-dropdown__service {
  color: #1A1A1A;
}
