.shedule-link-card {
  box-sizing: border-box;
  display: inline-flex;
  overflow: hidden;
  background: #EDEEF5;
  color: #8C8695;
  font-family: "PT Root UI", Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  border-radius: 12px;
  width: calc(25% - 20px);
  margin: 10px;
  padding: 20px 24px;
}
.shedule-link-card__compact {
  box-sizing: border-box;
  display: inline-flex;
  overflow: hidden;
  background: #EDEEF5;
  color: #8C8695;
  font-family: "PT Root UI", Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  border-radius: 12px;
  margin: 0px;
  padding: 4px 8px;
  padding-right: 16px;
}
.shedule-link-card__compact .shedule-link-card__link {
  font-size: 14px;
  font-weight: normal;
}
@media (max-width: 1440px) {
  .shedule-link-card {
    width: calc(33% - 20px);
  }
}
@media (max-width: 1200px) {
  .shedule-link-card {
    width: calc(50% - 20px);
  }
}
@media (max-width: 760px) {
  .shedule-link-card {
    width: calc(100% - 16px);
    margin: 4px 8px;
  }
}
.shedule-link-card:hover {
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
}
.shedule-link-card__professor {
  max-height: 84px;
}
.shedule-link-card__link {
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
}
.shedule-link-card__link-label {
  display: none;
}
@media (min-width: 760px) {
  .shedule-link-card__link-label-full {
    display: inline-flex;
  }
}
@media (max-width: 759px) {
  .shedule-link-card__link-label-short {
    display: inline-flex;
  }
}
@media (max-width: 1440px) {
  .shedule-link-card__link {
    white-space: normal;
  }
}
.theme-light .ui-icon.shedule-link-card__icon {
  color: #4D0F8A;
}
.shedule-link-card-cpm {
  display: none;
}
