.course-button {
  border: 0;
  margin-left: -170px;
  background-size: 200%;
  background-position: 0 50%;
  border-radius: 28px;
  cursor: pointer;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  box-sizing: border-box;
  transition: all 500ms, background 100ms;
  z-index: 98;
  display: block;
  text-decoration: none;
}
.course-button-frompage-default {
  position: fixed;
  bottom: 32px;
  left: 50%;
  padding: 20px;
  width: 340px;
  height: 56px;
  font-size: 14px;
}
.course-button-frompage-course {
  position: fixed;
  bottom: 32px;
  left: 50%;
  padding: 20px;
  width: 340px;
  height: 56px;
  font-size: 14px;
}
.course-button-frompage-landing {
  position: absolute;
  bottom: 8px;
  right: 8px;
  left: auto;
  padding: 12px 16px;
  height: auto;
  width: auto;
  border-radius: 8px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  text-transform: none;
}
.course-button:hover {
  background: #599900;
}
.course-button:active {
  background: #499100;
}
.course-button:visited {
  color: #FFFFFF;
}
.course-button-cluster-cpm {
  background-color: #88CC00;
}
.course-button-cluster-sirius {
  background-color: #88CC00;
}
.course-button-cluster-textbook {
  background-color: #88CC00;
}
.course-button-cluster-corp {
  background-color: #88CC00;
}
.course-button-cluster-univ {
  background-color: #0DB1AA;
}
.course-button-cluster-univ:hover {
  background-color: #3E878A;
}
.course-button[disabled],
.course-button-disabled {
  background: none;
  box-shadow: initial;
  color: #C6C2CC;
  border: 1px solid #E1DFE6;
}
.course-button[focus],
.course-button-focus {
  border: 2px solid rgba(0, 0, 0, 0.16);
}
@media (max-width: 760px) {
  .course-button {
    width: 300px;
    margin-left: -150px;
    bottom: 24px;
  }
}
@media (max-width: 760px) {
  .course-button {
    width: 220px;
    margin-left: -110px;
    bottom: 24px;
  }
  .course-button__button_icon {
    left: auto;
    right: 20px;
  }
  .course-button--card {
    bottom: 10px;
    left: 0;
    width: calc(100% - 20px);
    margin: 0 10px;
    height: 56px;
  }
  .course-button--card__button_icon {
    right: auto;
    left: 26px;
  }
  .course-button--module_list {
    width: 64px;
    height: 64px;
    right: 24px;
    left: auto;
    border-radius: 100px;
  }
  .course-button--module_list p {
    display: none;
  }
  .course-button--module_list__button_icon {
    right: 50%;
    margin-right: -8px;
  }
}
