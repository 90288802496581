.course_progress_line {
  position: absolute;
  top: 25px;
  left: 54px;
  width: 65%;
}
.course_progress_line__level {
  width: 100%;
  padding: 10px 0 10px 0;
  margin-top: -10px;
  position: relative;
  margin-right: 15px;
}
.course_progress_line__progress {
  border-radius: 20px;
  background: rgba(179, 179, 179, 0.53);
  width: 100%;
  height: 6px;
  position: relative;
}
.course_progress_line__lines div:nth-child(1) {
  background: #88CC00;
}
.course_progress_line__lines div:nth-child(2) {
  background: #FF5E73;
  right: 0;
}
.course_progress_line__lines div {
  height: 6px;
  border-radius: 20px;
  position: absolute;
  top: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
}
.course_progress_line__point {
  border-radius: 50px;
  position: absolute;
  top: 5.1px;
  cursor: pointer;
  z-index: 0;
  margin-left: -10px;
  padding: 8px;
}
.course_progress_line__point--done {
  background: #88CC00;
}
.course_progress_line__point--fail {
  background: #FF5E73;
}
.course_progress_line__point--normal {
  background: rgba(179, 179, 179, 0.53);
}
