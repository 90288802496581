.parent_code_page {
  background: #fcfcf9;
}
.parent_code_page__wrapper {
  width: 230px;
  margin: 0 auto;
}
.parent_code_page__title {
  line-height: 30px;
  font-size: 24px;
  letter-spacing: -0.02em;
  margin: 80px 0 40px 0;
  font-family: "PT Root UI", Arial, sans-serif;
}
.parent_code_page__code {
  border: 1px solid #E9EFF7;
  box-sizing: border-box;
  border-radius: 28px;
  line-height: 25px;
  font-size: 16px;
  letter-spacing: 0.02em;
  padding: 13px 26px;
  margin: 0 -15px;
}
.parent_code_page__copy {
  background: linear-gradient(245.31deg, #40CCFB 48.72%, #4B8CEE 98.18%), #56CCF2;
  background-size: 200%;
  background-position: 0 50%;
  transition: all 500ms, background 100ms;
  width: 56px;
  height: 53px;
  margin-top: -14px;
  margin-right: -26px;
  float: right;
  border-radius: 0 28px 28px 0;
  box-sizing: border-box;
  padding-top: 15px;
  padding-left: 16px;
  cursor: pointer;
}
.parent_code_page__copy:hover {
  background-position: 100% 50%;
}
.parent_code_page__copy:active {
  background: #4B8EEE;
}
.parent_code_page__copy_icon {
  background: url("../img/parent_code_icon.svg") no-repeat;
  width: 24px;
  height: 24px;
}
.parent_code_page__descr {
  line-height: 22px;
  font-size: 16px;
  margin-top: 40px;
}
#parent_code_page__code_input {
  position: absolute;
  left: -999px;
  top: -999px;
  opacity: 0;
}
