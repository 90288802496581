/*Generated summary file*/
/* Generated with js2less.js.
Original file: src/styles/values/breakpoints.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-action.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-chain-biology.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-chain-chemistry.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-chain-informatics.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-chain-linguistics.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-chain-mathematics.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-chain-physics.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-main-purple.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-sirius-grey.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-attention.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-good.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-wrong.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors.json */
/* Generated with js2less.js.
Original file: src/styles/values/grayscale.json */
/* Generated with js2less.js.
Original file: src/styles/values/palettes.json */
/* Generated with js2less.js.
Original file: src/styles/values/text-sizes.json */
/* Generated with js2less.js.
Original file: src/styles/values/text-weight.json */
/* Generated with js2less.js.
Original file: src/styles/values/variables.json */
.course-toc {
  width: 100%;
  height: 100%;
  padding-left: 376px;
  padding-top: 16px;
  gap: 16px;
}
@media (max-width: 760px) {
  .course-toc {
    padding-left: unset;
    padding-top: 80px;
  }
}
.course-toc__page {
  height: 100%;
  width: 100%;
}
.course-toc__page > body,
.course-toc__page .theme-light.ui-screen {
  background-color: #1A1A1A;
}
.course-toc__page .course_page {
  display: flex;
  padding: 0;
  font-size: inherit;
  background: unset;
}
.course-toc__chapter {
  gap: 16px;
  padding-top: 16px;
  padding-bottom: 24px;
}
@media (min-width: 1920px) {
  .course-toc__chapter {
    align-items: center;
  }
}
@media (max-width: 760px) {
  .course-toc__chapter {
    padding-bottom: 8px;
  }
}
.course-toc__chapter-name,
.course-toc__chapter-description {
  color: #FFFFFF;
  max-width: 1078px;
  width: 100%;
  padding-right: 64px;
  padding-left: 64px;
}
.course-toc__chapter-name-expand,
.course-toc__chapter-description-expand {
  align-items: flex-start;
}
.ui-screen-mobile .course-toc__chapter-name,
.ui-screen-mobile .course-toc__chapter-description {
  padding-right: 16px;
  padding-left: 16px;
}
.course-toc__chapter-name {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  word-break: break-word;
}
.ui-screen-mobile .course-toc__chapter-name {
  font-size: 32px;
  line-height: 40px;
}
.course-toc__chapter-description {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 80px;
}
.ui-screen-mobile .course-toc__chapter-description {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
}
.course-toc__chapter-lessons,
.course-toc__chapter-paragraphs {
  width: 100%;
  gap: 16px;
}
.course-toc__chapter-lessons {
  gap: 16px;
  padding: 16px;
  height: auto;
  overflow: hidden;
  transition: all 0.2s ease-out;
  flex-wrap: wrap;
  flex-direction: row;
}
.course-toc__paragraph {
  background-color: #EDEDED;
  padding: 20px 40px;
  min-height: 80px;
  border-radius: 16px;
  margin: 0 24px;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
}
.ui-screen-mobile .course-toc__paragraph {
  padding: 12px 16px;
  margin: 0 16px;
}
.course-toc__paragraph:hover {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
}
.course-toc__paragraph-title {
  cursor: pointer;
  user-select: none;
  min-height: 40px;
  align-items: center;
}
.course-toc__paragraph-name {
  color: #1A1A1A;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  width: 100%;
}
.ui-screen-mobile .course-toc__paragraph-name {
  font-size: 18px;
  line-height: 28px;
}
.course-toc__paragraph-description {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #1A1A1A;
}
.ui-screen-mobile .course-toc__paragraph-description {
  font-size: 16px;
  line-height: 24px;
}
.course-toc__paragraph-description,
.course-toc__paragraph-lessons {
  max-height: 0;
  gap: 0;
  padding: 0;
  transition: all 0.2s ease-out;
  flex-wrap: wrap;
}
.course-toc__paragraph-description > .course-toc__lesson,
.course-toc__paragraph-lessons > .course-toc__lesson {
  transition: transform 0.2s ease-out;
  transform: scale(0);
}
.course-toc__paragraph-description {
  overflow: auto;
}
.course-toc__paragraph-open .course-toc__paragraph-description,
.course-toc__paragraph-open .course-toc__paragraph-lessons {
  height: auto;
  max-height: 4000px;
}
.course-toc__paragraph-open .course-toc__paragraph-description > .course-toc__lesson,
.course-toc__paragraph-open .course-toc__paragraph-lessons > .course-toc__lesson {
  transform: scale(1);
}
.course-toc__paragraph-open .course-toc__paragraph-description {
  padding: 24px 0;
}
.course-toc__paragraph-open .course-toc__paragraph-lessons {
  gap: 16px;
}
.course-toc__paragraph-arrow .ui-icon {
  transition: all 0.1s ease-out;
}
.course-toc__paragraph-open .course-toc__paragraph-arrow .ui-icon {
  transform: rotate(-180deg);
}
.course-toc__lesson {
  border-radius: 16px;
  padding: 8px 16px;
  background-color: #FFFFFF;
  min-height: 140px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  flex-shrink: 0;
  box-sizing: border-box;
  text-decoration: none;
}
.ui-screen-mobile .course-toc__lesson {
  min-height: initial;
}
.course-toc__lesson,
.course-toc__lesson:visited {
  color: #1A1A1A;
}
.course-toc__lesson:hover {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
}
.course-toc__lesson:active {
  background-color: #F7F7F7;
}
.course-toc__lesson-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  padding-top: 4px;
}
.ui-screen-mobile .course-toc__lesson-title {
  font-size: 16px;
  line-height: 24px;
}
.course-toc__lesson-dates {
  color: #999999;
  gap: 8px;
  padding-bottom: 4px;
}
.course-toc__lesson-dates-start,
.course-toc__lesson-dates-end {
  gap: 4px;
  height: 32px;
  align-items: center;
}
.course-toc__lesson-info {
  width: 100%;
  justify-content: space-between;
}
.course-toc__lesson-progress {
  min-width: 40px;
}
@media (min-width: 360px) {
  .course-toc__lesson {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .course-toc__lesson {
    width: calc(50% - 4px * 2);
  }
}
@media (min-width: 1440px) {
  .course-toc__lesson {
    width: calc(32% - 4px * 2);
  }
}
@media (min-width: 1920px) {
  .course-toc__lesson {
    width: calc(24% - 4px * 2);
  }
}
.course-toc__sidebar {
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  gap: 4px;
  background-color: #FFFFFF;
}
.course-toc__sidebar-item {
  padding: 4px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  flex-shrink: 0;
  box-sizing: border-box;
  text-decoration: none;
}
.course-toc__sidebar-item-icon {
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
}
.theme-light .course-toc__sidebar-item-icon .ui-icon {
  color: inherit;
}
.course-toc__sidebar-item-title {
  font-weight: 700;
  padding: 4px;
}
.course-toc__sidebar-item,
.course-toc__sidebar-item:visited {
  color: #1A1A1A;
}
.course-toc__sidebar-item-selected:visited,
.course-toc__sidebar-item-selected {
  background-color: #999999;
  color: #FFFFFF;
}
@media (min-width: 760px) {
  .course-toc__sidebar-close-button {
    display: none;
  }
}
@media (max-width: 760px) {
  .course-toc__sidebar {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 16px 16px;
  }
  .course-toc__sidebar-hide {
    display: none;
  }
}
