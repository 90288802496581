.select_on_image_answer__image_wrapper {
  text-align: center;
  user-select: none;
}
.select_on_image_answer__image_wrapper--align-left {
  text-align: left;
}
.select_on_image_answer__image_wrapper > div {
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  vertical-align: top;
}
.select_on_image_answer__image_wrapper > div img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}
.select_on_image_answer__image_wrapper > div svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.select_on_image_answer__image_wrapper > div svg > * {
  fill: rgba(0, 0, 0, 0);
}
.select_on_image_answer__zone {
  cursor: pointer;
}
.select_on_image_answer__zone + image {
  cursor: pointer;
}
.select_on_image_answer__zone--result {
  cursor: default;
}
.select_on_image_answer__zone--display-method-nothing {
  cursor: default;
}
