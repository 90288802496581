.message_popup {
  text-align: left;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.05);
}
.message_popup > div {
  padding: 16px;
  border-radius: 16px;
  background: #FFFFFF;
  box-shadow: 0px 12px 32px rgba(106, 105, 140, 0.15);
  font-size: 18px;
  line-height: 24px;
  color: #36363E;
  width: 90%;
  max-width: 360px;
}
.message_popup__buttons {
  margin-top: 55px;
  text-align: right;
}
.message_popup__buttons button {
  background: #F1EDFA;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  color: #7B29C9;
  margin: 0 8px;
  font-size: 16px;
  line-height: 24px;
  padding: 6px 16px;
  box-sizing: border-box;
}
.message_popup__buttons button:hover {
  background: #EAE0FF;
}
.message_popup h2 {
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 6px;
}
@media (max-width: 760px) {
  .message_popup {
    align-items: flex-end;
  }
  .message_popup > div {
    max-width: none;
    width: 100%;
    border-radius: 16px 16px 0 0;
  }
}
