.solution_to_show_popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(54, 54, 62, 0.8);
  z-index: 103;
  display: flex;
  justify-content: center;
  align-items: center;
}
.solution_to_show_popup__close {
  background: url("~CheopsRoot/img/close_cross.svg") 50% 50% no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
.solution_to_show_popup__window {
  background: #FFFFFF;
  border-radius: 24px;
  padding: 16px;
  width: 750px;
  max-width: 95%;
  box-sizing: border-box;
  max-height: calc(95% - 50px);
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}
.solution_to_show_popup__toggle {
  background: #F1EDFA;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  border-radius: 18px;
  color: #7B29C9;
  float: right;
  text-align: center;
  padding: 8px 16px;
  max-width: 180px;
  place-self: flex-end;
  user-select: none;
}
.solution_to_show_popup__toggle:hover {
  background: #EAE0FF;
}
.solution_to_show_popup__solutions {
  font-size: 0;
  white-space: nowrap;
  display: flex;
  overflow: hidden auto;
  margin-bottom: 16px;
}
.solution_to_show_popup__solutions > div {
  display: inline-block;
  width: 100%;
  white-space: normal;
  vertical-align: top;
  min-width: 100%;
}
.solution_to_show_popup__solutions > div:last-child {
  visibility: hidden;
}
.solution_to_show_popup__solutions > div:first-child:last-child {
  visibility: visible;
}
.solution_to_show_popup__solutions--show_user_answer > div:first-child {
  visibility: hidden;
  order: 2;
}
.solution_to_show_popup__solutions--show_user_answer > div:last-child {
  visibility: visible;
  order: 1;
}
.solution_to_show_popup__solutions--inline {
  line-height: 40px;
}
.solution_to_show_popup__solution_text {
  font-size: 18px;
  font-weight: 500;
}
.solution_to_show_popup--programming .solution_to_show_popup__solutions {
  margin-bottom: 0px;
}
.solution_to_show_popup--programming .solution_to_show_popup__window {
  padding: 8px;
}
