.landing_action_popup {
  position: fixed;
  width: 360px;
  height: 200px;
  top: calc(50% - 100px);
  left: calc(50% - 180px);
  z-index: 999;
  background: #FFFFFF;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0px 12px 32px rgba(106, 105, 140, 0.15);
  border-radius: 16px;
  color: #36363E;
  font-size: 18px;
  line-height: 24px;
}
.landing_action_popup__header {
  font-weight: 900;
}
.landing_action_popup__text {
  font-weight: 300;
}
.landing_action_popup__buttons {
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-size: 0;
  text-align: right;
}
.landing_action_popup__buttons_item {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  height: 36px;
  color: #7B29C9;
  background: #F1EDFA;
  border-radius: 8px;
  padding: 0 17px;
  margin-right: 8px;
  cursor: pointer;
  border: none;
  outline: none;
}
.landing_action_popup__buttons_item:hover {
  background: #EAE0FF;
}
.landing_action_popup__buttons_item:focus {
  border: 2px solid rgba(0, 0, 0, 0.16);
  height: 32px;
  padding: 0 15px;
}
.landing_action_popup__buttons_item:active {
  background: #D6C9F2;
}
@media (max-width: 760px) {
  .landing_action_popup {
    width: 100%;
    left: 0;
    right: 0;
    top: auto;
    bottom: 0;
    border-radius: unset;
  }
}
