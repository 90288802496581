.button_control {
  border-radius: 4px;
}
.button_control .MuiButton-root {
  transition-duration: 150ms;
  font-family: inherit;
}
.button_control .MuiButton-root:hover {
  background: transparent;
}
.button_control .MuiIcon-root,
.button_control .icon_atom.ui-icon {
  transition: color 150ms;
}
.button_control .MuiOutlinedInput-input {
  padding: 12.5px 16px;
}
.button_control .MuiOutlinedInput-notchedOutline,
.button_control .MuiButton-outlined,
.button_control .MuiButton-outlined:hover {
  border: 1px solid #DCDCDC;
}
.button_control .MuiButtonBase-root {
  padding: 8px 16px;
  border-radius: 4px;
  color: #1e1e1e;
  outline: none;
  text-transform: inherit;
  font-size: 14px;
  cursor: pointer;
  height: 40px;
}
.button_control .MuiButtonBase-root:hover:not(.restrict-hover) {
  background: rgba(51, 51, 51, 0.06);
}
.button_control .MuiButtonBase-root:focus:not(.restrict-focus) {
  box-shadow: 0 0 0 2px #2F82FF;
}
.button_control .MuiButtonBase-root.pressed {
  background: #E6E6E6;
}
.button_control .MuiButtonBase-root.error {
  color: #FF5757;
}
.button_control .MuiButtonBase-root.link {
  color: #2F82FF;
}
.button_control .MuiButtonBase-root[disabled] {
  background: #F5F5F5;
  color: #ADADAD;
}
.button_control .MuiButtonBase-root[disabled] .MuiButton-startIcon .MuiIcon-root,
.button_control .MuiButtonBase-root[disabled] .MuiButton-startIcon .icon_atom.ui-icon {
  color: #E0E0E0;
}
.button_control .MuiButtonBase-root[disabled] .MuiButton-endIcon .MuiIcon-root,
.button_control .MuiButtonBase-root[disabled] .MuiButton-endIcon .icon_atom.ui-icon {
  color: #E0E0E0;
}
.button_control .MuiButtonBase-root.link[disabled] {
  color: #ADADAD;
  background: none;
}
.button_control .MuiButtonBase-root.hasErrors .MuiButton-startIcon .MuiIcon-root,
.button_control .MuiButtonBase-root.hasErrors .MuiButton-startIcon .icon_atom.ui-icon {
  color: #FF5757;
}
.button_control .MuiButtonBase-root.hasErrors .MuiButton-endIcon .MuiIcon-root,
.button_control .MuiButtonBase-root.hasErrors .MuiButton-endIcon .icon_atom.ui-icon {
  color: #FF5757;
}
.button_control .MuiButtonBase-root.MuiButton-textPrimary,
.button_control .MuiButtonBase-root.MuiIconButton-colorPrimary,
.button_control .MuiButtonBase-root.MuiButton-outlinedPrimary {
  background: #333333;
  color: #FFFFFF;
}
.button_control .MuiButtonBase-root.MuiButton-textPrimary .MuiIcon-root,
.button_control .MuiButtonBase-root.MuiIconButton-colorPrimary .MuiIcon-root,
.button_control .MuiButtonBase-root.MuiButton-outlinedPrimary .MuiIcon-root,
.button_control .MuiButtonBase-root.MuiButton-textPrimary .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.MuiIconButton-colorPrimary .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.MuiButton-outlinedPrimary .icon_atom.ui-icon {
  color: #FFFFFF;
}
.button_control .MuiButtonBase-root.MuiButton-textPrimary:hover:not(.restrict-hover),
.button_control .MuiButtonBase-root.MuiIconButton-colorPrimary:hover:not(.restrict-hover),
.button_control .MuiButtonBase-root.MuiButton-outlinedPrimary:hover:not(.restrict-hover) {
  background: #474747;
}
.button_control .MuiButtonBase-root.MuiButton-textPrimary:hover:not(.restrict-hover) .MuiIcon-root,
.button_control .MuiButtonBase-root.MuiIconButton-colorPrimary:hover:not(.restrict-hover) .MuiIcon-root,
.button_control .MuiButtonBase-root.MuiButton-outlinedPrimary:hover:not(.restrict-hover) .MuiIcon-root,
.button_control .MuiButtonBase-root.MuiButton-textPrimary:hover:not(.restrict-hover) .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.MuiIconButton-colorPrimary:hover:not(.restrict-hover) .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.MuiButton-outlinedPrimary:hover:not(.restrict-hover) .icon_atom.ui-icon {
  color: #FFFFFF;
}
.button_control .MuiButtonBase-root.MuiButton-textPrimary.pressed,
.button_control .MuiButtonBase-root.MuiIconButton-colorPrimary.pressed,
.button_control .MuiButtonBase-root.MuiButton-outlinedPrimary.pressed {
  background: #656565;
}
.button_control .MuiButtonBase-root.MuiButton-textPrimary[disabled],
.button_control .MuiButtonBase-root.MuiIconButton-colorPrimary[disabled],
.button_control .MuiButtonBase-root.MuiButton-outlinedPrimary[disabled] {
  background: #D3D3D3;
}
.button_control .MuiButtonBase-root.dark-mode {
  color: #FFFFFF;
}
.button_control .MuiButtonBase-root.dark-mode:hover:not(.restrict-hover) {
  background: rgba(255, 255, 255, 0.1);
}
.button_control .MuiButtonBase-root.dark-mode:hover:not(.restrict-hover) .MuiIcon-root,
.button_control .MuiButtonBase-root.dark-mode:hover:not(.restrict-hover) .icon_atom.ui-icon {
  color: #FFFFFF;
}
.button_control .MuiButtonBase-root.dark-mode:focus:not(.restrict-focus) {
  box-shadow: 0 0 0 2px #2F82FF;
}
.button_control .MuiButtonBase-root.dark-mode.pressed {
  background: rgba(255, 255, 255, 0.2);
}
.button_control .MuiButtonBase-root.dark-mode[disabled] {
  background: none;
  color: rgba(255, 255, 255, 0.25);
}
.button_control .MuiButtonBase-root.dark-mode[disabled] .MuiIcon-root,
.button_control .MuiButtonBase-root.dark-mode[disabled] .icon_atom.ui-icon {
  color: rgba(255, 255, 255, 0.25);
}
.button_control .MuiButtonBase-root.dark-mode.MuiButton-textPrimary,
.button_control .MuiButtonBase-root.dark-mode.MuiIconButton-colorPrimary,
.button_control .MuiButtonBase-root.dark-mode.MuiButton-outlinedPrimary {
  background: #FFFFFF;
  color: #1e1e1e;
}
.button_control .MuiButtonBase-root.dark-mode.MuiButton-textPrimary .MuiIcon-root,
.button_control .MuiButtonBase-root.dark-mode.MuiIconButton-colorPrimary .MuiIcon-root,
.button_control .MuiButtonBase-root.dark-mode.MuiButton-outlinedPrimary .MuiIcon-root,
.button_control .MuiButtonBase-root.dark-mode.MuiButton-textPrimary .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.dark-mode.MuiIconButton-colorPrimary .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.dark-mode.MuiButton-outlinedPrimary .icon_atom.ui-icon {
  color: #929292;
}
.button_control .MuiButtonBase-root.dark-mode.MuiButton-textPrimary:hover:not(.restrict-hover),
.button_control .MuiButtonBase-root.dark-mode.MuiIconButton-colorPrimary:hover:not(.restrict-hover),
.button_control .MuiButtonBase-root.dark-mode.MuiButton-outlinedPrimary:hover:not(.restrict-hover) {
  background: #E6E6E6;
}
.button_control .MuiButtonBase-root.dark-mode.MuiButton-textPrimary:hover:not(.restrict-hover) .MuiIcon-root,
.button_control .MuiButtonBase-root.dark-mode.MuiIconButton-colorPrimary:hover:not(.restrict-hover) .MuiIcon-root,
.button_control .MuiButtonBase-root.dark-mode.MuiButton-outlinedPrimary:hover:not(.restrict-hover) .MuiIcon-root,
.button_control .MuiButtonBase-root.dark-mode.MuiButton-textPrimary:hover:not(.restrict-hover) .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.dark-mode.MuiIconButton-colorPrimary:hover:not(.restrict-hover) .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.dark-mode.MuiButton-outlinedPrimary:hover:not(.restrict-hover) .icon_atom.ui-icon {
  color: #717171;
}
.button_control .MuiButtonBase-root.dark-mode.MuiButton-textPrimary.pressed,
.button_control .MuiButtonBase-root.dark-mode.MuiIconButton-colorPrimary.pressed,
.button_control .MuiButtonBase-root.dark-mode.MuiButton-outlinedPrimary.pressed {
  background: #BFBFBF;
}
.button_control .MuiButtonBase-root.dark-mode.MuiButton-textPrimary[disabled],
.button_control .MuiButtonBase-root.dark-mode.MuiIconButton-colorPrimary[disabled],
.button_control .MuiButtonBase-root.dark-mode.MuiButton-outlinedPrimary[disabled] {
  background: #585858;
}
.button_control .MuiButtonBase-root.round {
  border-radius: 50%;
}
.button_control .MuiButton-outlined.MuiButtonBase-root {
  box-sizing: border-box;
}
.button_control--large .MuiButtonBase-root {
  line-height: 24px;
}
.button_control--medium .MuiButtonBase-root {
  line-height: 18px;
  padding: 11px 16px;
}
.button_control--small .MuiButtonBase-root {
  line-height: 15px;
  padding: 4.5px;
  height: 24px;
}
.button_control--endIcon hr {
  width: 1px;
  height: 24px;
  margin: 0 12px;
  background-color: rgba(136, 136, 136, 0.4);
}
.button_control.hasErrors .MuiButton-startIcon {
  color: #FF5757;
}
.button_control .MuiButtonBase-root.highlighted,
.button_control--endIcon.highlighted,
.button_control .MuiButtonBase-root.highlighted:hover,
.button_control--endIcon.highlighted:hover,
.button_control .MuiButtonBase-root.highlighted.MuiButton-textPrimary,
.button_control--endIcon.highlighted.MuiButton-textPrimary,
.button_control .MuiButtonBase-root.highlighted:hover.MuiButton-textPrimary,
.button_control--endIcon.highlighted:hover.MuiButton-textPrimary,
.button_control .MuiButtonBase-root.highlighted.MuiIconButton-colorPrimary,
.button_control--endIcon.highlighted.MuiIconButton-colorPrimary,
.button_control .MuiButtonBase-root.highlighted:hover.MuiIconButton-colorPrimary,
.button_control--endIcon.highlighted:hover.MuiIconButton-colorPrimary,
.button_control .MuiButtonBase-root.highlighted.MuiButton-outlinedPrimary,
.button_control--endIcon.highlighted.MuiButton-outlinedPrimary,
.button_control .MuiButtonBase-root.highlighted:hover.MuiButton-outlinedPrimary,
.button_control--endIcon.highlighted:hover.MuiButton-outlinedPrimary,
.button_control .MuiButtonBase-root.highlighted.dark-mode,
.button_control--endIcon.highlighted.dark-mode,
.button_control .MuiButtonBase-root.highlighted:hover.dark-mode,
.button_control--endIcon.highlighted:hover.dark-mode,
.button_control .MuiButtonBase-root.highlighted.MuiButton-textPrimary.dark-mode,
.button_control--endIcon.highlighted.MuiButton-textPrimary.dark-mode,
.button_control .MuiButtonBase-root.highlighted:hover.MuiButton-textPrimary.dark-mode,
.button_control--endIcon.highlighted:hover.MuiButton-textPrimary.dark-mode,
.button_control .MuiButtonBase-root.highlighted.MuiIconButton-colorPrimary.dark-mode,
.button_control--endIcon.highlighted.MuiIconButton-colorPrimary.dark-mode,
.button_control .MuiButtonBase-root.highlighted:hover.MuiIconButton-colorPrimary.dark-mode,
.button_control--endIcon.highlighted:hover.MuiIconButton-colorPrimary.dark-mode,
.button_control .MuiButtonBase-root.highlighted.MuiButton-outlinedPrimary.dark-mode,
.button_control--endIcon.highlighted.MuiButton-outlinedPrimary.dark-mode,
.button_control .MuiButtonBase-root.highlighted:hover.MuiButton-outlinedPrimary.dark-mode,
.button_control--endIcon.highlighted:hover.MuiButton-outlinedPrimary.dark-mode {
  background: #255ACC;
  color: #FFFFFF;
}
.button_control .MuiButtonBase-root.highlighted .MuiIcon-root,
.button_control--endIcon.highlighted .MuiIcon-root,
.button_control .MuiButtonBase-root.highlighted:hover .MuiIcon-root,
.button_control--endIcon.highlighted:hover .MuiIcon-root,
.button_control .MuiButtonBase-root.highlighted.MuiButton-textPrimary .MuiIcon-root,
.button_control--endIcon.highlighted.MuiButton-textPrimary .MuiIcon-root,
.button_control .MuiButtonBase-root.highlighted:hover.MuiButton-textPrimary .MuiIcon-root,
.button_control--endIcon.highlighted:hover.MuiButton-textPrimary .MuiIcon-root,
.button_control .MuiButtonBase-root.highlighted.MuiIconButton-colorPrimary .MuiIcon-root,
.button_control--endIcon.highlighted.MuiIconButton-colorPrimary .MuiIcon-root,
.button_control .MuiButtonBase-root.highlighted:hover.MuiIconButton-colorPrimary .MuiIcon-root,
.button_control--endIcon.highlighted:hover.MuiIconButton-colorPrimary .MuiIcon-root,
.button_control .MuiButtonBase-root.highlighted.MuiButton-outlinedPrimary .MuiIcon-root,
.button_control--endIcon.highlighted.MuiButton-outlinedPrimary .MuiIcon-root,
.button_control .MuiButtonBase-root.highlighted:hover.MuiButton-outlinedPrimary .MuiIcon-root,
.button_control--endIcon.highlighted:hover.MuiButton-outlinedPrimary .MuiIcon-root,
.button_control .MuiButtonBase-root.highlighted.dark-mode .MuiIcon-root,
.button_control--endIcon.highlighted.dark-mode .MuiIcon-root,
.button_control .MuiButtonBase-root.highlighted:hover.dark-mode .MuiIcon-root,
.button_control--endIcon.highlighted:hover.dark-mode .MuiIcon-root,
.button_control .MuiButtonBase-root.highlighted.MuiButton-textPrimary.dark-mode .MuiIcon-root,
.button_control--endIcon.highlighted.MuiButton-textPrimary.dark-mode .MuiIcon-root,
.button_control .MuiButtonBase-root.highlighted:hover.MuiButton-textPrimary.dark-mode .MuiIcon-root,
.button_control--endIcon.highlighted:hover.MuiButton-textPrimary.dark-mode .MuiIcon-root,
.button_control .MuiButtonBase-root.highlighted.MuiIconButton-colorPrimary.dark-mode .MuiIcon-root,
.button_control--endIcon.highlighted.MuiIconButton-colorPrimary.dark-mode .MuiIcon-root,
.button_control .MuiButtonBase-root.highlighted:hover.MuiIconButton-colorPrimary.dark-mode .MuiIcon-root,
.button_control--endIcon.highlighted:hover.MuiIconButton-colorPrimary.dark-mode .MuiIcon-root,
.button_control .MuiButtonBase-root.highlighted.MuiButton-outlinedPrimary.dark-mode .MuiIcon-root,
.button_control--endIcon.highlighted.MuiButton-outlinedPrimary.dark-mode .MuiIcon-root,
.button_control .MuiButtonBase-root.highlighted:hover.MuiButton-outlinedPrimary.dark-mode .MuiIcon-root,
.button_control--endIcon.highlighted:hover.MuiButton-outlinedPrimary.dark-mode .MuiIcon-root,
.button_control .MuiButtonBase-root.highlighted .icon_atom.ui-icon,
.button_control--endIcon.highlighted .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.highlighted:hover .icon_atom.ui-icon,
.button_control--endIcon.highlighted:hover .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.highlighted.MuiButton-textPrimary .icon_atom.ui-icon,
.button_control--endIcon.highlighted.MuiButton-textPrimary .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.highlighted:hover.MuiButton-textPrimary .icon_atom.ui-icon,
.button_control--endIcon.highlighted:hover.MuiButton-textPrimary .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.highlighted.MuiIconButton-colorPrimary .icon_atom.ui-icon,
.button_control--endIcon.highlighted.MuiIconButton-colorPrimary .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.highlighted:hover.MuiIconButton-colorPrimary .icon_atom.ui-icon,
.button_control--endIcon.highlighted:hover.MuiIconButton-colorPrimary .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.highlighted.MuiButton-outlinedPrimary .icon_atom.ui-icon,
.button_control--endIcon.highlighted.MuiButton-outlinedPrimary .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.highlighted:hover.MuiButton-outlinedPrimary .icon_atom.ui-icon,
.button_control--endIcon.highlighted:hover.MuiButton-outlinedPrimary .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.highlighted.dark-mode .icon_atom.ui-icon,
.button_control--endIcon.highlighted.dark-mode .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.highlighted:hover.dark-mode .icon_atom.ui-icon,
.button_control--endIcon.highlighted:hover.dark-mode .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.highlighted.MuiButton-textPrimary.dark-mode .icon_atom.ui-icon,
.button_control--endIcon.highlighted.MuiButton-textPrimary.dark-mode .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.highlighted:hover.MuiButton-textPrimary.dark-mode .icon_atom.ui-icon,
.button_control--endIcon.highlighted:hover.MuiButton-textPrimary.dark-mode .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.highlighted.MuiIconButton-colorPrimary.dark-mode .icon_atom.ui-icon,
.button_control--endIcon.highlighted.MuiIconButton-colorPrimary.dark-mode .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.highlighted:hover.MuiIconButton-colorPrimary.dark-mode .icon_atom.ui-icon,
.button_control--endIcon.highlighted:hover.MuiIconButton-colorPrimary.dark-mode .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.highlighted.MuiButton-outlinedPrimary.dark-mode .icon_atom.ui-icon,
.button_control--endIcon.highlighted.MuiButton-outlinedPrimary.dark-mode .icon_atom.ui-icon,
.button_control .MuiButtonBase-root.highlighted:hover.MuiButton-outlinedPrimary.dark-mode .icon_atom.ui-icon,
.button_control--endIcon.highlighted:hover.MuiButton-outlinedPrimary.dark-mode .icon_atom.ui-icon {
  color: #FFFFFF;
}
.button_control .MuiButtonBase-root.highlighted hr,
.button_control--endIcon.highlighted hr,
.button_control .MuiButtonBase-root.highlighted:hover hr,
.button_control--endIcon.highlighted:hover hr,
.button_control .MuiButtonBase-root.highlighted.MuiButton-textPrimary hr,
.button_control--endIcon.highlighted.MuiButton-textPrimary hr,
.button_control .MuiButtonBase-root.highlighted:hover.MuiButton-textPrimary hr,
.button_control--endIcon.highlighted:hover.MuiButton-textPrimary hr,
.button_control .MuiButtonBase-root.highlighted.MuiIconButton-colorPrimary hr,
.button_control--endIcon.highlighted.MuiIconButton-colorPrimary hr,
.button_control .MuiButtonBase-root.highlighted:hover.MuiIconButton-colorPrimary hr,
.button_control--endIcon.highlighted:hover.MuiIconButton-colorPrimary hr,
.button_control .MuiButtonBase-root.highlighted.MuiButton-outlinedPrimary hr,
.button_control--endIcon.highlighted.MuiButton-outlinedPrimary hr,
.button_control .MuiButtonBase-root.highlighted:hover.MuiButton-outlinedPrimary hr,
.button_control--endIcon.highlighted:hover.MuiButton-outlinedPrimary hr,
.button_control .MuiButtonBase-root.highlighted.dark-mode hr,
.button_control--endIcon.highlighted.dark-mode hr,
.button_control .MuiButtonBase-root.highlighted:hover.dark-mode hr,
.button_control--endIcon.highlighted:hover.dark-mode hr,
.button_control .MuiButtonBase-root.highlighted.MuiButton-textPrimary.dark-mode hr,
.button_control--endIcon.highlighted.MuiButton-textPrimary.dark-mode hr,
.button_control .MuiButtonBase-root.highlighted:hover.MuiButton-textPrimary.dark-mode hr,
.button_control--endIcon.highlighted:hover.MuiButton-textPrimary.dark-mode hr,
.button_control .MuiButtonBase-root.highlighted.MuiIconButton-colorPrimary.dark-mode hr,
.button_control--endIcon.highlighted.MuiIconButton-colorPrimary.dark-mode hr,
.button_control .MuiButtonBase-root.highlighted:hover.MuiIconButton-colorPrimary.dark-mode hr,
.button_control--endIcon.highlighted:hover.MuiIconButton-colorPrimary.dark-mode hr,
.button_control .MuiButtonBase-root.highlighted.MuiButton-outlinedPrimary.dark-mode hr,
.button_control--endIcon.highlighted.MuiButton-outlinedPrimary.dark-mode hr,
.button_control .MuiButtonBase-root.highlighted:hover.MuiButton-outlinedPrimary.dark-mode hr,
.button_control--endIcon.highlighted:hover.MuiButton-outlinedPrimary.dark-mode hr {
  background-color: #FFFFFF;
}
