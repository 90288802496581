.control_wrapper {
  margin: 2px 2px;
  display: inline-flex;
  min-height: 40px;
  min-width: 40px;
  align-items: start;
  vertical-align: top;
}
.control_wrapper--align-items-center {
  align-items: center;
}
.control_wrapper--full-width {
  width: calc(100% - (2px * 2));
}
.control_wrapper--medium {
  min-height: 32px;
  min-width: 32px;
}
.control_wrapper--small {
  min-height: 24px;
  min-width: 24px;
}
