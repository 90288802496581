.autograde_timeout {
  text-align: center;
  color: #36363E;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.autograde_timeout .title {
  font-size: 36px;
  line-height: 44px;
  color: #36363E;
  font-weight: 600;
}
.autograde_timeout .text {
  font-size: 20px;
  line-height: 26px;
  margin-top: 20px;
}
.autograde_timeout__center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.autograde_timeout img {
  max-width: 240px;
}
