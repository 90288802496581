.course_autograde_page {
  color: #36363E;
}
.course_autograde_page h1 {
  font-size: 36px;
  line-height: 44px;
  margin-top: 150px;
}
.course_autograde_page h2 {
  font-size: 24px;
  line-height: 36px;
}
.course_autograde_page p {
  font-size: 24px;
  line-height: 36px;
}
.course_autograde_page p + h2 {
  margin-top: 80px;
}
.course_autograde_page__img {
  display: block;
  margin: 40px auto 0;
}
.course_autograde_page__wrapper {
  max-width: 720px;
  margin: 50px auto;
  padding: 0 16px 100px;
}
.course_autograde_page__bottom_button_wrapper {
  text-align: center;
  position: fixed;
  bottom: 30px;
  left: 50%;
  margin-left: -164px;
  width: 328px;
}
.course_autograde_page__bottom_button {
  background: linear-gradient(180deg, #8CD100 0%, #66AF31 100%);
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  padding: 17px;
  box-sizing: border-box;
  width: 328px;
  border-radius: 12px;
  border: none;
  height: 56px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-family: inherit;
  font-weight: 600;
  max-width: 100%;
}
.course_autograde_page__bottom_button:hover {
  background: #599900;
}
.course_autograde_page__bottom_button:visited {
  color: #fff;
}
.course_autograde_page__bottom_button:disabled {
  background: rgba(167, 179, 194, 0.5);
  cursor: default;
}
@media (max-width: 1200px) {
  .course_autograde_page h1 {
    font-size: 24px;
    line-height: 30px;
    margin-top: 16px;
  }
  .course_autograde_page h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .course_autograde_page p {
    font-size: 18px;
    line-height: 24px;
  }
  .course_autograde_page p + h2 {
    margin-top: 50px;
  }
  .course_autograde_page__img {
    display: block;
    margin: 10px auto;
  }
  .course_autograde_page__wrapper {
    padding-bottom: 50px;
    margin: 16px auto;
  }
  .course_autograde_page__bottom_button_wrapper {
    padding: 8px 16px;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    width: auto;
  }
}
