.module-page-complexity-control__wrapper {
  padding-top: 40px;
  padding-bottom: 0;
  align-items: baseline;
}
@media (max-width: 760px) {
  .module-page-complexity-control__wrapper {
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    box-sizing: border-box;
  }
}
.module-page-complexity-control__label {
  color: #999999;
  white-space: nowrap;
}
.module-page-complexity-control__chips {
  margin-top: 8px;
  justify-content: flex-start;
}
