.chains-course-card {
  position: relative;
  min-height: 136px;
  border: 0;
  border-radius: 24px;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  background-color: #6357D9;
}
.chains-course-card__wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 80px;
  padding: 16px 24px 16px 16px;
  border: 0;
  border-radius: 23px;
  background-color: rgba(255, 255, 255, 0.8);
  overflow: hidden;
}
.chains-course-card__icon {
  position: absolute;
  top: 24px;
  right: 22px;
}
.chains-course-card__comment {
  margin-top: 8px;
  opacity: 0.5;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}
.chains-course-card__wrapper-out {
  break-inside: avoid;
}
.chains-course-card__margin-block {
  height: 16px;
  background-color: transparent;
}
@media (max-width: 1440px) {
  .chains-course-card {
    min-height: 128px;
    font-size: 16px;
    line-height: 20px;
  }
  .chains-course-card__wrapper {
    right: 56px;
  }
  .chains-course-card__comment {
    font-size: 14px;
  }
  .chains-course-card__icon {
    top: 16px;
    right: 10px;
  }
}
