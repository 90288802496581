.required_modules_list_item {
  text-decoration: none;
  min-height: 80px;
  position: relative;
  cursor: pointer;
  padding-right: 50px;
  box-sizing: border-box;
  transition: all 0.4s;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.required_modules_list_item svg {
  position: absolute;
  top: 11px;
  right: 16px;
}
.required_modules_list_item__title {
  padding: 18px 16px 8px 16px;
  line-height: 22px;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: #FFFFFF;
}
