/* Generated with js2less.js.
Original file: src/styles/values/grayscale.json */
/* Generated with js2less.js.
Original file: src/styles/values/breakpoints.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-action.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-wrong.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-good.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-attention.json */
.teacher-dropdown.dropdown-select {
  background-color: transparent;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
}
.teacher-dropdown.dropdown-select:hover {
  background-color: #FFFFFF;
}
.teacher-dropdown.dropdown-select .dropdown-select__select-item {
  white-space: normal;
}
