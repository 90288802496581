.element-client-validation-label {
  align-items: center;
}
.element-client-validation-label-level-error,
.theme-light .element-client-validation-label-level-error .ui-icon {
  color: #DF222E;
}
.element-client-validation-label-level-warning,
.theme-light .element-client-validation-label-level-warning .ui-icon {
  color: #E76A01;
}
.element-client-validation-label .ui-icon {
  align-self: flex-start;
}
.element-client-validation-label-has-level-error > .select-dropdown-answer > .dropdown-select,
.element-client-validation-label-has-level-error > .multiply_answers > .multiply_answers__input_wrapper > .multiply_answers__input,
.element-client-validation-label-has-level-error > .detailed_answer > .detailed_answer__message_form,
.element-client-validation-label-has-level-error > .match_answers .match_answers__field,
.element-client-validation-label-has-level-error > .select_option_answer {
  box-shadow: 0px 0px 0px 1px #F94C4F;
}
.element-client-validation-label-has-level-warning > .select-dropdown-answer > .dropdown-select,
.element-client-validation-label-has-level-warning > .multiply_answers > .multiply_answers__input_wrapper > .multiply_answers__input,
.element-client-validation-label-has-level-warning > .detailed_answer > .detailed_answer__message_form,
.element-client-validation-label-has-level-warning > .match_answers .match_answers__field,
.element-client-validation-label-has-level-warning > .select_option_answer {
  box-shadow: 0px 0px 0px 1px #FD9601;
}
