.modal_window {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_window--popover {
  background: none;
  top: unset;
  left: unset;
  bottom: unset;
  right: unset;
}
.modal_window__wrapper {
  position: fixed;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  max-width: 760px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.modal_window__wrapper--narrow {
  max-width: 360px;
  width: 360px;
}
.modal_window__wrapper--popover {
  width: fit-content;
  width: -moz-fit-content;
}
.modal_window__wrapper--narrow.modal_window__wrapper--popover {
  max-width: 360px;
  width: 360px;
}
.modal_window__wrapper--highlighted {
  padding: 16px;
  background-color: #255ACC;
  color: #fff;
}
.modal_window__wrapper--scrollable .modal_window__footer {
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
}
.modal_window__wrapper--scrollable .modal_window__header {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 0 0;
}
.modal_window__content {
  scrollbar-width: thin;
  scrollbar-color: rgba(19, 16, 16, 0.73) transparent;
  overflow: auto;
  white-space: pre-line;
}
.modal_window__content::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.modal_window__content::-webkit-scrollbar-track {
  background: transparent;
}
.modal_window__content::-webkit-scrollbar-thumb {
  background: rgba(19, 16, 16, 0.73);
  border-radius: 5px;
}
.modal_window__content::-webkit-scrollbar-thumb:hover {
  background: #131010;
}
.modal_window__content .MuiTypography-body1,
.modal_window__content .MuiInputBase-root {
  font-family: inherit;
}
.modal_window__content--disabledOverflow {
  overflow: unset;
}
.modal_window__header {
  padding: 8px;
  justify-content: space-between;
  display: flex;
  position: relative;
  z-index: 2;
  align-items: flex-start;
  min-height: fit-content;
}
.modal_window__header_title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  min-height: 40px;
  display: inline-flex;
  align-items: flex-start;
}
.modal_window__footer {
  min-height: 64px;
  position: relative;
  z-index: 2;
}
.modal_window__controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 760px) {
  .modal_window {
    align-items: flex-end;
  }
  .modal_window--popover {
    background: rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .modal_window__wrapper {
    animation: modal_window_slide_to_top 300ms ease-out backwards;
  }
  .modal_window__wrapper--popover {
    width: 100vw;
  }
  .modal_window__wrapper--highlighted {
    background-color: #fff;
    color: initial;
    max-width: 100%;
  }
  .modal_window__header_title {
    flex: 1;
  }
}
.ui .modal_window {
  z-index: 12000;
}
@keyframes modal_window_slide_to_top {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}
