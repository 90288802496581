.course-progress-line {
  position: absolute;
  top: 25px;
  left: 54px;
  width: 65%;
}
.course-progress-line__level {
  width: 100%;
  padding: 10px 0;
  margin-top: -10px;
  position: relative;
  margin-right: 15px;
}
.course-progress-line__progress {
  border-radius: 20px;
  background-color: rgba(179, 179, 179, 0.53);
  width: 100%;
  height: 6px;
  position: relative;
}
.course-progress-line__part {
  height: 6px;
  border-radius: 20px;
  position: absolute;
  top: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
}
.course-progress-line__part-done {
  background-color: #93C631;
}
.course-progress-line__part-fail {
  background-color: #FF7171;
  right: 0;
}
.course-progress-line__point {
  border-radius: 50px;
  position: absolute;
  top: 5.1px;
  cursor: pointer;
  z-index: 0;
  margin-left: -10px;
  padding: 8px;
}
.course-progress-line__point-type-done {
  background-color: #93C631;
}
.course-progress-line__point-type-fail {
  background-color: #FF7171;
}
.course-progress-line__point-type-normal {
  background-color: rgba(179, 179, 179, 0.53);
}
