__solution {
  /*no-& {
        min-height: 36px;
    }*/
}
.solution_to_show_popup__solutions {
  padding: 0px;
}
.solution_to_show_popup__solutions __solution {
  font-size: 16px;
  margin: 8px;
}
