.landing_course_card {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  vertical-align: top;
  margin: 10px;
  border-radius: 12px;
  min-height: 240px;
  width: calc(25% - 20px);
  color: #FFFFFF;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  background: #6357D9;
}
.landing_course_card:hover {
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1440px) {
  .landing_course_card {
    width: calc(33% - 20px);
  }
}
@media (max-width: 1200px) {
  .landing_course_card {
    width: calc(50% - 20px);
  }
}
.landing_course_card__img {
  position: absolute;
  left: 75%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  object-fit: cover;
  height: 240px;
}
@media (max-width: 760px) {
  .landing_course_card {
    width: calc(100% - 16px);
    margin: 4px 8px;
  }
  .landing_course_card--my_course,
  .landing_course_card--invite {
    padding-bottom: 84px;
  }
  .landing_course_card--has_img {
    padding-bottom: 250px;
    box-sizing: border-box;
  }
  .landing_course_card__img {
    top: auto;
    bottom: -90px;
  }
}
.landing_course_card__date {
  font-size: 13px;
  line-height: 15px;
  height: 16px;
  color: rgba(255, 255, 255, 0.7);
}
.landing_course_card__date--my {
  display: inline-block;
  vertical-align: middle;
}
@media (max-width: 760px) {
  .landing_course_card__date--my {
    display: block;
    margin-top: 8px;
    height: 28px;
    padding-top: 12px;
    box-sizing: border-box;
    width: calc(100% - 156px);
  }
}
.landing_course_card__content {
  font-size: 13px;
  line-height: 18px;
  margin-top: 8px;
  color: rgba(255, 255, 255, 0.7);
}
.landing_course_card--my_course .landing_course_card__stats,
.landing_course_card--invite .landing_course_card__stats {
  color: rgba(255, 255, 255, 0.7);
}
@media (max-width: 760px) {
  .landing_course_card--my_course .landing_course_card__stats,
  .landing_course_card--invite .landing_course_card__stats {
    bottom: 24px;
    width: 100%;
  }
}
.landing_course_card--my_course,
.landing_course_card--invite {
  width: calc(50% - 20px);
  min-height: 300px;
}
@media (max-width: 1200px) {
  .landing_course_card--my_course,
  .landing_course_card--invite {
    width: calc(100% - 20px);
  }
}
@media (max-width: 760px) {
  .landing_course_card--my_course,
  .landing_course_card--invite {
    width: calc(100% - 16px);
  }
}
.landing_course_card--upcomming {
  background: #FFFFFF;
  border-radius: 16px;
  color: #36363E;
}
.landing_course_card--upcomming .landing_course_card__date,
.landing_course_card--upcomming .landing_course_card__content {
  color: #36363E;
}
.landing_course_card--closed {
  background: #EDEEF5;
  border-radius: 16px;
  color: #8C8695;
}
.landing_course_card--closed .landing_course_card__date,
.landing_course_card--closed .landing_course_card__content {
  color: #8C8695;
}
.landing_course_card__wrapper {
  padding: 16px;
  min-height: 156px;
  position: relative;
}
.landing_course_card__progress {
  min-width: 215px;
}
.landing_course_card__progress_lines {
  width: 157px;
  display: inline-block;
  vertical-align: middle;
  padding: 10px 0 0 10px;
}
.landing_course_card__progress_starred {
  display: inline-block;
  vertical-align: middle;
}
.landing_course_card__progress_starred svg {
  vertical-align: middle;
}
.landing_course_card__progress_starred_numbers {
  display: inline-block;
  vertical-align: middle;
  margin-left: 7px;
  color: rgba(255, 255, 255, 0.6);
}
.landing_course_card__title {
  font-size: 22px;
  line-height: 26px;
  margin-top: 16px;
  font-weight: 600;
}
.landing_course_card__title_invitation {
  color: #88CC00;
}
.landing_course_card__menu {
  max-width: 70%;
}
@media (max-width: 760px) {
  .landing_course_card__menu {
    max-width: 100%;
  }
}
.landing_course_card__stats {
  font-size: 0;
  position: absolute;
  left: 16px;
  bottom: 16px;
}
.landing_course_card__stats_block {
  display: inline-block;
  vertical-align: middle;
  width: 90px;
  padding-right: 6px;
  height: 24px;
  font-size: 13px;
  line-height: 20px;
}
.landing_course_card__stats_block svg {
  margin-right: 10px;
  vertical-align: middle;
  display: inline-block;
}
.landing_course_card__stats_block_text {
  display: inline-block;
  vertical-align: middle;
}
.landing_course_card__button {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 12px 24px rgba(84, 120, 8, 0.025);
  border-radius: 8px;
  padding: 12px 20px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin: 0 8px 8px 0;
  transition: all 0.2s;
  align-self: flex-end;
}
.landing_course_card__button:hover {
  background: rgba(255, 255, 255, 0.2);
}
.landing_course_card--upcomming .landing_course_card__button {
  background: #F1EDFA;
  color: #7B29C9;
}
.landing_course_card--upcomming .landing_course_card__button:hover {
  background: #EAE0FF;
}
.landing_course_card a.course-sidebar-header__main-menu-item {
  font-size: 15px;
}
.landing_course_card--my_course.landing_course_card--closed .landing_course_card__title,
.landing_course_card--invite.landing_course_card--closed .landing_course_card__title {
  color: #36363E;
}
.landing_course_card--my_course.landing_course_card--closed .landing_course_card__date,
.landing_course_card--invite.landing_course_card--closed .landing_course_card__date {
  color: #8C8695;
}
.landing_course_card--my_course.landing_course_card--closed .landing_course_card__stats_block_text,
.landing_course_card--invite.landing_course_card--closed .landing_course_card__stats_block_text {
  color: #8C8695;
}
.landing_course_card--my_course.landing_course_card--closed a.course-sidebar-header__main-menu-item,
.landing_course_card--invite.landing_course_card--closed a.course-sidebar-header__main-menu-item {
  color: #7B29C9;
  background: rgba(54, 54, 62, 0.1);
}
.landing_course_card--my_course.landing_course_card--closed a.course-sidebar-header__main-menu-item:hover,
.landing_course_card--invite.landing_course_card--closed a.course-sidebar-header__main-menu-item:hover {
  background: rgba(54, 54, 62, 0.2);
}
.landing_course_card--my_course.landing_course_card--closed .landing_course_card__progress_starred_numbers,
.landing_course_card--my_course.landing_course_card--invite .landing_course_card__progress_starred_numbers {
  color: #8C8695;
}
