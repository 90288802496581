.icon_button_control {
  border-radius: 4px;
}
.icon_button_control .MuiIconButton-root {
  min-width: 100%;
  min-height: 100%;
  cursor: pointer;
}
.icon_button_control .MuiIconButton-root.restrict-hover {
  background: inherit;
  cursor: auto;
}
.icon_button_control .MuiButtonBase-root {
  padding: 8px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  color: #929292;
  outline: none;
  cursor: pointer;
}
.icon_button_control .MuiButtonBase-root:hover:not(.restrict-hover) {
  background: rgba(51, 51, 51, 0.06);
  color: #717171;
}
.icon_button_control .MuiButtonBase-root:hover:not(.restrict-hover) svg path {
  fill: #717171;
}
.icon_button_control .MuiButtonBase-root.MuiIconButton-colorInherit,
.icon_button_control .MuiButtonBase-root.MuiIconButton-colorInherit:hover {
  color: inherit;
}
.icon_button_control .MuiButtonBase-root.pressed {
  background: rgba(51, 51, 51, 0.05);
  color: #909090;
}
.icon_button_control .MuiButtonBase-root.error {
  color: #FF5757;
}
.icon_button_control .MuiButtonBase-root:active {
  background: #E6E6E6;
  color: #717171;
}
.icon_button_control .MuiButtonBase-root[disabled] {
  background: none;
  color: #e0e0e0;
}
.icon_button_control .MuiButtonBase-root.MuiButton-textPrimary,
.icon_button_control .MuiButtonBase-root.MuiIconButton-colorPrimary {
  background: #333333;
  color: #FFFFFF;
}
.icon_button_control .MuiButtonBase-root.MuiButton-textPrimary:hover:not(.restrict-hover),
.icon_button_control .MuiButtonBase-root.MuiIconButton-colorPrimary:hover:not(.restrict-hover) {
  background: #474747;
}
.icon_button_control .MuiButtonBase-root.MuiButton-textPrimary.pressed,
.icon_button_control .MuiButtonBase-root.MuiIconButton-colorPrimary.pressed {
  background: #656565;
}
.icon_button_control .MuiButtonBase-root.MuiButton-textPrimary.error,
.icon_button_control .MuiButtonBase-root.MuiIconButton-colorPrimary.error {
  color: #FF5757;
}
.icon_button_control .MuiButtonBase-root.MuiButton-textPrimary[disabled],
.icon_button_control .MuiButtonBase-root.MuiIconButton-colorPrimary[disabled] {
  background: #D3D3D3;
}
.icon_button_control .MuiButtonBase-root.dark-mode {
  color: #FFFFFF;
}
.icon_button_control .MuiButtonBase-root.dark-mode:hover:not(.restrict-hover) {
  background: rgba(255, 255, 255, 0.1);
  color: #FFFFFF;
}
.icon_button_control .MuiButtonBase-root.dark-mode:focus:not(.restrict-focus) {
  box-shadow: 0 0 0 2px #2F82FF;
}
.icon_button_control .MuiButtonBase-root.dark-mode.pressed {
  background: rgba(255, 255, 255, 0.2);
}
.icon_button_control .MuiButtonBase-root.dark-mode.error {
  color: #FF5757;
}
.icon_button_control .MuiButtonBase-root.dark-mode[disabled] {
  background: none;
  color: rgba(255, 255, 255, 0.25);
}
.icon_button_control .MuiButtonBase-root.dark-mode.MuiButton-textPrimary,
.icon_button_control .MuiButtonBase-root.dark-mode.MuiIconButton-colorPrimary {
  background: #FFFFFF;
  color: #1e1e1e;
}
.icon_button_control .MuiButtonBase-root.dark-mode.MuiButton-textPrimary:hover:not(.restrict-hover),
.icon_button_control .MuiButtonBase-root.dark-mode.MuiIconButton-colorPrimary:hover:not(.restrict-hover) {
  background: #E6E6E6;
}
.icon_button_control .MuiButtonBase-root.dark-mode.MuiButton-textPrimary.pressed,
.icon_button_control .MuiButtonBase-root.dark-mode.MuiIconButton-colorPrimary.pressed {
  background: #BFBFBF;
}
.icon_button_control .MuiButtonBase-root.dark-mode.MuiButton-textPrimary.error,
.icon_button_control .MuiButtonBase-root.dark-mode.MuiIconButton-colorPrimary.error {
  color: #FF5757;
}
.icon_button_control .MuiButtonBase-root.dark-mode.MuiButton-textPrimary[disabled],
.icon_button_control .MuiButtonBase-root.dark-mode.MuiIconButton-colorPrimary[disabled] {
  background: #585858;
}
.icon_button_control .MuiButtonBase-root .MuiIcon-root,
.icon_button_control .MuiButtonBase-root .icon_atom.ui-icon {
  color: inherit;
}
.icon_button_control .MuiButtonBase-root.round {
  border-radius: 50%;
}
.icon_button_control .MuiButtonBase-root.highlighted,
.icon_button_control .MuiButtonBase-root.highlighted:hover,
.icon_button_control .MuiButtonBase-root.highlighted.dark-mode,
.icon_button_control .MuiButtonBase-root.highlighted:hover.dark-mode,
.icon_button_control .MuiButtonBase-root.highlighted.MuiButton-textPrimary,
.icon_button_control .MuiButtonBase-root.highlighted:hover.MuiButton-textPrimary,
.icon_button_control .MuiButtonBase-root.highlighted.dark-mode.MuiButton-textPrimary,
.icon_button_control .MuiButtonBase-root.highlighted:hover.dark-mode.MuiButton-textPrimary,
.icon_button_control .MuiButtonBase-root.highlighted.MuiIconButton-colorPrimary,
.icon_button_control .MuiButtonBase-root.highlighted:hover.MuiIconButton-colorPrimary,
.icon_button_control .MuiButtonBase-root.highlighted.dark-mode.MuiIconButton-colorPrimary,
.icon_button_control .MuiButtonBase-root.highlighted:hover.dark-mode.MuiIconButton-colorPrimary {
  background: #255ACC;
  color: #FFFFFF;
}
.icon_button_control .outlined.MuiButtonBase-root {
  border: 1px solid #DCDCDC;
  box-sizing: border-box;
}
.icon_button_control--medium .MuiButtonBase-root {
  padding: 11px;
}
.icon_button_control--small .MuiButtonBase-root {
  height: 24px;
  width: 24px;
  padding: 4.5px;
}
.icon_button_control--fab .MuiButtonBase-root {
  height: 56px;
  width: 56px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.35);
}
.icon_button_control--layout .MuiButtonBase-root.dark-mode {
  height: 56px;
  width: 56px;
}
.icon_button_control--layout .MuiButtonBase-root.dark-mode .MuiIcon-root,
.icon_button_control--layout .MuiButtonBase-root.dark-mode .MuiSvgIcon-root,
.icon_button_control--layout .MuiButtonBase-root.dark-mode .icon_atom.ui-icon {
  color: #929292;
  fill: #929292;
}
.icon_button_control--layout .MuiButtonBase-root.dark-mode .MuiIcon-root *,
.icon_button_control--layout .MuiButtonBase-root.dark-mode .MuiSvgIcon-root *,
.icon_button_control--layout .MuiButtonBase-root.dark-mode .icon_atom.ui-icon * {
  fill: #929292;
}
.icon_button_control--layout .MuiButtonBase-root.dark-mode:hover .MuiIcon-root,
.icon_button_control--layout .MuiButtonBase-root.dark-mode:hover .MuiSvgIcon-root,
.icon_button_control--layout .MuiButtonBase-root.dark-mode:hover .icon_atom.ui-icon {
  color: #929292;
  fill: #929292;
}
.icon_button_control--layout .MuiButtonBase-root.dark-mode:hover .MuiIcon-root *,
.icon_button_control--layout .MuiButtonBase-root.dark-mode:hover .MuiSvgIcon-root *,
.icon_button_control--layout .MuiButtonBase-root.dark-mode:hover .icon_atom.ui-icon * {
  fill: #929292;
}
.icon_button_control--layout .MuiButtonBase-root.dark-mode.pressed .MuiIcon-root,
.icon_button_control--layout .MuiButtonBase-root.dark-mode.pressed .MuiSvgIcon-root,
.icon_button_control--layout .MuiButtonBase-root.dark-mode.pressed .icon_atom.ui-icon {
  color: #FFFFFF;
  fill: #FFFFFF;
}
.icon_button_control--layout .MuiButtonBase-root.dark-mode.pressed .MuiIcon-root *,
.icon_button_control--layout .MuiButtonBase-root.dark-mode.pressed .MuiSvgIcon-root *,
.icon_button_control--layout .MuiButtonBase-root.dark-mode.pressed .icon_atom.ui-icon * {
  fill: #FFFFFF;
}
.icon_button_control--layout .MuiButtonBase-root.dark-mode .MuiIcon-root,
.icon_button_control--layout .MuiButtonBase-root.dark-mode .MuiSvgIcon-root,
.icon_button_control--layout .MuiButtonBase-root.dark-mode .icon_atom.ui-icon {
  font-size: 48px;
}
