/* Generated with js2less.js.
Original file: src/styles/values/grayscale.json */
.teacher-panel {
  padding: 8px 16px;
  background: #FFFFFF;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  align-items: center;
  z-index: 999;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.teacher-panel__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #1A1A1A;
  margin: 0;
}
