.text_control {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 16px;
  max-width: 810px;
  transition: all 150ms;
}
.text_control--primary {
  font-size: 16px;
  font-weight: bold;
}
.text_control--disabled {
  color: #ADADAD;
}
.text_control--caption {
  color: #717171;
}
.text_control--captionHeight {
  line-height: 16px;
  font-size: 12px;
}
.text_control--bold {
  font-weight: 500;
}
.text_control--dark_mode {
  color: #FFFFFF;
}
.text_control--dark_mode.text_control--caption {
  color: #9F9F9F;
}
.text_control--dark_mode.text_control--caption:hover {
  color: #FFFFFF;
}
.text_control--clickable {
  cursor: pointer;
}
.text_control--hasErrors {
  color: #FF5757;
}
.text_control--text-align-center {
  text-align: center;
}
.text_control--hasHover:hover {
  background: rgba(51, 51, 51, 0.06);
  cursor: pointer;
  border-radius: 4px;
}
.text_control--wrap {
  white-space: pre-wrap;
}
