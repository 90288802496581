.prof-cheops-greeting {
  align-items: center;
  text-align: left;
}
.prof-cheops-greeting__content {
  max-width: 504px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  white-space: pre-line;
}
@media (max-width: 760px) {
  .prof-cheops-greeting__content {
    width: 90%;
    font-size: 16px;
  }
}
.prof-cheops-greeting__gap.gap-size-large {
  width: 16px;
  height: 16px;
}
@media (max-width: 760px) {
  .prof-cheops-greeting__gap.gap-size-large {
    width: 24px;
    height: 24px;
  }
}
@media (max-width: 760px) {
  .prof-cheops-greeting {
    flex-direction: column;
    text-align: center;
  }
}
