/* Generated with js2less.js.
Original file: src/styles/values/grayscale.json */
.textbook-module-card {
  display: inline-block;
  vertical-align: top;
  min-width: 300px;
  margin: 8px;
  height: 132px;
  background-color: #FFFFFF;
  border-radius: 16px;
  width: calc(20% - (8px * 2));
}
.textbook-module-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
.textbook-module-card__link {
  padding: 8px 16px;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  height: 100%;
  box-sizing: border-box;
}
.textbook-module-card__link-locked {
  pointer-events: none;
  cursor: default;
}
.textbook-module-card__progress {
  min-width: 40px;
  margin-right: 8px;
}
.textbook-module-card__title {
  color: #1A1A1A;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
  max-width: 300px;
  padding-top: 6px;
}
.textbook-module-card__complexity {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #999999;
  align-items: center;
}
@media (max-width: 2000px) {
  .textbook-module-card {
    width: calc(25% - (8px * 2));
  }
}
@media (max-width: 1700px) {
  .textbook-module-card {
    width: calc(33% - (8px * 2));
  }
}
@media (max-width: 1370px) {
  .textbook-module-card {
    width: calc(50% - (8px * 2));
  }
}
@media (max-width: 1050px) {
  .textbook-module-card {
    width: 100%;
  }
}
@media (max-width: 760px) {
  .textbook-module-card {
    margin: 4px 8px;
    width: calc(100% - (8px * 2));
  }
}
