/* Generated with js2less.js.
Original file: src/styles/values/grayscale.json */
/* Generated with js2less.js.
Original file: src/styles/values/breakpoints.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-action.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-wrong.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-good.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-attention.json */
.teacher-class-card {
  border-radius: 24px;
  min-height: 136px;
  position: relative;
  background-color: #999999;
}
.teacher-class-card__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 16px;
}
@media (max-width: 1200px) {
  .teacher-class-card__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 600px) {
  .teacher-class-card__wrapper {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 8px;
    column-gap: 0;
  }
}
.teacher-class-card__content {
  background-color: #FFFFFF;
  border-radius: inherit;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 80px;
  min-height: 100%;
  padding: 16px;
  box-sizing: border-box;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
}
.teacher-class-card__content-text {
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #1A1A1A;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.teacher-class-card__content:visited {
  color: #1A1A1A;
}
.teacher-class-card__icon {
  position: absolute;
  right: 16px;
  top: 16px;
}
