.confirm_edit_buttons__wrapper {
  justify-content: flex-start;
}
.confirm_edit_buttons__cancel {
  margin-right: 6px;
}
@media (max-width: 760px) {
  .confirm_edit_buttons__wrapper {
    flex-direction: row-reverse;
  }
}
