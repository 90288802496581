.programming-answer__editor {
  border: 1px solid #d5dde7;
  border-radius: 8px;
  padding: 12px 1px;
  background-color: #eaf3ff;
  font-size: 14px;
  min-height: 160px;
}
.programming-answer__editor-code {
  max-height: 70vh;
  overflow: scroll;
}
.programming-answer__editor-code > div {
  overflow: auto !important;
}
.programming-answer__editor-focus-within-textarea {
  outline: -webkit-focus-ring-color auto 1px;
  background-color: #F5F5F5;
}
.programming-answer__editor-textarea,
.programming-answer__editor-pre {
  outline: none;
  min-width: 53px;
}
.programming-answer__editor-pre .line-num {
  width: 0;
  position: absolute;
}
.programming-answer__editor-pre .line-num:before {
  content: attr(data-num);
  color: grey;
  text-align: right;
  width: 32px;
  display: inline-block;
  margin-left: -48px;
}
.programming-answer__editor-focus-within-textarea .programming-answer__editor-textarea,
.programming-answer__editor-focus-within-textarea .programming-answer__editor-pre {
  background-color: #FFFFFF;
}
.programming-answer__editor-clickable {
  cursor: pointer;
}
.programming-answer__editor-head {
  align-items: center;
  padding: 0px 12px;
}
.programming-answer__editor-head > b {
  font-size: 16px;
}
.programming-answer__editor-head .dropdown-select .select-option {
  font-size: 14px;
}
.programming-answer__editor-status-ok {
  background-color: #F1FAEA;
}
.programming-answer__editor-status-notok {
  background-color: #FFF0F2;
  cursor: pointer;
}
.programming-answer__editor-status-partly {
  background-color: #FEF5D9;
  cursor: pointer;
}
.programming-answer__verdicts-card {
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
}
.programming-answer__verdicts-card-ok {
  background-color: #F1FAEA;
}
.programming-answer__verdicts-card-notok {
  background-color: #FFF0F2;
}
.programming-answer__verdicts-card-partly {
  background-color: #FEF5D9;
}
.programming-answer__verdicts-card-clickable {
  cursor: pointer;
}
.programming-answer__verdicts-toggle {
  margin-top: 8px;
  padding: 16px 24px;
  color: #873CCE;
  background-color: #F5F5F5;
  box-shadow: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.programming-answer__verdicts-toggle:hover {
  background-color: #e6e6e6;
}
.programming-answer__verdicts-tm {
  color: rgba(0, 0, 0, 0.5);
}
.programming-answer__verdicts-head {
  font-size: 14px;
  align-items: center;
  padding: 16px;
}
.programming-answer__verdicts-head > b {
  font-size: 16px;
}
.programming-answer__verdicts-error {
  padding: 16px 24px;
  padding-top: 0;
  font-size: 13px;
  align-items: baseline;
}
.programming-answer__verdicts-score {
  font-weight: bold;
}
.programming-answer__verdicts-string {
  display: inline-flex;
  margin-left: 8px;
}
.programming-answer__verdicts-review {
  margin: 12px -16px -16px;
  line-height: 10px;
}
.programming-answer__verdicts-review-message {
  padding: 8px 24px;
  white-space: break-spaces;
  font-family: monospace;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.programming-answer__verdicts-code {
  font-size: 16px;
  line-height: 24px;
}
.programming-answer__verdicts-code .programming-answer__verdicts-review {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.programming-answer__verdicts-code .programming-answer__verdicts-review-panel {
  background-color: #36373D;
  color: #FFFFFF;
}
.programming-answer__verdicts-code .programming-answer__verdicts-review-result {
  padding: 8px 24px;
  font-family: "PT Root UI", Arial, sans-serif;
  font-weight: 500;
  line-height: 24px;
}
.programming-answer__verdicts-code .programming-answer__verdicts-review-result-sub {
  font-weight: normal;
  font-family: 'Source Code Pro', monospace;
  padding-left: 32px;
}
.programming-answer__copy-panel {
  background-color: white;
  width: 100%;
  height: 50px;
  padding: 12px 21px;
  border-top: 1px solid #E1DFE6;
}
.programming-answer__copy-panel-round {
  border-radius: 0 0 8px 8px;
}
.programming-answer__verdicts-code .programming-answer__copy-panel .programming-answer__copy-panel-icon {
  margin-right: 30px;
  cursor: pointer;
  width: 24px;
}
.programming-answer__limits {
  line-height: 30px;
}
.programming-answer__limits-head {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
}
.programming-answer__limits-list {
  margin: 0;
  padding: 0;
}
.programming-answer__limits-item {
  margin: 0;
  font-size: 16px;
  list-style-type: none;
}
