.module_card {
  width: 360px;
  display: inline-block;
  vertical-align: top;
  min-width: 300px;
}
.module_card a {
  text-decoration: none;
  display: block;
  border-radius: 16px;
  overflow: hidden;
  background: #3A3A40;
  color: #fff;
  font-size: 0;
}
.module_card__wrapper {
  min-height: 186px;
  box-sizing: border-box;
  position: relative;
  background: linear-gradient(180deg, #6F61F2 0%, #6357D9 100%);
  padding: 16px 16px 96px;
}
.module_card__header_text {
  width: 280px;
  display: inline-block;
  vertical-align: top;
  max-width: 90%;
}
.module_card__header {
  padding-bottom: 8px;
}
.module_card__header .progress {
  position: absolute;
  left: 8px;
  bottom: 10px;
}
.module_card__title {
  font-size: 18px;
  line-height: 22px;
}
.module_card__content {
  font-size: 13px;
  line-height: 18px;
  opacity: 0.5;
  position: absolute;
  left: 16px;
  bottom: 60px;
}
.module_card__continue_button {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  height: 36px;
  padding: 9px 14px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
}
.module_card__continue_button:hover {
  background: rgba(255, 255, 255, 0.15);
}
.module_card__continue_button--continue {
  background: linear-gradient(180deg, #8CD100 0%, #66AF31 100%);
  font-weight: bold;
  color: #fff;
}
.module_card__continue_button--continue:hover {
  background: #599900;
}
.module_card__time_text {
  position: absolute;
  left: 60px;
  bottom: 16px;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #fff;
  opacity: 0.5;
}
.module_card__lecturer {
  display: flex;
  width: 100%;
  height: 56px;
  overflow: hidden;
  transition: height 0.4s;
  border-radius: 16px 16px 0 0;
  position: relative;
  z-index: 0;
  padding: 16px 62px 16px 16px;
  box-sizing: border-box;
}
.module_card__lecturer_photo {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  overflow: hidden;
  border-radius: 50%;
}
.module_card__lecturer_photo img {
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
.module_card__lecturer_text {
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  opacity: 0.5;
}
.module_card__switcher {
  position: absolute;
  right: 20px;
}
.module_card__switcher_dot {
  opacity: 0.2;
  padding: 10px;
  cursor: pointer;
}
.module_card__switcher_dot--active {
  opacity: 1;
}
.module_card__switcher_dot:before {
  background: #fff;
  display: block;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  content: " ";
}
.module_card--not_available .module_card__wrapper {
  background: #494A52;
}
@media (max-width: 760px) {
  .module_card {
    width: auto;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
}
