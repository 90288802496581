.autograde_finished {
  padding-top: 100px;
  color: #fff;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, #6F61F2 0%, #6357D9 100%);
}
.autograde_finished__name {
  font-size: 24px;
  line-height: 24px;
}
@media (max-width: 760px) {
  .autograde_finished__name {
    font-size: 18px;
  }
}
.autograde_finished__stats {
  text-align: center;
  margin: 36px auto 20px;
  font-weight: 600;
  text-transform: uppercase;
}
@media (max-width: 760px) {
  .autograde_finished__stats {
    margin-top: 24px;
  }
}
.autograde_finished__stats_titles {
  display: flex;
  justify-content: center;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}
.autograde_finished__stats_titles > div {
  width: 150px;
  margin: 8px;
}
@media (max-width: 760px) {
  .autograde_finished__stats_titles > div {
    line-height: 14px;
    font-size: 10px;
    margin-bottom: 2px;
  }
}
.autograde_finished__stats_data {
  display: flex;
  justify-content: center;
  font-size: 84px;
  text-transform: uppercase;
}
.autograde_finished__stats_data > div {
  width: 150px;
  margin: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  line-height: 100px;
}
@media (max-width: 760px) {
  .autograde_finished__stats_data > div {
    line-height: 79px;
    font-size: 72px;
  }
}
.autograde_finished__module_dots {
  margin: 16px;
  text-align: center;
  font-size: 0;
}
.autograde_finished__module_dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid #fff;
  margin: 4px;
  display: inline-block;
  vertical-align: top;
}
.autograde_finished__module_dot--filled {
  background: #fff;
}
.autograde_finished__buttons_wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (max-width: 760px) {
  .autograde_finished__buttons_wrapper {
    background: #28282B;
    z-index: 999;
  }
}
.autograde_finished__buttons_wrapper button,
.autograde_finished__buttons_wrapper a {
  display: block;
  max-width: 320px;
  border: none;
  color: #fff;
  box-sizing: border-box;
  width: 100%;
  margin: 16px auto;
  cursor: pointer;
}
.autograde_finished__cancel_button {
  padding: 8px;
  text-decoration: none;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  height: 36px;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
}
.autograde_finished__accept_button {
  background: linear-gradient(180deg, #8CD100 0%, #66AF31 100%);
  border-radius: 12px;
  font-size: 18px;
  line-height: 22px;
  padding: 17px;
  height: 56px;
  text-decoration: none;
  font-weight: 600;
  box-shadow: 0px 8px 16px rgba(84, 120, 8, 0.15);
}
.autograde_finished__accept_button:hover {
  background: #599900;
}
@media (max-width: 760px) {
  .autograde_finished__modules_wrapper--open {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }
}
.autograde_finished__modules {
  background: #28282B;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.5);
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  max-width: 720px;
  border-radius: 16px;
  padding: 8px;
  margin: 36px auto;
  text-align: left;
}
@media (max-width: 760px) {
  .autograde_finished__modules {
    border-radius: 16px 16px 0 0;
    max-width: none;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 294px;
    height: calc(100vh - 294px);
    height: calc((var(--vh, 1vh) * 100) - 294px);
    transition: top 0.3s, height 0.3s;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 0;
    overscroll-behavior: contain;
  }
  .autograde_finished__modules--open {
    height: calc(100vh - 66px);
    height: calc((var(--vh, 1vh) * 100) - 66px);
    top: 66px;
  }
}
.autograde_finished__modules_title {
  padding: 16px 8px;
  display: flex;
  justify-content: space-between;
}
.autograde_finished__modules_title_text {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
.autograde_finished__modules_close {
  width: 24px;
  height: 24px;
}
.autograde_finished__modules_open {
  display: none;
}
@media (max-width: 760px) {
  .autograde_finished__modules_open {
    display: block;
    position: absolute;
    top: 0;
    width: calc(100% - 16px);
    padding: 8px 0 4px 0;
    box-sizing: border-box;
    background: #28282B;
    z-index: 1;
  }
}
.autograde_finished__modules_open_rectangle {
  height: 4px;
  width: 48px;
  margin: 0 auto;
  background: #FFFFFF;
  opacity: 0.2;
  border-radius: 100px;
}
@media (max-width: 760px) {
  .autograde_finished__modules_list {
    height: calc(100% - 16px);
    overflow: hidden;
    position: relative;
    padding: 16px 0 148px 0;
    box-sizing: border-box;
  }
}
.autograde_finished__module {
  min-height: 56px;
  padding: 17px 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.autograde_finished__module .progress {
  margin: -10px 0 -10px 10px;
}
.autograde_finished__module + .autograde_finished__module {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 760px) {
  .autograde_finished {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;
    overflow: hidden;
    padding-top: 72px;
  }
  .autograde_finished__modules--open .autograde_finished__modules_list {
    overflow: auto;
  }
}
