.teacher-materials-page {
  min-height: 100vh;
  min-width: 100%;
  background-color: #F7F7F7;
}
.teacher-materials-page__content {
  max-width: 1136px;
  margin: 0 auto;
  padding: 8px 40px;
  box-sizing: border-box;
}
.teacher-materials-page__breadcrumbs {
  margin: 40px 0 16px;
  padding-top: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.teacher-materials-page__breadcrumbs-back {
  display: flex;
  text-decoration: none;
  color: #999999;
  cursor: pointer;
  width: fit-content;
}
.teacher-materials-page__breadcrumbs-back:visited {
  color: #999999;
}
.teacher-materials-page__breadcrumbs-back-icon {
  margin-left: 10px;
  margin-right: 18px;
}
.teacher-materials-page__breadcrumbs-delim {
  color: #CCCCCC;
}
.teacher-materials-page__breadcrumbs-link {
  text-decoration: none;
  color: #999999;
}
.teacher-materials-page__breadcrumbs-link:visited {
  color: #999999;
}
.teacher-materials-page__breadcrumbs-link-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.teacher-materials-page__title {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #1A1A1A;
  padding: 40px 0 0;
  margin: 0 0 16px;
}
@media (max-width: 760px) {
  .teacher-materials-page__title {
    font-size: 32px;
    line-height: 40px;
    padding: 16px 0 0;
  }
}
.teacher-materials-page__section {
  margin-bottom: 16px;
}
.teacher-materials-page__section-menu {
  margin-bottom: 16px;
}
.theme-light .teacher-materials-page__section-menu .ui-button-mode-text {
  border-width: 2px;
  border-radius: 8px;
  border-color: #DFDFDF;
  border-style: solid;
}
.theme-light .teacher-materials-page__section-menu .ui-button-mode-text.ui-button.ui-button-active:not([disabled]) {
  background-color: #999999;
  border-color: #999999;
}
.theme-light .teacher-materials-page__section-menu .ui-button-mode-text.ui-button.ui-button-active:not([disabled]) .ui-icon {
  color: #FFFFFF;
}
.teacher-materials-page__marked {
  font-size: 24px;
  line-height: 32px;
  color: #1A1A1A;
}
@media (max-width: 760px) {
  .teacher-materials-page__marked {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 480px) {
  .teacher-materials-page__marked {
    font-size: 12px;
    line-height: 16px;
  }
}
