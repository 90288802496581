.MuiIcon-root.icon_atom,
.MuiSvgIcon-root.icon_atom,
.icon_atom.ui-icon {
  color: #929292;
}
.MuiIcon-root.icon_atom svg path,
.MuiSvgIcon-root.icon_atom svg path,
.icon_atom.ui-icon svg path {
  transition: fill 150ms;
  fill: #929292;
}
.MuiIcon-root.icon_atom--disabled,
.MuiSvgIcon-root.icon_atom--disabled,
.icon_atom.ui-icon--disabled {
  color: #E0E0E0;
}
.MuiIcon-root.icon_atom--disabled svg path,
.MuiSvgIcon-root.icon_atom--disabled svg path,
.icon_atom.ui-icon--disabled svg path {
  fill: #E0E0E0;
}
.MuiIcon-root.icon_atom--has-hover:hover,
.MuiSvgIcon-root.icon_atom--has-hover:hover,
.icon_atom.ui-icon--has-hover:hover {
  color: #717171;
}
.MuiIcon-root.icon_atom--has-hover:hover svg path,
.MuiSvgIcon-root.icon_atom--has-hover:hover svg path,
.icon_atom.ui-icon--has-hover:hover svg path {
  fill: #717171;
}
.MuiIcon-root.icon_atom--dark-mode,
.MuiSvgIcon-root.icon_atom--dark-mode,
.icon_atom.ui-icon--dark-mode {
  color: #FFFFFF;
}
.MuiIcon-root.icon_atom--dark-mode svg path,
.MuiSvgIcon-root.icon_atom--dark-mode svg path,
.icon_atom.ui-icon--dark-mode svg path {
  fill: #FFFFFF;
}
.MuiIcon-root.icon_atom--has-errors,
.MuiSvgIcon-root.icon_atom--has-errors,
.icon_atom.ui-icon--has-errors {
  color: #FF5757;
}
.MuiIcon-root.icon_atom--has-errors svg path,
.MuiSvgIcon-root.icon_atom--has-errors svg path,
.icon_atom.ui-icon--has-errors svg path {
  fill: #FF5757;
}
.MuiIcon-root.icon_atom--active,
.MuiSvgIcon-root.icon_atom--active,
.icon_atom.ui-icon--active {
  color: #909090;
}
.MuiIcon-root.icon_atom--active svg path,
.MuiSvgIcon-root.icon_atom--active svg path,
.icon_atom.ui-icon--active svg path {
  fill: #909090;
}
.MuiIcon-root.icon_atom--size-large,
.MuiSvgIcon-root.icon_atom--size-large,
.icon_atom.ui-icon--size-large {
  font-size: 24px;
}
.MuiIcon-root.icon_atom--size-medium,
.MuiSvgIcon-root.icon_atom--size-medium,
.icon_atom.ui-icon--size-medium {
  font-size: 18px;
}
.MuiIcon-root.icon_atom--size-small,
.MuiSvgIcon-root.icon_atom--size-small,
.icon_atom.ui-icon--size-small {
  font-size: 15px;
}
