.required_modules_list {
  overflow-y: auto;
  box-shadow: inset 0 16px 32px -8px rgba(0, 0, 0, 0.5);
  background: #28282B;
  margin: 0 -8px;
}
@media (max-width: 760px) {
  .required_modules_list {
    margin: 0;
  }
}
