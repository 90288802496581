.inline_answers--block {
  display: block;
}
.inline_answers--inline {
  display: inline-block;
  vertical-align: baseline;
  line-height: 40px;
  /*max-height:       40px;*/
}
@media (max-width: 768px) {
  .inline_answers--inline {
    max-width: 84vw;
  }
}
@media (max-width: 490px) {
  .inline_answers--inline {
    max-width: 80vw;
  }
}
