.multiply_answers {
  margin-left: -22px;
}
.multiply_answers .task__fraction_view {
  position: absolute;
  z-index: 1;
  top: -12px;
  left: -8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  padding: 84px 15px 15px 15px;
  background: #FFFFFF;
  width: calc(100% + 16px);
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  color: #8C8695;
  text-wrap: auto;
  min-width: fit-content;
}
.multiply_answers .task__fraction_view--error {
  color: #FF5E73;
}
.multiply_answers .task__fraction_view p {
  margin: 0;
}
.multiply_answers__input_wrapper {
  width: 222px;
  padding: 0;
  box-sizing: border-box;
  display: inline-block;
  margin: 20px 0 20px 22px;
  position: relative;
}
.multiply_answers__input_wrapper--singleton {
  width: calc(100% - 22px);
}
.multiply_answers__input_wrapper--double {
  width: calc(50% - 22px);
}
.multiply_answers__input_wrapper--triple {
  width: calc(33.3% - 22px);
}
.multiply_answers__input_wrapper--inline {
  margin: 0 20px 0 22px;
}
.multiply_answers__input_wrapper--inline .task__fraction_view {
  padding: 60px 12px 15px 12px;
}
.multiply_answers input {
  width: 100%;
  z-index: 0;
}
.multiply_answers input:focus {
  z-index: 100;
}
.multiply_answers--fraction-allowed + .multiply_answers__icon--remove {
  visibility: visible;
}
.multiply_answers--error input {
  color: #F94C4F;
}
.element-client-validation-label-has-level-warning > .multiply_answers > .multiply_answers__input_wrapper > .multiply_answers__input:hover,
.multiply_answers > .multiply_answers__input_wrapper > .multiply_answers__input:hover {
  background: #EAE0FF;
  box-shadow: inset 0px 0px 0px 1px #7B29C9;
}
.element-client-validation-label-has-level-warning > .multiply_answers > .multiply_answers__input_wrapper > .multiply_answers__input:focus,
.multiply_answers > .multiply_answers__input_wrapper > .multiply_answers__input:focus {
  box-shadow: inset 0px 0px 0px 1px #7B29C9;
}
.element-client-validation-label-has-level-warning > .multiply_answers > .multiply_answers__input_wrapper > .multiply_answers__input--error,
.multiply_answers > .multiply_answers__input_wrapper > .multiply_answers__input--error,
.element-client-validation-label-has-level-warning > .multiply_answers > .multiply_answers__input_wrapper > .multiply_answers__input--error:hover,
.multiply_answers > .multiply_answers__input_wrapper > .multiply_answers__input--error:hover,
.element-client-validation-label-has-level-warning > .multiply_answers > .multiply_answers__input_wrapper > .multiply_answers__input--error:focus,
.multiply_answers > .multiply_answers__input_wrapper > .multiply_answers__input--error:focus {
  box-shadow: inset 0px 0px 0px 1px #F94C4F;
}
.element-client-validation-label-has-level-warning > .multiply_answers > .multiply_answers__input_wrapper > .multiply_answers__input--warning,
.multiply_answers > .multiply_answers__input_wrapper > .multiply_answers__input--warning,
.element-client-validation-label-has-level-warning > .multiply_answers > .multiply_answers__input_wrapper > .multiply_answers__input--warning:hover,
.multiply_answers > .multiply_answers__input_wrapper > .multiply_answers__input--warning:hover,
.element-client-validation-label-has-level-warning > .multiply_answers > .multiply_answers__input_wrapper > .multiply_answers__input--warning:focus,
.multiply_answers > .multiply_answers__input_wrapper > .multiply_answers__input--warning:focus {
  box-shadow: inset 0px 0px 0px 1px #FD9601;
}
.multiply_answers__input {
  position: relative;
  width: 150px;
  background: #F1EDFA;
  border: none;
  box-shadow: inset 0px 0px 0px 1px #D5DDE6;
  box-sizing: border-box;
  border-radius: 8px;
  height: 56px;
  line-height: 22px;
  font-size: 18px;
  padding: 0 16px;
  outline: none;
  font-family: inherit;
  -moz-appearance: textfield;
}
.multiply_answers__input:read-only {
  cursor: default;
  pointer-events: none;
}
.multiply_answers__input--result_right {
  background: #E1F5E4;
  color: #5BC36C;
}
.multiply_answers__input--result_wrong {
  background: #FFF1F2;
  color: #FF5E73;
}
.multiply_answers__input::-webkit-inner-spin-button,
.multiply_answers__input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.multiply_answers__input:focus {
  background-color: #FFFFFF;
  z-index: 102;
}
.multiply_answers__input:focus ~ .multiply_answers__icon--remove {
  visibility: hidden !important;
}
.multiply_answers__input:focus + .multiply_answers__icon.multiply_answers__icon--question {
  visibility: visible;
  z-index: 102;
}
.multiply_answers__input--inline {
  height: 32px;
  font-size: 16px;
  padding: 0 8px;
}
.multiply_answers__input--inline::placeholder {
  font-size: 16px;
}
.multiply_answers:hover .multiply_answers__icon--remove {
  visibility: visible;
}
.multiply_answers__icon {
  display: inline-block;
  margin: 0 14px;
  width: 20px;
  height: 23px;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
.multiply_answers__icon:hover + .multiply_answers__baloon {
  visibility: visible;
  opacity: 1;
}
.multiply_answers__icon:hover .multiply_answers__baloon {
  visibility: visible;
  opacity: 1;
}
.multiply_answers__icon svg {
  width: inherit;
  height: inherit;
}
.multiply_answers__icon--question {
  position: absolute;
  right: 0;
  top: 17px;
  visibility: hidden;
}
.multiply_answers__icon--question use,
.multiply_answers__icon--question path {
  fill: #CAD4E0;
}
.multiply_answers__icon--question.multiply_answers__icon--inline {
  top: 6px;
}
.multiply_answers__icon--add {
  z-index: 0;
  background: transparent;
  width: 100%;
  height: 57px;
  border: 1px solid #E1DFE6;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 0;
  position: relative;
  top: -3px;
  outline: none !important;
  transition: border 0.15s;
}
.multiply_answers__icon--add .multiply_answers__baloon {
  width: 110px;
  right: 0;
  left: initial;
}
.multiply_answers__icon--add use,
.multiply_answers__icon--add path {
  fill: #CAD4E0;
  transition: fill 0.15s;
}
.multiply_answers__icon--add svg {
  width: 26px;
}
.multiply_answers__icon--add:hover:not([disabled]) {
  border: 1px solid #7B29C9;
}
.multiply_answers__icon--add:hover:not([disabled]) use,
.multiply_answers__icon--add:hover:not([disabled]) path {
  fill: #8C8695;
}
.multiply_answers__icon--add[disabled] {
  cursor: auto;
}
.multiply_answers__icon--add[disabled] use,
.multiply_answers__icon--add[disabled] path {
  fill: rgba(202, 212, 224, 0.59);
}
.multiply_answers__icon--remove {
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 17px;
}
.multiply_answers__icon--remove .multiply_answers__baloon {
  width: 110px;
  right: -15px;
  top: -77px;
  bottom: auto;
}
.multiply_answers__icon--remove:hover use,
.multiply_answers__icon--remove:hover path {
  fill: rgba(0, 0, 0, 0.74);
}
.multiply_answers__icon--remove use,
.multiply_answers__icon--remove path {
  fill: #CAD4E0;
}
.multiply_answers__icon--remove svg {
  transform: rotate(45deg);
}
.multiply_answers__baloon {
  background: #FFFFFF;
  border: 1px solid #DDE4ED;
  box-shadow: 0 4px 8px rgba(202, 212, 224, 0.5);
  border-radius: 8px;
  position: absolute;
  padding: 10px;
  bottom: 100%;
  width: 226px;
  left: -15px;
  pointer-events: none;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 18px;
  color: #69798C;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 3;
  text-wrap: auto;
}
.multiply_answers__baloon:after {
  display: block;
  content: ' ';
  background: url(img/icons.svg?#balloon_corner);
  width: 28px;
  height: 13px;
  position: absolute;
  bottom: -12px;
  box-shadow: inset 0 1px 0 0 #FFFFFF;
}
.multiply_answers__baloon--right {
  background: #FFFFFF;
  right: 0;
  left: auto;
  max-width: 200px;
}
.multiply_answers__baloon--right:after {
  right: 6px;
  left: auto;
}
@media (max-width: 760px) {
  .multiply_answers {
    margin: 0 !important;
  }
  .multiply_answers__input_wrapper,
  .multiply_answers__icon--add {
    width: 100% !important;
  }
  .multiply_answers__input ~ .multiply_answers__icon--remove {
    visibility: visible;
  }
  .multiply_answers__input_wrapper {
    margin-right: 0;
    margin-left: 0;
  }
}
