.course_training_card {
  background: #3A3A40;
  margin-top: 8px;
  border-radius: 16px;
  width: 355px;
  height: 420px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding: 16px;
  box-sizing: border-box;
  text-decoration: none;
}
.course_training_card__title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #fff;
  height: 65px;
}
.course_training_card__content_text {
  font-size: 18px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.5);
  height: 68px;
  display: flex;
  align-items: flex-end;
  margin-top: 8px;
}
.course_training_card iframe {
  width: 328px;
  height: 185px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
.course_training_card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.course_training_card__body {
  height: 200px;
}
.course_training_card__footer {
  margin-top: 10px;
}
.course_training_card__slide_links {
  padding: 12px 0;
}
.course_training_card__slide_link {
  width: 12px;
  height: 12px;
  background: rgba(255, 255, 255, 0.2);
  margin-right: 20px;
  display: inline-block;
  vertical-align: text-bottom;
  border-radius: 50%;
  cursor: pointer;
}
.course_training_card__slide_link:last-child {
  margin-right: 0;
}
.course_training_card__slide_link--active {
  background: #fff;
}
.course_training_card__button {
  float: right;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  border-radius: 8px;
  height: 36px;
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  margin-left: 8px;
  background: #88CC00;
  color: #fff;
  font-weight: 600;
  border: none;
  font-family: inherit;
}
.course_training_card__button:disabled {
  background: rgba(255, 255, 255, 0.5);
  cursor: default;
}
.course_training_card__button:visited {
  color: #fff;
}
@media (max-width: 360px) {
  .course_training_card iframe {
    width: 288px;
    height: 160px;
  }
}
