.icon_control {
  padding: 8px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  color: #929292;
  outline: none;
  justify-content: center;
  align-items: center;
}
.icon_control.control_wrapper--small {
  padding: 4.5px;
  width: 24px;
  height: 24px;
}
.icon_control.active {
  background: rgba(51, 51, 51, 0.05);
  color: #909090;
}
.icon_control.error {
  color: #FF5757 !important;
}
.icon_control.disabled {
  background: none;
  color: #e0e0e0;
}
.icon_control.highlighted {
  background: #255ACC;
  color: #FFFFFF;
}
.icon_control.colorPrimary {
  background: #333333;
  color: #FFFFFF;
}
.icon_control.colorPrimary.active {
  background: #656565;
}
.icon_control.colorPrimary.error {
  color: #FF5757 !important;
}
.icon_control.colorPrimary.disabled {
  background: #D3D3D3;
}
.icon_control.dark-mode {
  color: #FFFFFF;
}
.icon_control.dark-mode.active {
  background: rgba(255, 255, 255, 0.2);
}
.icon_control.dark-mode.error {
  color: #FF5757;
}
.icon_control.dark-mode.disabled {
  background: none;
  color: rgba(255, 255, 255, 0.25);
}
.icon_control.dark-mode.colorPrimary {
  background: #FFFFFF;
  color: #1e1e1e;
}
.icon_control.dark-mode.colorPrimary.active {
  background: #BFBFBF;
}
.icon_control.dark-mode.colorPrimary.error {
  color: #FF5757;
}
.icon_control.dark-mode.colorPrimary.disabled {
  background: #585858;
}
.icon_control .MuiIcon-root,
.icon_control .icon_atom.ui-icon {
  color: inherit;
}
.icon_control.round {
  border-radius: 50%;
}
.icon_control.outlined {
  border: 1px solid #DCDCDC;
  box-sizing: border-box;
}
