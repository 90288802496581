/* Generated with js2less.js.
Original file: src/styles/values/grayscale.json */
.analogic-task {
  color: #1A1A1A;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
}
.analogic-task__close-button {
  margin-bottom: 24px;
  margin-right: 16px;
  cursor: pointer;
}
.analogic-task__content {
  background: #F7F7F7;
  border-radius: 12px;
  padding: 0 16px 40px;
}
.analogic-task__title,
.analogic-task__subtitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #1A1A1A;
  margin: 40px 0;
}
.analogic-task__subtitle {
  margin-bottom: 24px;
}
