.autograde_header {
  height: 56px;
  font-size: 16px;
  line-height: 22px;
  font-family: "PT Root UI", Arial, sans-serif;
}
.autograde_header__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  height: 56px;
  text-align: center;
  padding: 16px 0;
  box-sizing: border-box;
  background: #fff;
}
.autograde_header__back_arrow {
  background: url("~CheopsRoot/img/couse-back-arrow.svg") no-repeat;
  filter: invert(49.5%);
  width: 16px;
  height: 16px;
  position: absolute;
  left: 20px;
  top: 20px;
  box-sizing: content-box;
  cursor: pointer;
}
.autograde_header__pause {
  position: absolute;
  right: 20px;
  top: 16px;
  cursor: pointer;
}
.autograde_header--transparent .autograde_header__wrapper {
  background: none;
  color: #fff;
}
.autograde_header--transparent .autograde_header__back_arrow {
  filter: none;
}
.autograde_header--dark .autograde_header__wrapper {
  color: #fff;
  background: #6d5fef;
}
.autograde_header--dark .autograde_header__back_arrow {
  filter: none;
}
.autograde_header--darkest .autograde_header__wrapper {
  color: #fff;
  background: #4C42A6;
}
.autograde_header--darkest .autograde_header__back_arrow {
  filter: none;
}
