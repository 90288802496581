.text_input_control {
  justify-content: space-between;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
}
.text_input_control--full-width {
  width: 100%;
  display: inline;
}
.text_input_control .MuiOutlinedInput-root {
  border: 1px solid #E4E4E4;
}
.text_input_control--borderless .MuiOutlinedInput-root {
  border: 0px;
}
.text_input_control__title {
  display: inline-flex;
}
.text_input_control__input {
  width: 100%;
  height: 40px;
  margin: auto;
  padding-right: 4px;
}
.text_input_control__input .MuiInputBase-formControl {
  height: 100%;
}
.text_input_control__input .MuiInputBase-input {
  font-size: 14px;
  line-height: 24px;
}
.text_input_control__input .MuiOutlinedInput-input {
  padding: 8px 16px;
}
.text_input_control__input .MuiInputBase-root fieldset {
  border-width: 0;
}
@media (max-width: 760px) {
  .text_input_control__input .MuiInputBase-root input {
    padding: 0 16px;
  }
}
@media (max-width: 760px) {
  .text_input_control__input .MuiInputBase-root input::placeholder {
    color: inherit;
    opacity: 1;
    font-size: 14px;
  }
}
@media (max-width: 760px) {
  .text_input_control__input .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
}
.text_input_control__input .Mui-focused {
  border-color: transparent;
}
.text_input_control__input .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 2px solid #2F82FF !important;
}
.text_input_control__input .Mui-focused.MuiOutlinedInput-multiline .MuiOutlinedInput-notchedOutline {
  bottom: -3px;
  left: -3px;
  right: -2px;
  top: -6px;
}
.text_input_control__input--multiline {
  height: auto;
  width: 336px;
}
@media (max-width: 760px) {
  .text_input_control__input--multiline {
    width: 100%;
  }
}
.text_input_control__input--multiline .MuiOutlinedInput-multiline {
  padding: 8px 16px;
}
.text_input_control__input--multiline .MuiOutlinedInput-input {
  padding: 0;
}
.text_input_control__input--full-width {
  width: 100%;
}
.text_input_control__cancel {
  position: absolute;
  right: 8px;
  top: calc(50% - 12px);
  margin: 0;
  width: 24px;
  height: 24px;
}
.text_input_control__cancel .MuiButton-root {
  padding: 0;
  min-width: 24px;
}
.text_input_control__cancel .MuiButton-root .icon_control,
.text_input_control__cancel .MuiButton-root .control_wrapper {
  margin: 0;
}
.text_input_control__search {
  position: absolute;
  right: 0;
}
.text_input_control__confirm_edit_btns {
  display: none;
}
@media (max-width: 760px) {
  .text_input_control__confirm_edit_btns {
    display: flex;
    padding: 22px 0;
  }
}
@media (max-width: 760px) {
  .text_input_control--focused {
    width: 100% !important;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 12px;
    margin: 0;
    justify-content: flex-start;
    z-index: 510;
  }
}
.text_input_control.mobile_filter {
  width: 100%;
}
.text_input_control.mobile_filter .text_input_control__input_container .text_input_control__cancel {
  display: none;
}
.text_input_control__input_container .text_input_control__cancel .MuiButtonBase-root .MuiIcon-root,
.text_input_control__input_container .text_input_control__cancel .MuiButtonBase-root .icon_atom.ui-icon {
  color: #929292 !important;
}
.text_input_control.mobile_filter .text_input_control__input_container .text_input_control__input .MuiInputBase-root {
  width: 100%;
}
.text_input_control.mobile_filter .text_input_control__input_container .text_input_control__input .MuiInputBase-root input {
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.05);
  padding: 0 16px;
  border-radius: 4px;
}
.text_input_control--focused .text_input_control__input_container .text_input_control__cancel {
  display: block !important;
}
.text_input_control--focused .text_input_control__input .MuiInputBase-root fieldset:focus {
  border-width: 2px;
}
@media (max-width: 760px) {
  .text_input_control--focused .text_input_control__input .MuiInputBase-root input {
    padding: 8px 0 8px 16px !important;
    background-color: initial !important;
    color: initial;
    text-align: initial;
  }
}
@media (max-width: 760px) {
  .text_input_control--focused .text_input_control__input .MuiInputBase-input::placeholder {
    opacity: 0.42;
  }
}
.text_input_control__input_container {
  display: flex;
  position: relative;
  width: 100%;
}
.text_input_control--edited .text_input_control__input .MuiInputBase-root fieldset {
  border-width: 0;
}
.text_input_control--hasError .text_input_control__input .MuiInputBase-root {
  background-color: rgba(255, 47, 47, 0.1);
}
.text_input_control--hasError .text_input_control__input .MuiInputBase-root input {
  color: #FF5757;
}
.text_input_control--hasError .text_input_control__input .MuiInputBase-root input::placeholder {
  color: #FF5757;
}
.text_input_control--hasError .text_input_control__input .MuiInputBase-root textarea {
  color: #FF5757;
}
.text_input_control--hasError .text_input_control__input .MuiInputBase-root textarea::placeholder {
  color: #FF5757;
}
.text_input_control__mobile_header {
  display: none;
}
.text_input_control--focused:hover {
  background-color: #FFFFFF !important;
}
.text_input_control:hover:not(.restrictHoverEffects) {
  background-color: rgba(51, 51, 51, 0.06);
}
.text_input_control:hover:not(.restrictHoverEffects) {
  background-color: rgba(51, 51, 51, 0.06);
}
@media (max-width: 760px) {
  .text_input_control:hover:not(.restrictHoverEffects) {
    background-color: #FFFFFF;
  }
}
.text_input_control--fullWidth {
  width: 100%;
}
.text_input_control--fullWidth .text_input_control__input_container {
  width: 100%;
}
.text_input_control--fullWidth .text_input_control--multiline {
  width: 100%;
}
.text_input_control--bordered .text_input_control__input {
  border-radius: 4px;
  border: 1px solid #E4E4E4;
}
.text_input_control--dark_mode {
  background-color: #333333;
  color: #FFFFFF;
}
.text_input_control--dark_mode input {
  color: #FFFFFF;
}
.text_input_control--dark_mode input::placeholder {
  color: #9F9F9F;
  opacity: 1;
}
.text_input_control--dark_mode .text_input_control__input_container {
  background-color: #333333;
  color: #FFFFFF;
}
@media (max-width: 760px) {
  .text_input_control--dark_mode {
    background-color: initial;
    color: initial;
  }
  .text_input_control--dark_mode input {
    color: initial;
  }
  .text_input_control--dark_mode .text_input_control__input_container {
    background-color: initial;
    color: initial;
  }
  .text_input_control--focused.text_input_control--dark_mode {
    color: initial;
  }
  .text_input_control:hover {
    background-color: #FFFFFF;
  }
  .text_input_control--edited .text_input_control__input .MuiInputBase-root fieldset {
    border-width: 1px;
  }
  .text_input_control__mobile_header {
    display: flex;
    padding-bottom: 8px;
    align-self: flex-start;
  }
  .text_input_control__mobile_header button {
    background-color: unset !important;
  }
  .text_input_control__input_container {
    width: 100%;
  }
  .text_input_control__cancel button {
    background-color: unset !important;
  }
  .text_input_control--focused .text_input_control__input_container {
    border: 1px solid #E4E4E4;
    border-radius: 4px;
  }
}
.text_input_control--focused.restrictFocusEffects .text_input_control__input .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-width: 0!important;
}
.text_input_control input[type=number]::-webkit-inner-spin-button,
.text_input_control input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
