.input-client-validation {
  position: absolute;
  z-index: 99;
  top: -8px;
  left: -8px;
  border-radius: 16px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  padding: 68px 8px 8px 8px;
  background-color: #FFFFFF;
  width: calc(100% - 16px);
  display: none;
  font-weight: 400;
  min-width: fit-content;
}
.theme-light .input-client-validation-level-error .ui-icon,
.input-client-validation-level-error {
  color: #DF222E;
}
.theme-light .input-client-validation-level-warning .ui-icon,
.input-client-validation-level-warning {
  color: #E76A01;
}
.multiply_answers__input:not(.multiply_answers__input--inline) ~ .input-client-validation {
  padding-top: 68px;
}
.multiply_answers__input.multiply_answers__input--inline ~ .input-client-validation {
  padding-top: 48px;
}
input:focus ~ .input-client-validation-show {
  display: block;
}
.input-client-validation__layout {
  align-items: center;
  text-wrap: auto;
}
