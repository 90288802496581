.course-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 100;
  width: 376px;
  padding: 8px;
}
.course-sidebar-expanded {
  background: #28282B;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  bottom: 0;
  max-width: 376px;
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  overflow-x: visible;
  overflow-y: auto;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
  scrollbar-width: thin;
}
.course-sidebar-expanded::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.course-sidebar-expanded::-webkit-scrollbar-track {
  background: transparent;
}
.course-sidebar-expanded::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}
.course-sidebar-expanded::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.4);
}
.course-sidebar .prof_cheops_message {
  position: relative;
  border-radius: 16px 2px 16px 16px;
  width: auto;
  top: auto;
  right: auto;
  margin-top: 8px;
}
@media (min-width: 761px) {
  .course-sidebar .notifications_list {
    top: 8px;
    bottom: 8px;
    position: absolute;
    left: 8px;
    right: 8px;
    z-index: 10;
    height: auto;
  }
}
@media (max-width: 760px) {
  .course-sidebar {
    left: auto;
    right: 0;
    padding: 10px;
    width: 56px;
    pointer-events: none;
  }
  .course-sidebar > .course_progress_form,
  .course-sidebar > .notifications_list,
  .course-sidebar > .autograde_ongoing_card,
  .course-sidebar > .module_action_card,
  .course-sidebar > .prof_cheops_message,
  .course-sidebar > .module_card,
  .course-sidebar > .course_training_card,
  .course-sidebar > .course-sidebar-header,
  .course-sidebar > .course-toc__sidebar {
    pointer-events: initial;
  }
  .course-sidebar > *:not(.course-sidebar-header):not(.course-toc__sidebar):not(.notifications_list):not(.course-sidebar-header__toc-button):not(.course-sidebar-header__menu-button) {
    position: fixed;
    left: 0;
    top: auto;
    bottom: 0;
    width: 100%;
    max-width: none;
    max-height: 80vh;
    margin-bottom: 0;
    overflow: auto;
  }
  .course-sidebar > *:not(.course-sidebar-header):not(.course-toc__sidebar):not(.notifications_list):not(.course-sidebar-header__toc-button):not(.course-sidebar-header__menu-button):nth-child(n+4):not(.course_progress_form) {
    display: none;
  }
  .course-sidebar-expanded {
    background: none;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: none;
    width: auto;
    padding: 0;
  }
}
