/* Generated with js2less.js.
Original file: src/styles/values/grayscale.json */
/* Generated with js2less.js.
Original file: src/styles/values/breakpoints.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-action.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-wrong.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-good.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-attention.json */
.teacher-room-page {
  min-height: 100vh;
  min-width: 100%;
  background-color: #F7F7F7;
}
.teacher-room-page__content {
  max-width: 1136px;
  margin: 0 auto;
  padding: 8px;
  box-sizing: border-box;
}
.teacher-room-page__section {
  margin-bottom: 16px;
  min-height: 336px;
}
.teacher-room-page__title {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #1A1A1A;
  padding: 40px;
  margin: 0 0 16px 0;
}
@media (max-width: 760px) {
  .teacher-room-page__title {
    font-size: 32px;
    line-height: 40px;
    padding: 16px;
  }
}
.teacher-room-page__submenu {
  padding: 16px 40px;
}
.teacher-room-page__submenu-button {
  font-size: 18px;
  line-height: 28px;
}
@media (max-width: 760px) {
  .teacher-room-page__submenu {
    padding: 8px;
  }
}
.teacher-room-page__modal-body.ui-modal__body {
  padding: 8px 16px;
}
.teacher-room-page__modal-text {
  margin: 8px 0;
}
.teacher-room-page__modal-footer {
  justify-content: end;
}
.teacher-room-page__modal-textarea > .ui-textarea__textarea.text-s {
  box-sizing: border-box;
  min-height: 146px;
}
.teacher-room-page__modal-dropdown.dropdown-select {
  width: 328px;
  margin: 8px 0;
}
