.programming-answer-examples {
  margin-bottom: 32px;
}
.programming-answer-examples__title {
  font-weight: bold;
  margin-top: 24px;
  font-size: 14px;
}
.programming-answer-examples__header {
  display: flex;
  color: #36363E;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
}
.programming-answer-examples__header-title {
  width: 50%;
  padding: 29px 24px 13px;
}
.programming-answer-examples__row {
  display: flex;
  background: #F5F5F5;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 24px;
  word-break: break-word;
}
.programming-answer-examples__row > .programming-answer-examples__row-part {
  width: 50%;
  padding: 18px 24px;
  font-family: 'Source Code Pro', monospace;
}
.programming-answer-examples__row > .programming-answer-examples__row-part + .programming-answer-examples__row-part {
  border-left: 1px solid #fff;
}
.programming-answer-examples-content {
  white-space: pre-wrap;
}
@media (max-width: 760px) {
  .programming-answer-examples__header {
    display: none;
  }
  .programming-answer-examples__row + .programming-answer-examples__row {
    margin-top: 16px;
  }
  .programming-answer-examples__row {
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 20px;
    border-radius: 0;
    margin: 0 -16px;
    padding: 0 9px;
  }
  .programming-answer-examples__row > .programming-answer-examples__row-part {
    width: 100%;
    padding: 10px 16px;
  }
  .programming-answer-examples__row > .programming-answer-examples__row-part + .programming-answer-examples__row-part {
    border-left: none;
    border-top: 1px solid #fff;
  }
  .programming-answer-examples__row-header {
    display: block;
  }
  .programming-answer-examples__row + .programming-answer-examples__row {
    margin-top: 16px;
  }
}
