.thread_message__header {
  margin-bottom: 20px;
}
.thread_message__name {
  color: #7B29C9;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
}
.thread_message__date {
  color: #8C8695;
  font-size: 16px;
  line-height: 20px;
}
.thread_message__avatar {
  background: #7B29C9;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  padding: 7px;
  border-radius: 50%;
  float: left;
  margin-right: 16px;
}
.thread_message__attachments {
  margin-top: 17px;
  margin: 0 -15px;
}
.thread_message + .thread_message {
  margin-top: 30px;
}
