.hint_popup,
.hints {
  text-align: center;
  color: #36363E;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
}
.hint_popup__close,
.hints__close {
  background: url("../../../img/close_cross_black.svg") 50% 50% no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
.hint_popup__wrapper,
.hints__wrapper {
  background: #F5F5F5;
  border-radius: 12px;
}
.hint_popup__text,
.hints__text {
  padding: 32px 16px;
}
.hint_popup__button,
.hints__button {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #7B29C9;
  vertical-align: middle;
  height: 64px;
  padding: 0 20px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.hints {
  margin-top: 64px;
  padding-top: 56px;
  position: relative;
}
.hints__item {
  border-bottom: 1px solid #E1DFE6;
  text-align: left;
  padding: 17px 16px 12px 16px;
}
.hints__item:last-child {
  border: none;
}
.hints__bottom {
  text-align: right;
}
.hints__request_btn {
  height: 64px;
  box-sizing: border-box;
  padding: 21px 32px;
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #7B29C9;
  cursor: pointer;
}
