.result_table {
  width: 100%;
  height: 100%;
  position: relative;
}
@media (max-width: 760px) {
  .result_table {
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    overflow: auto;
  }
}
.result_table table {
  border-collapse: collapse;
}
.result_table td {
  box-sizing: border-box;
}
.result_table td a {
  box-sizing: border-box;
}
.result_table__header {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: relative;
}
.result_table__header--student {
  background: #6C4DC4;
  color: #FFFFFF;
}
.result_table__header_upper {
  height: 56px;
  padding: 16px;
}
.result_table__header_upper_title {
  color: #000000;
  font-size: 18px;
  line-height: 16px;
}
.result_table__header_lower {
  position: relative;
  height: 56px;
  padding-left: 480px;
}
@media (max-width: 760px) {
  .result_table__header_lower {
    padding-left: 129px;
  }
}
.result_table__content {
  padding-left: 480px;
  position: relative;
  height: calc(100vh - 112px);
  overflow: auto;
}
@media (max-width: 760px) {
  .result_table__content {
    padding-left: 129px;
  }
}
.result_table__content--student {
  max-height: calc(100vh - 112px);
}
.result_table__table {
  text-align: left;
  font-size: 0;
  overflow: auto;
  background: #FFFFFF;
}
.result_table__table table {
  display: inline-block;
}
.result_table__table table tr {
  white-space: nowrap;
  background: #F4F5F7;
}
.result_table__table table td {
  display: inline-block;
}
.rating_table_page__content {
  padding-top: 56px;
}
.content-wrapper .rating_table_page__content {
  height: calc(100vh - 172px);
}
.result_table_sidebar {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background: #FFFFFF;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #36363E;
  box-shadow: 4px 3px 6px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  padding-bottom: 10px;
}
@media (max-width: 760px) {
  .result_table_sidebar {
    font-size: 14px;
    line-height: 24px;
  }
}
.result_table_sidebar tr {
  white-space: nowrap;
}
.result_table_sidebar td {
  display: inline-block;
  padding-top: 12px;
}
.result_table_sidebar__rating {
  width: 70px;
  height: 48px;
  color: #8C8695;
  text-align: center;
}
.result_table_sidebar__rating--hole {
  color: #8C8695;
}
@media (max-width: 760px) {
  .result_table_sidebar__rating {
    width: 0;
    font-size: 0;
    visibility: hidden;
    padding: 0;
    display: none;
  }
}
.result_table_sidebar__long_cell {
  width: calc(320px - 70px);
  height: 48px;
  text-align: left;
  padding-top: 6px;
  overflow: hidden;
}
.result_table_sidebar__long_cell--bold {
  font-weight: 600;
}
.result_table_sidebar__long_cell--hole {
  color: #8C8695;
}
@media (max-width: 760px) {
  .result_table_sidebar__long_cell {
    width: 82px;
    padding-left: 5px;
  }
}
.result_table_sidebar__total {
  height: 48px;
  width: 50px;
  font-weight: 600;
  text-align: center;
}
@media (max-width: 760px) {
  .result_table_sidebar__total {
    width: 46px;
    padding-right: 10px;
  }
}
.result_table_sidebar__stats {
  height: 48px;
  width: 50px;
  text-align: center;
}
@media (max-width: 760px) {
  .result_table_sidebar__stats {
    width: 0;
    visibility: hidden;
    padding: 0;
    font-size: 0;
  }
}
.result_table_sidebar td {
  background: #FFFFFF;
}
@media (max-width: 760px) {
  .result_table_sidebar__name {
    width: 0;
    font-size: 0;
    visibility: hidden;
  }
}
@media (min-width: 761px) {
  .result_table_sidebar__name_short {
    width: 0;
    font-size: 0px;
    visibility: hidden;
  }
}
.result_table_sidebar__row--current td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media (max-width: 760px) {
  .result_table_sidebar--open .result_table_sidebar__long_cell {
    width: 110px;
  }
  .result_table_sidebar--open .result_table_sidebar__total {
    padding-right: 0;
    width: 36px;
  }
  .result_table_sidebar--open .result_table_sidebar__stats {
    font-size: inherit;
    visibility: visible;
    width: 32px;
  }
  .result_table_sidebar--open .result_table_sidebar__stats:last-child {
    padding-right: 10px;
    width: 42px;
  }
}
.result_table_header_sidebar {
  position: absolute;
  left: 0;
  top: 0;
  padding-right: 10px;
}
@media (max-width: 760px) {
  .result_table_header_sidebar {
    font-size: 12px;
    line-height: 14px;
  }
}
.result_table_header_sidebar__rating {
  width: 70px;
  height: 56px;
  color: #8C8695;
  text-align: center;
}
@media (max-width: 760px) {
  .result_table_header_sidebar__rating {
    width: 0;
    font-size: 0;
    visibility: hidden;
    padding: 0;
  }
}
.result_table_header_sidebar__long_cell {
  width: calc(320px - 70px);
  height: 56px;
  padding-top: 6px;
  position: relative;
}
@media (max-width: 760px) {
  .result_table_header_sidebar__long_cell {
    width: 82px;
  }
}
.result_table_header_sidebar__total {
  height: 56px;
  width: 50px;
  font-weight: 600;
  text-align: center;
}
@media (max-width: 760px) {
  .result_table_header_sidebar__total {
    width: 36px;
  }
}
.result_table_header_sidebar__stats {
  height: 56px;
  width: 50px;
  text-align: center;
}
@media (max-width: 760px) {
  .result_table_header_sidebar__stats {
    width: 0;
    visibility: hidden;
    padding: 0;
    font-size: 0;
  }
}
.result_table_header_sidebar__stats_inner {
  display: inline-block;
  width: 40px;
  height: 36px;
  border-radius: 8px;
  line-height: 36px;
}
@media (max-width: 760px) {
  .result_table_header_sidebar__stats_inner {
    width: 0;
    visibility: hidden;
    padding: 0;
    font-size: 0;
  }
}
.result_table_header_sidebar td {
  background: #FFFFFF;
}
.result_table_header_sidebar--student {
  background: inherit;
}
.result_table_header_sidebar--student td {
  background: inherit;
  color: #FFFFFF;
}
.result_table_header_sidebar__close {
  visibility: hidden;
  width: 0;
}
@media (max-width: 760px) {
  .result_table_header_sidebar--open .result_table_header_sidebar__long_cell {
    width: 110px;
  }
  .result_table_header_sidebar--open .result_table_header_sidebar__stats {
    font-size: inherit;
    visibility: visible;
    width: 32px;
    font-weight: 600;
  }
  .result_table_header_sidebar--open .result_table_header_sidebar__stats_inner {
    font-size: inherit;
    line-height: inherit;
    font-weight: 600;
    padding-top: 8px;
    box-sizing: border-box;
    visibility: visible;
    width: 30px;
    height: 30px;
    border-radius: 6px;
  }
  .result_table_header_sidebar--open .result_table_header_sidebar__close {
    width: 14px;
    height: 14px;
    padding: 5px;
    visibility: visible;
    cursor: pointer;
    left: 16px;
    bottom: 16px;
    position: absolute;
  }
}
.result_table_header_table {
  width: 100%;
  height: 100%;
  text-align: left;
  font-size: 0;
  max-height: 56px;
  overflow: hidden;
}
.result_table_header_table table {
  display: inline-block;
  table-layout: fixed;
}
.result_table_header_table table tr {
  white-space: nowrap;
}
.result_table_header_table table td {
  display: inline-block;
  position: relative;
}
.result_table_header_table--student table td {
  background: inherit;
  color: #FFFFFF;
}
.result_table_header_table__task {
  width: 70px;
  height: 56px;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding-top: 16px;
}
@media (max-width: 760px) {
  .result_table_header_table__task {
    width: 60px;
    font-size: 14px;
    line-height: 24px;
  }
}
.result_table_cell {
  width: 70px;
  height: 48px;
  background: rgba(255, 255, 255, 0.95);
  color: #8C8695;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  border-top: 1px solid #0000000d;
  border-right: 1px solid #0000000d;
}
@media (max-width: 760px) {
  .result_table_cell {
    width: 60px;
    font-size: 14px;
    line-height: 24px;
  }
}
.result_table_cell a {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  padding-top: 12px;
  text-decoration: none;
  color: inherit;
}
.result_table_cell--open {
  background: #FFFFFF;
}
.result_table_cell--open:hover {
  border: 1px solid #7b7b7b;
}
.result_table_cell--bold {
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.result_table_cell--empty .result_table_cell__text {
  opacity: 0;
}
.result_table_cell--empty:hover .result_table_cell__text {
  opacity: 1;
}
.result_table_cell--full {
  background: #F1EDFA;
}
.result_table_cell--has_comments {
  color: #000000;
  background: #aee7ff;
}
.result_table_cell--has_color:hover .result_table_cell__text {
  color: #FFFFFF;
}
.result_table_cell img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
.result_table_cell__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.result_table_cell:hover .result_table_cell__bg {
  opacity: 1;
}
.result_table_cell__timer_icon {
  position: absolute;
  right: 2px;
  bottom: -12px;
}
.result_table_cell__timer_icon svg {
  width: 12px;
  color: grey;
}
.result_table__content--groups_13 {
  padding-left: 1070px;
}
@media (max-width: 760px) {
  .result_table__content--groups_13 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__content--groups_open_13 {
    padding-left: 606px;
  }
}
.result_table__header_lower--groups_13 {
  padding-left: 1070px;
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_13 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_open_13 {
    padding-left: 606px;
  }
}
.result_table__content--groups_12 {
  padding-left: 1020px;
}
@media (max-width: 760px) {
  .result_table__content--groups_12 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__content--groups_open_12 {
    padding-left: 574px;
  }
}
.result_table__header_lower--groups_12 {
  padding-left: 1020px;
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_12 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_open_12 {
    padding-left: 574px;
  }
}
.result_table__content--groups_11 {
  padding-left: 970px;
}
@media (max-width: 760px) {
  .result_table__content--groups_11 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__content--groups_open_11 {
    padding-left: 542px;
  }
}
.result_table__header_lower--groups_11 {
  padding-left: 970px;
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_11 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_open_11 {
    padding-left: 542px;
  }
}
.result_table__content--groups_10 {
  padding-left: 920px;
}
@media (max-width: 760px) {
  .result_table__content--groups_10 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__content--groups_open_10 {
    padding-left: 510px;
  }
}
.result_table__header_lower--groups_10 {
  padding-left: 920px;
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_10 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_open_10 {
    padding-left: 510px;
  }
}
.result_table__content--groups_9 {
  padding-left: 870px;
}
@media (max-width: 760px) {
  .result_table__content--groups_9 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__content--groups_open_9 {
    padding-left: 478px;
  }
}
.result_table__header_lower--groups_9 {
  padding-left: 870px;
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_9 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_open_9 {
    padding-left: 478px;
  }
}
.result_table__content--groups_8 {
  padding-left: 820px;
}
@media (max-width: 760px) {
  .result_table__content--groups_8 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__content--groups_open_8 {
    padding-left: 446px;
  }
}
.result_table__header_lower--groups_8 {
  padding-left: 820px;
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_8 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_open_8 {
    padding-left: 446px;
  }
}
.result_table__content--groups_7 {
  padding-left: 770px;
}
@media (max-width: 760px) {
  .result_table__content--groups_7 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__content--groups_open_7 {
    padding-left: 414px;
  }
}
.result_table__header_lower--groups_7 {
  padding-left: 770px;
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_7 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_open_7 {
    padding-left: 414px;
  }
}
.result_table__content--groups_6 {
  padding-left: 720px;
}
@media (max-width: 760px) {
  .result_table__content--groups_6 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__content--groups_open_6 {
    padding-left: 382px;
  }
}
.result_table__header_lower--groups_6 {
  padding-left: 720px;
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_6 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_open_6 {
    padding-left: 382px;
  }
}
.result_table__content--groups_5 {
  padding-left: 670px;
}
@media (max-width: 760px) {
  .result_table__content--groups_5 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__content--groups_open_5 {
    padding-left: 350px;
  }
}
.result_table__header_lower--groups_5 {
  padding-left: 670px;
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_5 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_open_5 {
    padding-left: 350px;
  }
}
.result_table__content--groups_4 {
  padding-left: 620px;
}
@media (max-width: 760px) {
  .result_table__content--groups_4 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__content--groups_open_4 {
    padding-left: 318px;
  }
}
.result_table__header_lower--groups_4 {
  padding-left: 620px;
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_4 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_open_4 {
    padding-left: 318px;
  }
}
.result_table__content--groups_3 {
  padding-left: 570px;
}
@media (max-width: 760px) {
  .result_table__content--groups_3 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__content--groups_open_3 {
    padding-left: 286px;
  }
}
.result_table__header_lower--groups_3 {
  padding-left: 570px;
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_3 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_open_3 {
    padding-left: 286px;
  }
}
.result_table__content--groups_2 {
  padding-left: 520px;
}
@media (max-width: 760px) {
  .result_table__content--groups_2 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__content--groups_open_2 {
    padding-left: 254px;
  }
}
.result_table__header_lower--groups_2 {
  padding-left: 520px;
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_2 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_open_2 {
    padding-left: 254px;
  }
}
.result_table__content--groups_1 {
  padding-left: 470px;
}
@media (max-width: 760px) {
  .result_table__content--groups_1 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__content--groups_open_1 {
    padding-left: 222px;
  }
}
.result_table__header_lower--groups_1 {
  padding-left: 470px;
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_1 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_open_1 {
    padding-left: 222px;
  }
}
.result_table__content--groups_0 {
  padding-left: 420px;
}
@media (max-width: 760px) {
  .result_table__content--groups_0 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__content--groups_open_0 {
    padding-left: 190px;
  }
}
.result_table__header_lower--groups_0 {
  padding-left: 420px;
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_0 {
    padding-left: 129px;
  }
}
@media (max-width: 760px) {
  .result_table__header_lower--groups_open_0 {
    padding-left: 190px;
  }
}
