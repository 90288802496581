/* Generated with js2less.js.
Original file: src/styles/values/grayscale.json */
/* Generated with js2less.js.
Original file: src/styles/values/breakpoints.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-action.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-wrong.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-good.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-attention.json */
@keyframes run {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
.teacher-class-page {
  min-height: 100vh;
  min-width: 100%;
  background-color: #F7F7F7;
}
.teacher-class-page__content {
  max-width: 1136px;
  margin: 0 auto;
}
.teacher-class-page__back-button {
  display: flex;
  text-decoration: none;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #999999;
  padding: 10px 50px;
  margin: 40px 0 16px 0;
  cursor: pointer;
  width: fit-content;
}
.teacher-class-page__back-button-icon {
  margin-right: 18px;
}
.teacher-class-page__back-button:visited {
  color: #999999;
}
.teacher-class-page__title {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #1A1A1A;
  padding: 40px;
  margin: 0 0 16px 0;
}
.teacher-class-page__subtitle {
  color: #1A1A1A;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  padding: 40px;
  margin: 0 0 16px 0;
}
.teacher-class-page__permissions {
  padding: 16px 40px;
  gap: 8px;
}
.teacher-class-page__permissions-section {
  padding: 80px 0;
}
.teacher-class-page__permissions-content {
  display: inline-flex;
  align-items: center;
}
.teacher-class-page__permissions-icon {
  margin-right: 8px;
}
.teacher-class-page__permissions-name {
  color: #1A1A1A;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
}
.teacher-class-page__permissions-button {
  max-width: fit-content;
  margin: 16px 40px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}
.teacher-class-page__submenu {
  padding: 16px 40px;
  margin: 0 0 16px 0;
  overflow-x: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.teacher-class-page__submenu-button {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #1A1A1A;
  margin-right: 8px;
}
.teacher-class-page__submenu-button:last-of-type {
  margin-right: 0;
}
.theme-light .teacher-class-page__submenu-button:disabled.ui-button.ui-button-mode-outlined {
  box-shadow: inset 0 0 0 2px #DFDFDF;
}
.teacher-class-page__table {
  padding: 0 40px;
}
.teacher-class-page__table .ui-table__tbody-cols-cell,
.teacher-class-page__table .ui-table__tbody-cell {
  padding: 4px;
}
.teacher-class-page__table .ui-table__tbody-cols-cell.ui-table__tbody-cols-cell-name-edit {
  width: 5%!important;
}
.teacher-class-page__modal-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 16px 0;
  color: #1A1A1A;
}
.teacher-class-page__modal-input {
  width: 100%;
}
.teacher-class-page__modal-input > .ui-input-wrapper {
  width: 100%;
  border: none;
  height: auto;
}
.teacher-class-page__modal-input > .ui-input-wrapper > .ui-textinput__input {
  width: 100%;
}
.teacher-class-page__modal-textarea > .ui-textarea__textarea.text-s {
  box-sizing: border-box;
  min-height: 120px;
}
.teacher-class-page__notification {
  padding: 16px;
  width: 295px;
  position: fixed;
  bottom: 16px;
  right: 16px;
  background-color: #F94C4F;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  animation: ease ease-in alternate;
  animation-name: run;
  animation-duration: 300ms;
  word-break: break-word;
  white-space: pre-line;
  cursor: pointer;
  z-index: 10;
}
@media (max-width: 760px) {
  .teacher-class-page__title {
    margin-bottom: 8px;
    padding: 16px 24px;
    font-size: 32px;
    line-height: 40px;
  }
  .teacher-class-page__submenu {
    padding: 8px 16px;
    margin-bottom: 8px;
  }
  .teacher-class-page__table {
    padding: 0 16px;
  }
}
