.landings-footer-cpm .landings-footer__links {
  background-color: #6C4DC4;
  color: #FFFFFF;
}
.landings-footer-cpm .landings-footer__links a {
  color: #FFFFFF;
}
.landings-footer-cpm .landings-footer__social {
  background-color: #6C4DC4;
}
.landings-footer-sirius .landings-footer__links {
  background-color: #6C4DC4;
  color: #FFFFFF;
}
.landings-footer-sirius .landings-footer__links a {
  color: #FFFFFF;
}
.landings-footer-sirius .landings-footer__social {
  background-color: #6C4DC4;
}
.landings-footer-univ .landings-footer__links {
  background-color: #0DB1AA;
  color: #FFFFFF;
}
.landings-footer-univ .landings-footer__links a {
  color: #FFFFFF;
}
.landings-footer-univ .landings-footer__social {
  background-color: #0DB1AA;
}
.landings-footer-corp .landings-footer__links {
  background-color: #90A0C4;
  color: #000000;
}
.landings-footer-corp .landings-footer__links a {
  color: #000000;
}
.landings-footer-corp .landings-footer__social {
  background-color: #90A0C4;
}
.landings-footer__social {
  min-height: 112px;
  padding-top: 44px;
  padding-bottom: 28px;
}
.landings-footer__social_gap {
  margin: 0 10px;
}
.landings-footer__links {
  min-height: 68px;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  letter-spacing: 0.02em;
}
@media (max-width: 592px) {
  .landings-footer__links {
    flex-direction: column;
    padding: 24px 16%;
    text-align: center;
  }
}
.landings-footer__links_gap {
  margin: 6px;
}
.landings-footer__bottom {
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #3F2688;
  color: #FFFFFF;
  min-height: 68px;
  line-height: 20px;
  font-size: 14px;
  letter-spacing: 0.02em;
}
@media (max-width: 760px) {
  .landings-footer__bottom-big {
    height: 160px;
    align-items: flex-start;
  }
  .landings-footer__bottom-text {
    margin-top: 18px;
  }
}
@media (max-width: 592px) {
  .landings-footer__bottom-text {
    width: 192px;
    margin-top: 24px;
    margin-bottom: 40px;
  }
}
.landings-footer__link {
  text-decoration: none;
}
