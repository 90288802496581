.course_progress_form {
  margin-top: 8px;
}
.course_progress_form__button {
  transition: all 100ms;
}
.course_progress_form__body {
  min-height: 200px;
  background: white;
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;
}
.course_progress_form__head_title {
  font-size: 24px;
  line-height: 24px;
  color: #36363E;
  margin: 0 0 13px 0;
  display: inline-block;
}
.course_progress_form__select_level {
  float: right;
  margin: 8px 0 0 0;
}
.course_progress_form__select_level_item {
  display: inline-block;
  width: 12px;
  height: 12px;
  padding-right: 15px;
}
.course_progress_form__select_level_item-selected:before,
.course_progress_form__select_level_item:hover:before {
  background: #8C8695 !important;
}
.course_progress_form__select_level_item:nth-last-child(1) {
  padding-right: 0;
}
.course_progress_form__select_level_item:before {
  content: '';
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background: #E1DFE6;
  cursor: pointer;
  display: block;
}
.course_progress_form__select_level__selected:before {
  background: #8C8695 !important;
}
.course_progress_form__statistic {
  width: 100%;
  color: #36363E;
  font-size: 16px;
  line-height: 22px;
}
.course_progress_form__tabs {
  margin-top: 25px;
}
.course_progress_form__tabs_item {
  height: 36px;
  border: 2px solid transparent;
  border-radius: 18px;
  font-size: 16px;
  color: #36363E;
  background: transparent;
  cursor: pointer;
  margin: 2px 7px 0 0;
  text-align: left;
  padding: 1px 13px 0 10px;
  outline: none;
}
.course_progress_form__tabs_item_image {
  padding-right: 6px;
  width: 16px;
  position: relative;
  bottom: -2px;
}
.course_progress_form__tabs_item-selected,
.course_progress_form__tabs_item:hover {
  border: 2px solid #2A98FF !important;
}
.course_progress_form__tabs--selected {
  border: 2px solid #2A98FF !important;
}
.course_progress_form__graph {
  margin-top: 20px;
  height: 105px;
}
.course_progress_form__graph__body {
  display: flex;
  justify-content: space-between;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-color: rgba(169, 169, 169, 0.31) rgba(218, 218, 218, 0.25);
  scrollbar-width: thin;
}
.course_progress_form__graph__body::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  border-radius: 10px;
}
.course_progress_form__graph__body::-webkit-scrollbar-track {
  background: rgba(218, 218, 218, 0.25);
}
.course_progress_form__graph__body::-webkit-scrollbar-thumb {
  background: rgba(169, 169, 169, 0.31);
  border-radius: 5px;
}
.course_progress_form__graph__body::-webkit-scrollbar-thumb:hover {
  background: rgba(218, 218, 218, 0.45);
}
.course_progress_form__graph__body__advanced {
  display: initial;
}
.course_progress_form__graph__advanced {
  display: table-cell;
  vertical-align: bottom;
  height: 125px;
}
.course_progress_form__graph__line {
  padding-top: 75px;
}
.course_progress_form__graph__item {
  background: #EBF7DF;
  border-radius: 10px;
  margin-right: 3px;
  min-height: 20px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-self: flex-end;
}
.course_progress_form__graph__item_image {
  align-self: flex-end;
  width: 9px;
  height: 9px;
  margin-bottom: 5px;
}
.course_progress_form__graph__item-empty {
  background: transparent;
}
.course_progress_form__graph__item-point_image {
  width: 18px;
  height: 16px;
  position: relative;
  bottom: -2px;
  cursor: pointer;
}
.course_progress_form__graph__item-advanced {
  background: transparent;
  justify-content: left;
  display: inline-block;
  width: inherit;
  margin: 12px 10px 0 0;
}
.course_progress_form__separator {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  height: 4px;
  margin: 9px -16px 0 -16px;
}
.course_progress_form__description {
  padding-top: 15px;
  margin-top: 5px;
  overflow: auto;
  max-height: 300px;
  scrollbar-color: rgba(169, 169, 169, 0.31) rgba(218, 218, 218, 0.25);
  scrollbar-width: thin;
}
.course_progress_form__description::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  border-radius: 10px;
}
.course_progress_form__description::-webkit-scrollbar-track {
  background: rgba(218, 218, 218, 0.25);
}
.course_progress_form__description::-webkit-scrollbar-thumb {
  background: rgba(169, 169, 169, 0.31);
  border-radius: 5px;
}
.course_progress_form__description::-webkit-scrollbar-thumb:hover {
  background: rgba(218, 218, 218, 0.45);
}
.course_progress_form__description__item {
  margin-bottom: 35px;
}
.course_progress_form__description__item_title {
  font-size: 18px;
  line-height: 24px;
  color: #36363E;
  cursor: pointer;
  margin: 0 0 15px 0;
}
.course_progress_form__description__item_title_image {
  padding-right: 10px;
  position: relative;
  top: 1px;
  height: 18px;
}
.course_progress_form__description__item_title-selected {
  color: #2A98FF;
}
.course_progress_form__description__item_wrapper {
  font-size: 16px;
  line-height: 22px;
  color: #36363E;
}
.course_progress_form__description__item_details {
  font-size: initial;
}
.course_progress_form__icon {
  align-self: flex-end;
  display: inline-block;
}
.course_progress_form__icon__star_image {
  height: initial;
  width: 17px;
}
.course_progress_form__line_progress {
  margin-top: 8px;
  width: 100%;
}
.course_progress_form__line_progress .course-progress-line__progress {
  background: #E1DFE6;
}
.course_progress_form__line_progress .course-progress-line__point,
.course_progress_form__line_progress .course-progress-line__point--selected {
  padding: 9px 0 9px 0;
  margin-left: -19px;
  background-size: 20px !important;
  width: 20px;
  z-index: 1;
}
.course_progress_form__line_progress .course-progress-line__point {
  border: 0;
  background: transparent url("~CheopsRoot/img/cap.svg") center no-repeat;
  background-position: center;
  background-repeat: no-repeat;
}
.course_progress_form__line_progress .course-progress-line__point-type-fail {
  background: transparent url("~CheopsRoot/img/cap_red.svg") center no-repeat;
}
.course_progress_form__line_progress .course-progress-line__point-type-done {
  background: transparent url("~CheopsRoot/img/cap_green.svg") center no-repeat;
}
.course_progress_form__line_progress .course-progress-line__point-selected {
  background: transparent url("~CheopsRoot/img/cap_blue.svg") center no-repeat;
}
