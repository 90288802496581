.course_module_list {
  border-radius: 12px;
  background: #3A3A40;
  overflow: hidden;
}
.course_module_list__wrapper {
  height: 0;
  padding: 0;
  visibility: hidden;
  transition: all 500ms;
  max-height: calc(100vh - 180px);
  overflow: hidden;
}
.course_module_list__navigation {
  width: calc(100% - 32px);
  border-radius: 10px;
  background: inherit;
  font-size: 0;
  height: 0;
  opacity: 0;
  transition: all 500ms;
  overflow: hidden;
  margin: 17px 16px 10px 16px;
}
.course_module_list__navigation_item {
  display: inline-block;
  vertical-align: top;
  line-height: 22px;
  font-size: 12px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  height: 0;
  opacity: 0;
  transition: all 500ms;
  box-sizing: border-box;
  width: 50%;
}
.course_module_list__navigation_item-active {
  background: rgba(255, 255, 255, 0.08);
  box-sizing: border-box;
  color: #ffffff;
}
.course_module_list__modules {
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
  scrollbar-width: thin;
  max-height: calc(100vh - 242px);
  overflow: auto;
  box-shadow: inset 0px 8px 16px rgba(0, 0, 0, 0.5);
}
.course_module_list__modules::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.course_module_list__modules::-webkit-scrollbar-track {
  background: transparent;
}
.course_module_list__modules::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}
.course_module_list__modules::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.4);
}
.course_module_list__modules a {
  text-decoration: none;
}
.course_module_list__block_title {
  line-height: 22px;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.5);
  width: calc(100% - 32px);
  margin: 0;
  padding: 28px 16px 16px;
  font-weight: 400;
  height: 0;
  opacity: 0;
  transition: all 500ms;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.course_module_list__item {
  display: block;
  position: relative;
  color: #36363E !important;
  line-height: 30px;
  font-size: 24px;
  letter-spacing: -0.01em;
  box-sizing: border-box;
  opacity: 0;
  transition: all 500ms;
  text-decoration: none;
  background: #fff;
  border-radius: 16px;
  margin: 10px;
  padding: 18px 60px 18px 20px;
}
.course_module_list__item .module_progress {
  background: url("~CheopsRoot/img/progress-circle-empty.svg") no-repeat;
  width: 19px;
  height: 19px;
  position: absolute;
  right: 3px;
  top: 15px;
}
.course_module_list__item .progress {
  position: absolute;
  right: 16px;
  top: 14px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}
.course_module_list__title {
  cursor: pointer;
  padding: 18px 16px;
  line-height: 22px;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: #fff;
  height: 60px;
  box-sizing: border-box;
  position: relative;
  transition: all 500ms;
}
.course_module_list__title:before {
  background: url("~CheopsRoot/img/menu_button.svg");
  display: inline-block;
  content: " ";
  width: 18px;
  height: 12px;
  vertical-align: middle;
  margin-right: 36px;
}
.course_module_list__title_rectangle {
  display: none;
}
.course_module_list__arrow {
  background: url("~CheopsRoot/img/module-list-arrow.svg") no-repeat;
  position: absolute;
  width: 12px;
  height: 8px;
  right: 25px;
  top: 50%;
  margin-top: -4px;
}
.course_module_list-opened {
  background: none;
  margin: 0 -8px;
}
.course_module_list-opened .course_module_list__block_title {
  height: auto;
  opacity: 1;
}
.course_module_list-opened .course_module_list__wrapper {
  padding-bottom: 41px;
  height: auto;
  visibility: visible;
  opacity: 1;
}
.course_module_list-opened .course_module_list__title {
  height: 0;
  padding: 0;
  display: none;
}
.course_module_list-opened .course_module_list__navigation {
  height: auto;
  opacity: 1;
  background: rgba(255, 255, 255, 0.08);
}
.course_module_list-opened .course_module_list__navigation_item {
  height: auto;
  opacity: 1;
}
.course_module_list-opened .course_module_list__item {
  opacity: 1;
}
.course_module_list__empty {
  font-size: 24px;
  text-align: center;
  padding: 30px;
  color: #fff;
}
.course_module_list__empty img {
  display: block;
  margin: 0 auto 20px;
  width: 109px;
  height: 127px;
}
.course_module_list__cert {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #828282;
  margin-top: 20px;
}
.course_module_list__cert_link {
  font-size: 16px;
  margin-top: 20px;
  display: inline-block;
}
.course_module_list__module_link {
  text-decoration: none;
  color: inherit;
}
.course_module_list__module_link:visited {
  color: inherit;
}
@media (max-width: 760px) {
  .course_module_list {
    display: none;
  }
  .course_module_list-opened {
    margin: 0;
  }
}
