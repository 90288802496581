.progress_transfer_page {
  font-family: "PT Root UI", Arial, sans-serif;
}
.progress_transfer_page__content {
  width: 720px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 120px 16px 148px 16px;
  font-size: 24px;
  line-height: 36px;
  color: #36363E;
  min-height: calc(100vh - 56px);
  min-height: calc((var(--vh, 1vh) * 100) - 56px);
  position: relative;
}
.progress_transfer_page__content_header {
  font-size: 36px;
  line-height: 44px;
  padding-bottom: 53px;
  font-weight: 600;
}
.progress_transfer_page__buttons {
  position: absolute;
  bottom: 0;
  height: 148px;
  padding-top: 16px;
  width: calc(100% - 32px);
}
.progress_transfer_page__abort_button {
  cursor: pointer;
  width: 328px;
  color: #7B29C9;
  text-align: center;
  background: #F1EDFA;
  border-radius: 8px;
  font-size: 16px;
  line-height: 19px;
  margin: 0 auto;
  padding: 8px 0;
}
.progress_transfer_page__abort_button:hover {
  background: #EAE0FF;
}
.progress_transfer_page__transfer_button {
  cursor: pointer;
  width: 328px;
  border-radius: 12px;
  box-shadow: 0px 8px 16px rgba(84, 120, 8, 0.15);
  text-align: center;
  color: #FFFFFF;
  background: linear-gradient(180deg, #8CD100 0%, #66AF31 100%);
  margin: 16px auto 0 auto;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  padding: 16px 0;
}
.progress_transfer_page__transfer_button:hover {
  background: #599900;
}
.progress_transfer_page__waiting {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress_transfer_page__waiting_text {
  margin-top: 88px;
  font-family: "PT Root UI", Arial, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #FFFFFF;
}
@media (max-width: 760px) {
  .progress_transfer_page__content {
    width: 100%;
    padding: 16px 16px 138px 16px;
    font-size: 18px;
    line-height: 24px;
  }
  .progress_transfer_page__content_header {
    padding-bottom: 31px;
    font-size: 24px;
    line-height: 30px;
  }
  .progress_transfer_page__buttons {
    height: 108px;
    padding: 8px 0;
  }
  .progress_transfer_page__abort_button,
  .progress_transfer_page__transfer_button {
    max-width: 328px;
    width: auto;
  }
  .progress_transfer_page__waiting_text {
    margin: 124px 32px 0 32px;
  }
}
