.autograde_status {
  text-align: center;
  font-size: 24px;
  line-height: 48px;
  color: #36363E;
  padding-bottom: 125px;
}
.autograde_status__center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.autograde_status__total_solved {
  font-size: 96px;
  margin-top: 25px;
  font-weight: 600;
  line-height: 112px;
}
.autograde_status__total_solved span {
  font-weight: 400;
}
.autograde_status__total_solved span span {
  color: #88CC00;
  font-weight: 600;
}
.autograde_status__stats {
  text-align: center;
  margin: 36px auto 20px;
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  bottom: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.autograde_status__stats_titles {
  display: flex;
  justify-content: center;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}
.autograde_status__stats_titles > div {
  width: 150px;
  margin: 8px;
}
.autograde_status__stats_data {
  display: flex;
  justify-content: center;
  font-size: 84px;
  text-transform: uppercase;
}
.autograde_status__stats_data > div {
  width: 150px;
  margin: 8px;
  background: #E1DFE6;
  border-radius: 8px;
  line-height: 100px;
}
.autograde_status__stats_data > div:last-child {
  color: #88CC00;
}
.autograde_status__bottom_buttons {
  text-align: center;
  position: fixed;
  left: 50%;
  margin-left: -164px;
  width: 328px;
  bottom: 90px;
}
.autograde_status__remain {
  color: #8C8695;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
}
.autograde_status__bottom_links > div {
  line-height: 0;
  height: 36px;
  margin: 8px;
}
.autograde_status__bottom_links > div > * {
  text-decoration: none;
  color: #7B29C9;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .autograde_status__total_solved {
    font-size: 72px;
  }
  .autograde_status__bottom_buttons {
    margin: 0;
    width: 100%;
    left: 0;
  }
  .autograde_status__stats_titles {
    font-size: 10px;
    line-height: 14px;
  }
  .autograde_status__stats_titles > div {
    width: 120px;
    margin: 8px;
  }
  .autograde_status__stats_data {
    font-size: 72px;
  }
  .autograde_status__stats_data > div {
    width: 120px;
  }
}
@media (max-height: 480px) {
  .autograde_status {
    padding-bottom: 0;
  }
  .autograde_status__remain {
    font-size: 16px;
  }
  .autograde_status__stats {
    position: static;
  }
  .autograde_status__bottom_buttons {
    position: static;
    margin: 0;
  }
}
