.contests-task-page {
  color: #FFFFFF;
  max-width: 100%;
}
.contests-task-page__intro-wrapper {
  height: 100vh;
  background-repeat: no-repeat;
  background-position: right;
  background-size: 392px;
}
.contests-task-page__intro-wrapper > .landings-header {
  height: 56px;
}
.contests-task-page__intro-wrapper > .landings-header .landings-header__enter-button {
  background-color: #2979FF;
}
.contests-task-page__intro-wrapper > .landings-header .landings-header__enter-button:hover {
  background-color: #005FFF;
}
.contests-task-page__intro-content {
  height: 100%;
  margin: 0 auto;
  padding-bottom: 40px;
  justify-content: flex-end;
  max-width: 1200px;
}
.contests-task-page__back-button {
  align-items: center;
  margin-left: 72px;
  margin-bottom: 40px;
  cursor: pointer;
  width: fit-content;
}
.contests-task-page__back-button-icon {
  transform: rotate(270deg);
}
.contests-task-page__back-button-text {
  margin: 0 0 0 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.4);
}
.contests-task-page__heading {
  font-weight: 700;
  margin: 0 24px;
  font-size: 24px;
  line-height: 32px;
}
.contests-task-page__description {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin: 8px 24px 0 24px;
}
.contests-task-page__class-list {
  justify-content: flex-start;
  gap: 8px;
  overflow-x: scroll;
  margin: 8px 24px 24px;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.contests-task-page__class-list::-webkit-scrollbar {
  display: none;
}
.contests-task-page__class-list .ui-button {
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  background-color: #EDEDED;
}
.contests-task-page__content {
  color: #1A1A1A;
  max-width: 1136px;
  background-color: #FFFFFF;
  margin: 0 auto 8px;
}
.contests-task-page__content-class {
  padding-top: 48px;
}
.contests-task-page__content-class:first-of-type {
  padding-top: 72px;
}
.contests-task-page__content-title {
  font-weight: 700;
  margin: 0 0 0 24px;
  font-size: 32px;
  line-height: 40px;
}
.contests-task-page__dates {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin: 24px 0 0 24px;
}
.contests-task-page__dates-item {
  margin-right: 24px;
}
@media (min-width: 768px) {
  .contests-task-page__intro-wrapper {
    background-size: 442px;
  }
  .contests-task-page__intro-content {
    padding-bottom: 0;
    justify-content: center;
  }
  .contests-task-page__heading {
    font-size: 32px;
    line-height: 40px;
    max-width: 472px;
  }
  .contests-task-page__description,
  .contests-task-page__dates {
    font-size: 18px;
    line-height: 28px;
    max-width: 472px;
  }
}
@media (min-width: 1200px) {
  .contests-task-page__intro-wrapper {
    background-size: unset;
  }
  .contests-task-page__heading {
    font-size: 48px;
    line-height: 56px;
    max-width: 752px;
    margin-left: 72px;
  }
  .contests-task-page__description,
  .contests-task-page__dates {
    font-size: 24px;
    line-height: 32px;
    max-width: 752px;
    margin: 24px 0 0 72px;
  }
  .contests-task-page__dates {
    margin-top: 40px;
  }
  .contests-task-page__dates-item {
    margin-right: 40px;
  }
  .contests-task-page__class-list {
    margin-top: 40px;
    margin-left: 72px;
    gap: 16px;
    flex-wrap: wrap;
  }
  .contests-task-page__class-list .ui-button {
    font-size: 18px;
    line-height: 28px;
  }
  .contests-task-page__content {
    margin-bottom: 112px;
  }
  .contests-task-page__content-class {
    padding-top: 96px;
  }
  .contests-task-page__content-class:first-of-type {
    padding-top: 112px;
  }
  .contests-task-page__content-title {
    margin-left: 48px;
  }
}
.subject-card-list {
  flex-wrap: wrap;
  margin-top: 16px;
  margin-left: 8px;
}
@media (min-width: 1200px) {
  .subject-card-list {
    margin-top: 48px;
  }
}
@media (max-width: 400px) {
  .subject-card-list {
    margin-right: 8px;
  }
}
.subject-card {
  border-radius: 16px;
  max-width: 358px;
  margin-top: 8px;
  padding: 16px;
  background-repeat: no-repeat;
  background-position: right -19px;
}
.subject-card__heading-wrapper {
  align-items: center;
  min-height: 40px;
}
.subject-card__heading {
  margin: 0;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}
.subject-card__text {
  font-weight: 500;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
  margin: 8px 0 0;
}
.subject-card__button-wrapper {
  flex-wrap: wrap;
}
.subject-card__button-link {
  text-decoration: none;
  margin: 8px 8px 0 0;
}
.subject-card__button-link:nth-of-type(4n) {
  margin-right: 0;
}
.subject-card__button-link > .subject-card__button.ui-button {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  min-width: 60px;
  max-width: 60px;
}
.subject-card__button-gray.subject-card__button:not([disabled]) {
  background-color: #EDEDED;
  color: #1A1A1A;
}
.subject-card__button-gray.subject-card__button:not([disabled]):not([disabled]):hover {
  background-color: #DFDFDF;
}
.subject-card__button-blue.subject-card__button {
  padding: 0;
}
.subject-card__button-blue.subject-card__button > .ui-button__icon.ui-icon {
  color: #FFFFFF;
  margin: 0;
}
.subject-card__button-blue.subject-card__button:not([disabled]) {
  background-color: #2979FF;
}
.subject-card__button-blue.subject-card__button:not([disabled]):hover {
  background-color: #005FFF;
}
@media (min-width: 760px) {
  .subject-card {
    max-width: unset;
    width: 48.6vw;
    margin-right: 8px;
  }
  .subject-card:nth-of-type(2n) {
    margin-right: 0;
  }
  .subject-card__button-link:nth-of-type(4n) {
    margin-right: 8px;
  }
  .subject-card__button-link:nth-of-type(5n) {
    margin-right: 0;
  }
  .subject-card__button-wrapper {
    max-width: 340px;
  }
}
@media (min-width: 1200px) {
  .subject-card {
    max-width: 368px;
    min-width: 368px;
    padding: 40px;
    background-size: 409px auto;
    background-position: 55px -32px;
  }
  .subject-card:nth-of-type(2n) {
    margin-right: 8px;
  }
  .subject-card:nth-of-type(3n) {
    margin-right: 0;
  }
  .subject-card__heading {
    font-size: 24px;
    line-height: 32px;
  }
  .subject-card__text {
    font-size: 18px;
    line-height: 28px;
    margin: 20px 0 0;
  }
  .subject-card__button-link {
    margin: 16px 16px 0 0;
  }
  .subject-card__button-link:nth-of-type(4n) {
    margin-right: 0;
  }
  .subject-card__button-link:nth-of-type(5n) {
    margin-right: 16px;
  }
}
