.platform-landing-title {
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  font-size: 36px;
  margin: 0 10px;
  align-items: flex-start;
}
@media (max-width: 760px) {
  .platform-landing-title {
    padding-top: 5px;
    margin-left: 8px;
    margin-right: 8px;
    line-height: 30px;
    font-size: 24px;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 760px) {
  .platform-landing-title > .gap-size-infinity {
    flex-basis: 16px;
  }
}
.platform-landing-title__buttons {
  align-items: stretch;
}
@media (max-width: 760px) {
  .platform-landing-title__buttons {
    align-items: start;
    flex-direction: column-reverse;
  }
}
.platform-landing-title__additional-button {
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}
.platform-landing-title__additional-button-text {
  color: #FFFFFF;
  z-index: 1;
}
.platform-landing-title__additional-button-icon {
  z-index: 1;
}
.platform-landing-title__additional-button:hover::before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: inherit;
  opacity: 0.1;
  position: absolute;
  border-radius: inherit;
  filter: contrast(600%);
}
.platform-landing-title__additional-button:not(.platform-landing-title__additional-button-short) {
  width: 320px;
  border-radius: 8px;
  font-weight: 500;
}
.platform-landing-title__additional-button-short {
  width: 265px;
  border-radius: 12px;
  padding: 4px 8px;
}
.platform-landing-title__additional-button-short > .platform-landing-title__additional-button-icon {
  display: none;
}
@media (max-width: 760px) {
  .platform-landing-title__additional-button {
    height: 56px;
  }
  .platform-landing-title__additional-button-short {
    width: 320px;
    margin-top: 8px;
    border-radius: 8px;
    font-weight: 500;
    padding: 0;
  }
  .platform-landing-title__additional-button-short > .platform-landing-title__additional-button-icon {
    display: flex;
  }
}
