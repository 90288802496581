.module-group-nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  top: 56px;
  bottom: 0;
  left: 50%;
  margin-left: -520px;
  width: 100px;
  max-height: calc(100% - 100px);
}
@media (max-width: 1200px) {
  .module-group-nav {
    display: none;
  }
}
