.module_passing_header {
  min-height: 56px;
}
.module_passing_header > div {
  background: #6C4DC4;
  position: relative;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s;
}
.module_passing_header--fixed > div {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.module_passing_header__back {
  width: 24px;
  height: 24px;
  background: url("~CheopsRoot/img/couse-back-arrow.svg") center no-repeat;
  cursor: pointer;
  position: absolute;
  top: 16px;
  left: 16px;
}
.module_passing_header__title {
  color: #fff;
  line-height: 0;
  min-height: 0;
  opacity: 0;
  transition: all 0.2s;
  margin: 0 60px;
}
.module_passing_header__more {
  color: hsla(0, 0%, 100%, 0.5);
  width: 25px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  line-height: 34px;
  display: inline-block;
  font-size: 25px;
  letter-spacing: -1px;
  vertical-align: top;
}
.module_passing_header__elements {
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
  scrollbar-width: thin;
  font-size: 0;
  margin: 4px 60px;
  transition: padding 0.2s;
  text-align: center;
}
.module_passing_header__elements::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.module_passing_header__elements::-webkit-scrollbar-track {
  background: transparent;
}
.module_passing_header__elements::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}
.module_passing_header__elements::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.4);
}
.module_passing_header__elements--clickable {
  cursor: pointer;
}
.module_passing_header__close_all_tasks {
  position: absolute;
  top: 8px;
  right: 16px;
  cursor: pointer;
  padding: 8px;
}
.module_passing_header__corners {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}
.module_passing_header__corners > svg {
  position: absolute;
  z-index: 2;
}
.module_passing_header__corners > svg:first-child {
  left: 0;
  top: 0;
}
.module_passing_header__corners > svg:last-child {
  right: 0;
  top: 0;
}
.module_passing_header__bottom_close_bar {
  background: rgba(255, 255, 255, 0.7);
  position: relative;
  height: 100vh;
}
.module_passing_header--show_all_tasks {
  height: auto;
}
.module_passing_header--show_all_tasks.module_passing_header--fixed > div {
  max-height: calc(100% - 42px);
}
.module_passing_header--show_all_tasks .module_passing_header__title {
  min-height: 24px;
  font-size: 18px;
  line-height: 24px;
  opacity: 1;
  margin: 15px 60px;
}
.module_passing_header--show_all_tasks .module_passing_header__more {
  display: none;
}
.module_passing_header--show_all_tasks .module_passing_header__elements {
  margin: 4px 15px 0;
  text-align: left;
  overflow: auto;
  padding-bottom: 20px;
}
.module_passing_header--show_all_tasks .module_passing_header__element {
  display: inline-block !important;
}
.module_passing_header .progress {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 16px;
  top: 16px;
}
