.select_option_answer {
  min-height: 56px;
  box-sizing: border-box;
  position: relative;
  margin: 10px -20px;
  background: #F1EDFA;
  border-radius: 8px;
  margin: 20px 0;
  padding: 16px;
}
.select_option_answer p {
  margin: 0;
}
.select_option_answer img {
  max-width: 100%;
}
.select_option_answer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.select_option_answer input ~ label {
  cursor: pointer;
}
.select_option_answer input ~ label:before {
  display: inline-block;
  content: " ";
  vertical-align: text-bottom;
  border: 2px solid #A7B3C2;
  box-sizing: border-box;
  border-radius: 3px;
  width: 24px;
  height: 24px;
  margin-right: 24px;
  position: absolute;
  left: 16px;
  top: 17px;
}
.select_option_answer input ~ label:hover .select_option_answer input ~ label:before {
  border: 2px solid #4B8CEE;
}
.select_option_answer input[type=checkbox] ~ label:hover:before {
  border: 2px solid #4B8CEE;
}
.select_option_answer input[type=checkbox]:checked ~ label:before {
  background: #4B8CEE url("~CheopsRoot/img/ok.svg") 50% no-repeat;
}
.select_option_answer input[type=radio] ~ label:hover:before {
  border: 2px solid #4B8CEE;
}
.select_option_answer input[type=radio] ~ label:before {
  border-radius: 50%;
}
.select_option_answer input[type=radio] ~ label:hover:before {
  border: 2px solid #4B8CEE;
}
.select_option_answer input[type=radio]:checked ~ label:before {
  background: #4B8CEE;
  border-color: #4B8CEE;
  box-shadow: inset 0 0 0 3px #fff;
}
.select_option_answer input:hover {
  background: #EAE0FF;
  border: 1px solid #7B29C9;
}
.select_option_answer label {
  cursor: pointer;
  padding-left: 40px;
  display: block;
  line-height: 24px;
  font-size: 18px;
  margin: 0;
}
.select_option_answer--result {
  margin: 10px -25px;
}
.select_option_answer--result label {
  cursor: default;
  color: #232943;
}
.select_option_answer--result label:before {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  background: #69798C;
  top: 26px;
  border: none;
  border-radius: 50%;
}
.select_option_answer--result label:hover .select_option_answer--result label:before {
  border: none !important;
}
.select_option_answer--result_wrong label {
  color: #FF5E73;
}
.select_option_answer--result_wrong input:checked ~ label:before {
  background: #FF5E73;
  box-shadow: none;
}
.select_option_answer--result_partial_wrong input:checked ~ label:before {
  background: #ff8e25;
  box-shadow: none;
}
.select_option_answer--result_partial_wrong label {
  color: #ff8e25;
}
.select_option_answer--result_right {
  border-radius: 8px;
}
.select_option_answer--result_right input:checked ~ label:before {
  background: #5BC36C;
  box-shadow: none;
}
.select_option_answer--result_right label {
  color: #5BC36C;
}
.select_option_answer--full_width input {
  width: 100% !important;
  box-sizing: border-box;
  height: 56px !important;
}
.select_option_answer--inline {
  display: inline-flex;
  border-radius: 8px;
  margin: 0 4px 0 0;
  padding: 4px;
  min-height: 32px;
  max-width: 720px;
  overflow: hidden;
}
.select_option_answer--inline:last-child {
  margin: 0;
}
.select_option_answer--inline input ~ label {
  padding-right: 8px;
  font-size: 16px;
}
.select_option_answer--inline input ~ label:before {
  width: 20px;
  height: 20px;
  left: 6px;
  top: 6px;
}
