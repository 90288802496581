.chains-course {
  text-decoration: none;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 16px;
}
@media (max-width: 1201px) {
  .chains-course {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 880px) {
  .chains-course {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12px;
  }
}
@media (max-width: 592px) {
  .chains-course {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 8px;
  }
}
