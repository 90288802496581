/* Generated with js2less.js.
Original file: src/styles/values/grayscale.json */
/* Generated with js2less.js.
Original file: src/styles/values/breakpoints.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-action.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-wrong.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-good.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-attention.json */
.teacher-progress-page {
  min-height: 100vh;
  background-color: #EDEDED;
}
.teacher-progress-page .ui-table__tbody-cell > .ui-table__tbody-cell-wrapper {
  padding: 4px 2px;
}
.teacher-progress-page .ui-table__tbody-cell > .ui-table__tbody-cell-wrapper > .ui-table__datacell-name,
.teacher-progress-page .ui-table__tbody-cell > .ui-table__tbody-cell-wrapper .teacher-progress-page__table-custom-cell {
  padding: 10px 8px;
}
.teacher-progress-page .ui-table__tbody-row > .ui-table__tbody-cell.ui-table__tbody-cell-name-user.ui-table__tbody-cell-sticky {
  max-width: 280px;
}
.teacher-progress-page__header {
  background-color: #FFFFFF;
  padding: 8px;
}
.teacher-progress-page__back-button {
  display: flex;
  text-decoration: none;
  align-items: center;
  color: #999999;
  cursor: pointer;
}
.teacher-progress-page__back-button-icon {
  padding: 10px;
}
.teacher-progress-page__back-button-text {
  padding: 12px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}
.teacher-progress-page__back-button:visited {
  color: #999999;
}
.teacher-progress-page__table {
  margin: 8px;
  width: auto;
}
.teacher-progress-page__table > .ui-table__thead-wrapper {
  height: auto;
}
.teacher-progress-page__table .ui-table__tbody-row > .ui-table__tbody-cell {
  height: 48px;
}
.teacher-progress-page__table .ui-table__tbody-cols-cell-wrapper {
  max-width: 120px;
}
.teacher-progress-page__table-header {
  margin: 14px 16px;
}
.teacher-progress-page__table-header-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #1A1A1A;
  margin: 0 0 2px 0;
  padding: 0;
}
.teacher-progress-page__table-header-separator {
  width: 1px;
  background: #DFDFDF;
  height: 16px;
  margin-right: 8px;
  align-self: center;
}
.teacher-progress-page__table-user-cell {
  min-width: 280px;
  align-items: center;
  max-width: 280px;
  padding: 0 10px;
}
.teacher-progress-page__table-sum-cell {
  font-weight: 700;
}
.teacher-progress-page__table-task-cell {
  width: 46px;
  height: 40px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}
.teacher-progress-page__table-task-cell-verdict {
  cursor: pointer;
}
.teacher-progress-page__table-task-cell-verdict-ok {
  background-color: #EFFFD0;
}
.teacher-progress-page__table-task-cell-verdict-ok:hover {
  background-color: #D9F6A2;
}
.teacher-progress-page__table-task-cell-verdict-ok:active {
  background-color: #C4ED75;
}
.teacher-progress-page__table-task-cell-verdict-partly {
  background-color: #FFF5DB;
}
.teacher-progress-page__table-task-cell-verdict-partly:hover {
  background-color: #FFE5A6;
}
.teacher-progress-page__table-task-cell-verdict-partly:active {
  background-color: #FFD370;
}
.teacher-progress-page__table-task-cell-verdict-wrong {
  background-color: #FFE3E3;
}
.teacher-progress-page__table-task-cell-verdict-wrong:hover {
  background-color: #FFC6C6;
}
.teacher-progress-page__table-task-cell-verdict-wrong:active {
  background-color: #FFA6A6;
}
.teacher-progress-page__table-task-cell-verdict-annulled {
  background-color: #F7F7F7;
}
.teacher-progress-page__table-task-cell-verdict-annulled:hover {
  background-color: #EDEDED;
}
.teacher-progress-page__table-task-cell-verdict-annulled:active {
  background-color: #DFDFDF;
}
.teacher-progress-page__table-task-cell-verdict-none {
  background-color: #FFFFFF;
}
.teacher-progress-page__table-task-cell-verdict-none:hover {
  background-color: #F7F7F7;
}
.teacher-progress-page__table-task-cell-verdict-none:active {
  background-color: #DFDFDF;
}
