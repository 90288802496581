.task_solution img,
.task_solution image {
  cursor: default;
  max-width: 100%;
}
.task_solution--smt.task_solution--force-show-right {
  position: relative;
  z-index: 100;
}
.task_solution--smt .task_solution__field {
  background: #ECF4FF;
}
.task_solution__field {
  border-radius: 12px;
  color: #36363E;
}
.task_solution__field .marked-paragraph {
  margin: 0;
}
.task_solution__field--inline .marked-paragraph {
  width: max-content;
}
.task_solution__field--verdict-ok {
  background: #EBF7DF !important;
}
.task_solution__field--verdict-none,
.task_solution__field--verdict-wrong {
  background: #FFEBED !important;
}
.task_solution__field--verdict-partly {
  background: #FFF5D9 !important;
}
.task_solution__field--verdict-unscored,
.task_solution__field--verdict-noneUnscored,
.task_solution__field--verdict-annulled {
  background: #ffffff !important;
  border: 1px solid #E1DFE6;
  min-height: 56px;
}
.task_solution__field_placeholder {
  color: #757575;
}
.task_solution__composite_text {
  line-height: 28px;
  font-size: 16px;
}
.task_solution__composite_text img {
  max-width: 100%;
}
.task_solution__composite_text p {
  margin: 16px 0;
}
.task_solution:not(.task_solution--type-match) .task_solution__field {
  margin: 20px 0;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 24px;
  min-height: 56px;
}
.task_solution:not(.task_solution--type-match) .task_solution__field--inline {
  flex: 0;
  margin: 4px 2px;
  padding: 4px 16px;
  width: 100%;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 24px;
  min-height: 32px;
}
.task_solution:not(.task_solution--type-match) .task_solution__field--inline:has(img) {
  flex-grow: 1;
}
.task_solution:not(.task_solution--type-match) .task_solution__field--inline-img {
  flex-grow: 1;
}
.task_solution--inline.task_solution--type-checkbox {
  display: inline-flex;
  flex-wrap: wrap;
}
.task_solution--type-checkbox .task_solution__field {
  position: relative;
}
.task_solution--type-checkbox .task_solution__field:before {
  display: inline-block;
  content: " ";
  width: 24px;
  height: 24px;
  border: 1px solid #E1DFE6;
  box-sizing: border-box;
  border-radius: 50%;
  margin-right: 16px;
  background: #fff;
  vertical-align: middle;
  position: absolute;
  top: 16px;
  left: 16px;
}
.task_solution--type-checkbox .task_solution__field > div {
  display: inline-block;
}
.task_solution--type-checkbox .task_solution__field--checkbox-checked:before {
  background: #E1DFE6;
  border: none;
}
.task_solution--type-checkbox .task_solution__field--inline:before {
  width: 20px;
  height: 20px;
  left: 8px;
  top: 6px;
}
.task_solution--type-checkbox .task_solution__value_text {
  margin-left: 40px;
}
.task_solution--verdict-ok.task_solution--type-checkbox .task_solution__field--checkbox-checked:before {
  background: #88CC00;
}
.task_solution--verdict-none.task_solution--type-checkbox .task_solution__field--checkbox-checked:before,
.task_solution--verdict-wrong.task_solution--type-checkbox .task_solution__field--checkbox-checked:before {
  background: #FF5E73;
}
.task_solution--verdict-partly.task_solution--type-checkbox .task_solution__field--checkbox-checked:before {
  background: #FFBF00;
}
.task_solution--verdict-annulled.task_solution--type-checkbox .task_solution__field--checkbox-checked:before {
  background: #C6C2CC;
}
.task_solution--verdict-ok .task_solution__field {
  background: #EBF7DF;
}
.task_solution--verdict-noneUnscored .task_solution__field,
.task_solution--verdict-none .task_solution__field {
  min-height: 56px;
}
.task_solution--verdict-none .task_solution__field,
.task_solution--verdict-wrong .task_solution__field {
  background: #FFEBED;
}
.task_solution--verdict-partly .task_solution__field {
  background: #FFF5D9;
}
.task_solution--verdict-unscored .task_solution__field,
.task_solution--verdict-noneUnscored .task_solution__field,
.task_solution--verdict-annulled .task_solution__field {
  background: #ffffff !important;
  border: 1px solid #E1DFE6;
  padding: 15px;
}
.task_solution--type-multiple {
  margin-left: -22px;
}
.inline_answers--inline .task_solution--type-multiple {
  display: flex;
  flex-direction: column;
}
.task_solution--type-multiple .task_solution__field {
  max-width: 222px;
  display: inline-block;
  vertical-align: top;
  margin-left: 22px !important;
}
.task_solution--type-multiple .task_solution__field--singleton:not(.task_solution--type-multiple .task_solution__field--inline) {
  max-width: none;
  width: calc(100% - 22px) !important;
}
.task_solution--type-multiple .task_solution__field--double:not(.task_solution--type-multiple .task_solution__field--inline) {
  max-width: none;
  width: calc(50% - 22px) !important;
}
.task_solution--type-multiple .task_solution__field--triple {
  max-width: none;
  width: calc(33.3% - 22px) !important;
}
.task_solution--inline.task_solution--type-multiple .task_solution__field {
  vertical-align: baseline;
}
.task_solution--type-ordering.task_solution--verdict-ok > .ordering-answer > .task-element-ordering__solution {
  border-color: #7EB219;
}
.task_solution--type-ordering.task_solution--verdict-ok > .ordering-answer > .task-element-ordering__solution:before {
  color: #7EB219;
}
.task_solution--type-ordering.task_solution--verdict-ok > .ordering-answer > .task-element-ordering__solution .task-element-ordering__item {
  background-color: #EFFFD0;
}
.task_solution--type-ordering.task_solution--verdict-wrong > .ordering-answer > .task-element-ordering__solution {
  border-color: #F94C4F;
}
.task_solution--type-ordering.task_solution--verdict-wrong > .ordering-answer > .task-element-ordering__solution:before {
  color: #F94C4F;
}
.task_solution--type-ordering.task_solution--verdict-wrong > .ordering-answer > .task-element-ordering__solution .task-element-ordering__item {
  background-color: #FFE3E3;
}
.task_solution--type-ordering.task_solution--verdict-partly > .ordering-answer > .task-element-ordering__solution {
  border-color: #FD9601;
}
.task_solution--type-ordering.task_solution--verdict-partly > .ordering-answer > .task-element-ordering__solution:before {
  color: #FD9601;
}
.task_solution--type-ordering.task_solution--verdict-partly > .ordering-answer > .task-element-ordering__solution .task-element-ordering__item {
  background-color: #FFF5DB;
}
@media (max-width: 760px) {
  .task_solution--type-multiple .task_solution__field:not(.task_solution__field--inline) {
    max-width: none;
    width: calc(100% - 22px) !important;
  }
}
