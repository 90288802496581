.element_nav_list {
  margin: -20px 0;
  overflow: hidden;
}
.element_nav_list__group {
  margin: 20px 0;
}
.element_nav_list__group > * {
  vertical-align: middle;
}
.element_nav_list__element_title {
  margin-left: 12px;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  max-width: calc(100% - 70px);
}
.element_nav_list--without_groups {
  margin: 0;
}
.element_nav_list--without_groups .element_nav_list__group {
  margin: 0;
  display: inline-block;
}
