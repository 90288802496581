.match_answers {
  position: relative;
  z-index: 0;
}
.match_answers > div {
  display: flex;
}
.match_answers__fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.match_answers__fields:last-child {
  text-align: right;
}
.match_answers__lines {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.match_answers__line {
  transition: stroke 0.3s;
}
.match_answers__line--faded {
  stroke: #E1DFE6;
}
.match_answers__field {
  margin: 10px;
  padding: 24px 16px;
  border-radius: 8px;
  position: relative;
  box-shadow: inset 0 0 0 1px #E1DFE6;
  flex: 1 1 auto;
  font-size: 16px;
  box-sizing: border-box;
}
@media print {
  .match_answers__field {
    outline: 1px solid #E1DFE6;
  }
}
.match_answers__field--selected {
  background: #EAE0FF;
}
.match_answers__field--clickable {
  background: #F1EDFA;
  border: none;
  box-shadow: none;
}
.match_answers__field--clickable:hover {
  background: #EAE0FF;
}
.match_answers__fields:first-child .match_answers__target {
  right: 24px;
}
.match_answers__fields:first-child .match_answers__field {
  margin-left: 0;
}
.match_answers__fields:last-child .match_answers__field {
  margin-right: 0;
}
.match_answers__fields:last-child .match_answers__target {
  left: 24px;
}
.match_answers__fields:first-child .match_answers__target--expanded {
  margin-right: 16px !important;
}
.match_answers__fields:last-child .match_answers__target--expanded {
  margin-left: 16px !important;
}
.match_answers__text p {
  margin: 0;
}
.match_answers__text img {
  max-height: 80px;
  max-width: 100%;
}
.match_answers--read-only .match_answers__target {
  cursor: default;
}
.match_answers__target {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 0 16px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  margin-top: -12px;
  z-index: 11;
  transition: margin 0.3s, background 0.3s;
  cursor: pointer;
}
.match_answers__target--single {
  background: #fff;
  border: 2px solid #735FCD;
  box-sizing: content-box;
  margin-right: 14px;
  cursor: default;
}
.match_answers__target--active {
  background: #E1DFE6;
}
.match_answers__target--faded {
  background: #E1DFE6 !important;
  cursor: default;
  z-index: 9;
}
.match_answers__target--expanded {
  z-index: 12;
}
.match_answers__target:first-child:last-child {
  margin-top: -12px;
}
.match_answers__target:nth-child(4n+1) {
  margin-top: -18px;
}
.match_answers__target:nth-child(4n+2) {
  margin-left: 22px;
  margin-right: 22px;
}
.match_answers__target:nth-child(4n+3) {
  margin-top: -6px;
}
.match_answers__target:nth-child(4n+4) {
  margin-left: 10px;
  margin-right: 10px;
}
