/* Generated with js2less.js.
Original file: src/styles/values/grayscale.json */
/* Generated with js2less.js.
Original file: src/styles/values/breakpoints.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-action.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-wrong.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-good.json */
/* Generated with js2less.js.
Original file: src/styles/values/colors-status-attention.json */
.teacher-invite-page {
  min-height: 100vh;
  width: 100%;
}
.teacher-invite-page__back-link {
  position: absolute;
  top: 16px;
  left: 16px;
}
.teacher-invite-page__content {
  display: grid;
  gap: 24px;
  max-width: 360px;
  justify-items: center;
}
.teacher-invite-page__icon.ui-icon-size-xl.material-icons-outlined {
  width: 120px;
  height: 120px;
  font-size: 120px;
}
.teacher-invite-page__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #1A1A1A;
  margin: 0;
}
.teacher-invite-page__text {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #1A1A1A;
  margin: 0;
}
.teacher-invite-page__button {
  min-width: 240px;
}
@media (max-width: 760px) {
  .teacher-invite-page__content {
    max-width: 328px;
    padding: 0 16px;
    box-sizing: content-box;
  }
  .teacher-invite-page__button {
    min-width: 100%;
  }
}
